import { FC } from 'react';

import cx from 'classnames';

import Icon from '../Icon';
import ErrorCircleRounded from '../Icon/icons/ErrorCircleRounded';
import styles from './index.module.css';
import { IFormFieldWrapperProps } from './types';

export const FormFieldWrapper: FC<IFormFieldWrapperProps> = ({
  children,
  helpText,
  errorMessage,
  helpClassName = '',
}) => {
  return (
    <>
      {children}
      {errorMessage && (
        <div className={styles.errorMessage}>
          <Icon component={ErrorCircleRounded} width="13px" height="13px" />
          {errorMessage}
        </div>
      )}
      {helpText && <div className={cx(styles.helpText, helpClassName)}>{helpText}</div>}
    </>
  );
};
