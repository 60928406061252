import React from 'react';

import * as R from 'ramda';

import { getDimensionsError } from 'constants/texts';

export const ERROR_TOO_MANY_FILES = 'too-many-files';
export const ERROR_FILE_INVALID_TYPE = 'file-invalid-type';
export const ERROR_TOO_LARGE = 'file-too-big';
export const ERROR_UNEXPECTED = 'unexpected-error';
export const ERROR_DIMENSIONS = 'dimensions-too-large';

const PRIORITY = [ERROR_TOO_MANY_FILES, ERROR_FILE_INVALID_TYPE];

const MESSAGES = {
  [ERROR_TOO_MANY_FILES]: <div>Sorry, you can only upload 1 file at a time.</div>,
  [ERROR_FILE_INVALID_TYPE]: <div>Sorry, this file type is not permitted. Please try again.</div>,
  [ERROR_TOO_LARGE]: <div>The file you are trying to upload is too large.</div>,
  [ERROR_UNEXPECTED]: <div>Unexpected error. Please try again.</div>,
  [ERROR_DIMENSIONS]: ({ width, height }) => getDimensionsError(width, height),
};

const getErrorsFromRejection = (rej) => rej.errors;
const getErrorCode = (error) => error.code;
const getErrorCodes = R.map(getErrorCode);
const getErrorCodesFromRejection = R.pipe(getErrorsFromRejection, getErrorCodes);
const getErrorCodesFromRejectionAndAppend = R.pipe(getErrorCodesFromRejection, R.concat);
const uncurryTwoArguments = R.uncurryN(2);
const reduceFn = uncurryTwoArguments(getErrorCodesFromRejectionAndAppend);
const reduceToEmptyArray = R.flip(R.reduce)([]);
const constructAndCall = uncurryTwoArguments(R.construct);
const mapRejectionsToErrorsArray = reduceToEmptyArray(reduceFn);
const constructSetAndCall = constructAndCall(Set);

const hasInObject = R.flip(R.has);
const findInList = R.flip(R.find);
const findInPriority = findInList(PRIORITY);

export const mapRejectionsToErrorsSet = R.pipe(mapRejectionsToErrorsArray, constructSetAndCall);
export const getMarkup = (val, params) => {
  if (MESSAGES[val]) {
    return typeof MESSAGES[val] === 'function' ? MESSAGES[val](params) : MESSAGES[val];
  }

  return MESSAGES[ERROR_UNEXPECTED];
};
export const selectPrimary = R.pipe(hasInObject, findInPriority);
export const getPrimaryFromRejections = R.pipe(mapRejectionsToErrorsSet, selectPrimary);
