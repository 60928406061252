import { FC } from 'react';

import { ISimpleView } from 'components/shared/FileDropZone/View/types';
import Text from 'components/shared/Typography/Text';

const SimpleView: FC<ISimpleView> = ({ filename }) => {
  return <Text>{filename}</Text>;
};

export default SimpleView;
