const mockData = (page) => ({
  title: page.name || 'Activity',
  blocks: [
    { type: 'header', data: { subheadline: 'Activity overview' } },
    {
      type: 'text',
      data: {
        placeholder:
          'Click in this text block to write your own content. This is a good place to write about day tours or special activities on offer. There is a character limit on this text block to ensure that the text is split up with images and videos which studies show is much more effective than long blocks of text. You can continue your text beneath the media block and in the other sections.',
      },
    },
    {
      type: 'image',
      data: {},
    },
    {
      type: 'text',
      data: {
        placeholder:
          'Here is more space to write about day tours or special activities on offer. There is a character limit on this text block; you can add another text block if you absolutely must but we encourage you to keep it concise.',
      },
    },
  ],
});

export default mockData;
