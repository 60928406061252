export const ITINERARIES_ITEM_TYPES = {
  LOOKBOOK: 'lookbook',
  ITINERARY: 'itinerary',
  GUEST_PORTAL: 'guest_portal',
  SAMPLE: 'sample',
  SAMPLE_LOOKBOOK: 'sample_lookbook',
};

// account types
export const ACCOUNT_TYPE_ADMIN = 'admin';
export const ACCOUNT_TYPE_AGENT = 'agent';
export const ACCOUNT_TYPE_OWNER = 'owner';
export const ACCOUNT_TYPE_GUEST = 'guest';

// page type
export const LAST_ACTIVE_PAGE_TYPE = 'LAST_ACTIVE_PAGE_TYPE';
export const PAGE_TYPE_AREA = 'area';
export const PAGE_TYPE_ACTIVITY = 'activity';
export const PAGE_TYPE_PROPERTY = 'property';
export const PAGE_TYPE_MISC = 'misc';
export const PAGE_TYPE_ABOUT_US = 'about_us';
export const PAGE_TYPE_ABOUT_SAFARI = 'about_safari';
export const PAGE_TYPE_PRE_TRAVEL_INFO = 'pre_travel_info';
export const PAGE_TYPE_HELP_ARTICLE = 'help_article';
export const PAGE_TYPE_HELP_TUTORIAL = 'help_tutorial';
export const PAGE_TYPE_SAMPLE_ITINERARIES = 'sample_itinerary';
export const PAGE_TYPE_SAMPLE_LOOKBOOK = 'sample_lookbook';

// page types that can be created via content library
export const PAGE_TYPES = {
  AREA: PAGE_TYPE_AREA,
  ACTIVITY: PAGE_TYPE_ACTIVITY,
  PROPERTY: PAGE_TYPE_PROPERTY,
  MISC: PAGE_TYPE_MISC,
  ABOUT_US: PAGE_TYPE_ABOUT_US,
  ABOUT_SAFARI: PAGE_TYPE_ABOUT_SAFARI,
  PRE_TRAVEL_INFO: PAGE_TYPE_PRE_TRAVEL_INFO,
  HELP_ARTICLE: PAGE_TYPE_HELP_ARTICLE,
  HELP_TUTORIAL: PAGE_TYPE_HELP_TUTORIAL,
};

/**
 * landing page roles
 * yes, there are some collisions with @LANDING_PAGE_TYPES but it's a different thing
 */
export const LANDING_PAGE_ROLES = {
  COVER_PAGE: 'cover_page', // itinerary, lookbook
  SAFARI_MAP: 'safari_map', // itinerary, lookbook

  SAFARI_OVERVIEW: 'safari_overview', // itinerary
  SAFARI_COSTS: 'safari_costs', // itinerary

  TERMS: 'terms', // itinerary
  ABOUT_US: 'about_us', // itinerary

  ABOUT_SAFARI: 'about_safari', // itinerary, lookbook

  COMMON: 'common', // lookbook inclusions

  DETAILS: 'details', // itinerary details
  DAY: 'day', // itinerary details V2

  GUESTS: 'guest_forms', // guest portal
  SAFARI_MANIFEST: 'safari_manifest', // guest portal
  TRAVEL_WALLET: 'travel_wallet', // guest portal
  COMMON_PAGES_FIRST: 'common_pages_first', // guest portal
  COMMON_PAGES_SECOND: 'common_pages_second', // guest portal
  RELOCATIONS: 'relocations', // guest portal
};

/**
 * landing page types that can appear inside landing. It contains @PAGE_TYPES and some extensions
 */
export const LANDING_PAGE_TYPES = {
  AREA: PAGE_TYPES.AREA,
  ACTIVITY: PAGE_TYPES.ACTIVITY,
  PROPERTY: PAGE_TYPES.PROPERTY,
  MISC: PAGE_TYPES.MISC,
  ABOUT_US: PAGE_TYPES.ABOUT_US,
  ABOUT_SAFARI: PAGE_TYPES.ABOUT_SAFARI,
  PRE_TRAVEL_INFO: PAGE_TYPES.PRE_TRAVEL_INFO,
  HELP_ARTICLE: PAGE_TYPES.HELP_ARTICLE,
  HELP_TUTORIAL: PAGE_TYPES.HELP_TUTORIAL,

  COVER_PAGE: LANDING_PAGE_ROLES.COVER_PAGE,
  SAFARI_OVERVIEW: LANDING_PAGE_ROLES.SAFARI_OVERVIEW,
  SAFARI_MAP: LANDING_PAGE_ROLES.SAFARI_MAP,
  SAFARI_COSTS: LANDING_PAGE_ROLES.SAFARI_COSTS,

  GUESTS: LANDING_PAGE_ROLES.GUESTS,
  SAFARI_MANIFEST: LANDING_PAGE_ROLES.SAFARI_MANIFEST,
  TRAVEL_WALLET: LANDING_PAGE_ROLES.TRAVEL_WALLET,
  RELOCATIONS: LANDING_PAGE_ROLES.RELOCATIONS,
};

/**
 * default landing page types that are extensions of @PAGE_TYPES
 */
export const defaultLandingPageTypes = [
  LANDING_PAGE_TYPES.COVER_PAGE,
  LANDING_PAGE_TYPES.SAFARI_OVERVIEW,
  LANDING_PAGE_TYPES.SAFARI_MAP,
  LANDING_PAGE_TYPES.SAFARI_COSTS,

  LANDING_PAGE_TYPES.GUESTS,
  LANDING_PAGE_TYPES.RELOCATIONS,
  LANDING_PAGE_TYPES.SAFARI_MANIFEST,
  LANDING_PAGE_TYPES.TRAVEL_WALLET,
];

// page status
export const PAGE_STATUS_DRAFT = 'draft';
export const PAGE_STATUS_PENDING = 'pending';
export const PAGE_STATUS_APPROVED = 'approved';
export const PAGE_STATUS_REJECTED = 'rejected';

// library pages
export const LIBRARY_RESOURCE = 'resource-library';
export const LIBRARY_CONTENT = 'content-library';
export const PENDING_CONTENT = 'pending-content';
export const LIBRARY_MEDIA = 'media_library';
export const LIBRARY_DOCUMENT = 'document_library';
export const LIBRARY_ITINERARIES = 'itineraries';

// DEFAULT MAP COORDS
export const LATITUDE = '-14.235004';
export const LONGITUDE = '-51.92528';

export const LIST_PLACEHOLDER_IMAGE_TYPE = {
  HIGHLIGHTS: 'highlights',
  FACTS: 'facts',
  INCLUSIONS: 'inclusions',
  EXCLUSIONS: 'exclusions',
  SUITCASE: 'suitcase',
};

//permissions
export const PERMISSIONS = {
  USERS: {
    ADD_NEW_USER: 'usersAddNewUser',
    OVERRIDE_USERS: 'usersOverrideUsers',
    TOGGLE_STATUS: 'usersToggleStatus',
    VIEW_USER_DASHBOARD: 'usersViewUserDashboard',
    MANAGE_TRIAL: 'usersManageTrial',
  },
  CONTENT_LIBRARY: {
    CREATE_ITINERARY_LAYOUTS: 'contentLibraryCanCreateItineraryLayouts',
    CREATE_NEW_PAGE: 'contentLibraryCreateNewContent',
    EDIT_PAGE: 'contentLibraryEditExistingContent',
    DELETE_PAGE: 'contentLibraryDeleteExistingContent',
  },
  DASHBOARD: {
    MANAGE_ITINERARY_DASHBOARD: 'itinerariesManageItineraryDashboard',
  },
};

// itinerary table row actions
export const ROW_ACTIONS = {
  SHOW_UPDATE_FILE_NAME_MODAL: 'showUpdateFileNameModal',
  MOVE_TO_CONFIRMED: 'moveToConfirmed',
  MOVE_TO_PIPELINE: 'moveToPipeline',
  MOVE_TO_COMPLETED: 'moveToCompleted',
  ACTIVITY_LOG: 'activityLog',
  DUPLICATE: 'duplicate',
  ASSIGN_TO_ALT_USER: 'assignToAltUser',
  ARCHIVE: 'archive',
  ENTER_PORTAL: 'enterPortal',
  DELETE: 'delete',
  UPDATE_BEDNIGHTS: 'updateBednights',
};

export const PENDING_FILE_TASKS = 'pendingFileTasks';

export const INSIDER_ACCESS_URL = 'https://www.safariportal.app/insider-access';

export const REQUEST_DEMO_URL = 'https://www.safariportal.app/request-demo';

// Privacy Policy , Terms & Conditions
export const PRIVACY_POLICY_URL = 'https://www.safariportal.app/privacy-policy';
export const TERMS_OF_SERVICE_URL = 'https://www.safariportal.app/terms-of-service';

export const CONTINENTS = {
  africa: 'African',
  antarctica: 'Antarctic',
  australia: 'Australian, NZ & South Pacific',
  asia: 'Indian, Middle Eastern & Asian',
  europe: 'European',
  north_america: 'North American',
  south_america: 'South American',
};

export const GOOGLE_MAP_CONTAINER_CLASSNAME = '.mapContainerClassName';
