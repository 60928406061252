import React, { ReactNode, MouseEvent, PropsWithChildren, ComponentProps, FC } from 'react';

import classes from 'classnames';
import { prop } from 'ramda';
import { Link } from 'react-router-dom';

import Group from './Group';
import ButtonIcon from './Icon';
import styles from './index.module.css';

type CommonButtonProps = PropsWithChildren<{
  block?: boolean;
  className?: string;
  disabled?: boolean;
  icon?: FC;
  iconRight?: boolean;
  iconProps?: Partial<ComponentProps<typeof ButtonIcon>>;
  loading?: boolean;
  isWidthFitContent?: boolean;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  shape?: 'circle' | 'ellipse';
  size?: 'extraSmall' | 'small' | 'small-long' | 'medium' | 'large';
  type?:
    | 'default'
    | 'success'
    | 'warning'
    | 'danger'
    | 'primary'
    | 'primary2'
    | 'secondary'
    | 'secondary2'
    | 'black'
    | 'text'
    | 'grey'
    | 'dark-grey'
    | 'facebook'
    | 'twitter'
    | 'undesirable'
    | 'empty';
}>;

type LinkProps = ComponentProps<Link> & CommonButtonProps & { anchorRef?: React.Ref<HTMLAnchorElement> };
type AnchorProps = ComponentProps<'a'> &
  Required<Pick<ComponentProps<'a'>, 'href'>> &
  CommonButtonProps & { anchorRef?: React.Ref<HTMLAnchorElement> };
type ButtonProps = Omit<ComponentProps<'button'>, 'type'> &
  CommonButtonProps & { htmlType?: ComponentProps<'button'>['type']; anchorRef?: React.Ref<HTMLButtonElement> };

// @ts-expect-error
function Button(props: LinkProps): JSX.Element;
function Button(props: AnchorProps): JSX.Element;
function Button(props: ButtonProps): JSX.Element;
function Button(props: LinkProps & AnchorProps & ButtonProps): JSX.Element {
  const {
    children,
    className,
    icon,
    iconProps = {},
    onClick,
    block = false,
    disabled = false,
    iconRight = false,
    loading = false,
    isWidthFitContent = false,
    shape,
    size,
    type,
    htmlType,
    anchorRef,
    ...rest
  } = props;
  const handleClick = (e: MouseEvent<HTMLElement>) => {
    if (disabled) return;
    if (loading) return;
    if (onClick) {
      onClick(e);
    }
  };

  const buttonClasses = classes(
    styles.button,
    block && prop('button-block', styles),
    disabled && prop('button-disabled', styles),
    loading && prop('button-loading', styles),
    isWidthFitContent && prop('width-fit-conent', styles),
    type && prop(`button-${type}`, styles),
    shape && prop(`button-${shape}`, styles),
    size && prop(`button-${size}`, styles),
    className,
    'custom-button',
    `custom-button-type-${type ?? 'initial'}`,
    disabled && 'custom-button-disabled',
    loading && 'custom-button-loading',
    size && 'custom-button-size',
  );

  const iconNode = (
    <ButtonIcon
      loading={loading}
      component={icon as ReactNode}
      width="1.6rem"
      height="1.6rem"
      className="button__icon"
      {...iconProps}
    />
  );

  const kids = (
    <>
      {!iconRight && iconNode}
      {React.Children.map(children, (child: ReactNode) => {
        if (child == null) return;
        if (typeof child === 'string') return <span>{child}</span>;
        return child;
      })}
      {iconRight && iconNode}
    </>
  );

  if (props.to) {
    return (
      <Link {...rest} onClick={handleClick} className={buttonClasses} ref={anchorRef}>
        {kids}
      </Link>
    );
  }

  if (props.href) {
    return (
      <a {...rest} onClick={handleClick} className={buttonClasses} ref={anchorRef}>
        {kids}
      </a>
    );
  }

  return (
    <button
      {...rest}
      disabled={disabled}
      type={htmlType}
      onClick={handleClick}
      className={buttonClasses}
      ref={anchorRef}
      children={kids}
    />
  );
}

Button.Group = Group;

export default Button;
