import React, { PropsWithChildren } from 'react';

import { Route } from 'react-router';
import { useLocation } from 'react-router-dom';

import MapPage from 'components/Pages/Map';

const HelperRoutesMiddleware = ({ children }: PropsWithChildren) => {
  const location = useLocation();

  if (location.pathname === '/map') {
    return <Route path="/map" render={() => <MapPage />} />;
  }

  return children;
};

export default HelperRoutesMiddleware;
