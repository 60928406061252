import React from 'react';

import cx from 'classnames';

import Icon from 'components/shared/Icon';
import Close from 'components/shared/Icon/icons/Close';

import styles from '../index.module.css';

export function ModalNoPortal(props) {
  // BASE SIZE PROPS - sm, md(base), lmd, lg, auto
  const { isOpen, children, toClose, withoutCloseBtn, innerRef, modalClasses, modalContentClasses, marginClasses } =
    props;

  const wrapperClasses = cx(styles.modalWrap, !isOpen && styles.noPortalOpen);

  return (
    <div className={wrapperClasses} ref={innerRef}>
      <div tabIndex={0} onClick={toClose} className={styles.modalBackdrop} />
      <div className={marginClasses}>
        <div className={modalClasses}>
          <div className={modalContentClasses}>
            {!withoutCloseBtn && (
              <button type="button" className={styles.modalClose} tabIndex={0} onClick={toClose}>
                <Icon component={Close} className={styles.closeIcon} />
              </button>
            )}

            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
