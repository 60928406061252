export const LOREM_IPSUM_TEXT =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.';

export const SYMBOLS_LIMIT = 100_000;

export const APP_HAS_CHANGES_TEXT = 'Attention! There are unsaved updates, are you sure you want to leave this page?';

export const GENERAL_ERROR_MESSAGE = 'Oops! Something went wrong';

export const DUPLICATE_ERROR_MESSAGE = (
  <>
    A page with this handle already exists.
    <br />
    Please update either the Name or Tags to continue.
  </>
);

export const EDITING_PERMISSION_TEXT = 'You don’t have editing permission anymore. Please contact your account admin.';
export const EDITING_PERMISSION_BLOCK = (
  <>
    You don’t have editing permission anymore.
    <br />
    Please contact your account admin.
  </>
);

export const PDF_WARNING =
  'The Export to PDF functionality works best in Safari browser.<br><br>We are working on a cross-browser solution, but in the meantime, if you are having trouble exporting your itinerary into PDF, please try Safari browser.<br><br>If you do not have access to Safari browser, please email us at <a href="mailto:support@safariportal.app?subject=Safari Portal PDF Exporting">support@safariportal.app</a> for assistance.<br><br>Thank you for your understanding!';

const VIDEO_HOST_LINK = 'YouTube, Vimeo or Wistia Link';
export const ENTER_VIDEO_HOST_LINK = 'Enter ' + VIDEO_HOST_LINK;
export const PLEASE_ENTER_VIDEO_HOST_LINK = 'Please enter a valid ' + VIDEO_HOST_LINK;
export const PLEASE_ENTER_CORRECT_LINK = ' ' + 'Please enter a valid link';
export const PLEASE_ENTER_CORRECT_HTML = ' ' + 'The code you entered is not valid';

export const getDimensionsError = (width, height) =>
  `Your file cannot be uploaded because it is too big. Please resize it so it’s less than ${width}x${height} pixels.`;

export const LINK_COPIED = 'The link has been copied to your clipboard.';

export const FLIGHTS = {
  CHECKBOXES: {
    NOTIFY: 'Notify me if this flight is cancelled, delayed, or rescheduled.',
    UPDATE: 'Update the client-facing itinerary if this flight is changed.',
  },
  ALERTS: {
    CHANGE: 'There has been a flight change.',
    CHANGE2: 'There has been a change to this flight. Please check directly with the airline.',
    CHECK_INFO: 'Please check this flight information & other affected events.',
    CHANGED_DATES: 'You have changed the dates for this itinerary.',
    ORG_BY_DAY:
      'You originally added this flight using a specific date, but this itinerary is no longer showing specific dates since it is ‘Organized by Day.’',
    NOT_ACCURATE: 'Please verify flight information as it may not be accurate.',
  },
};

export const getAlertWithAttention = (str) => {
  return (
    <>
      <u>ATTENTION REQUIRED</u>: {str}
    </>
  );
};
