import { MIME_TYPES } from 'utils/asset';

import * as errors from './errors';
import * as types from './types';

export const getFileUploadError = async ({
  file,
  allowedMimeTypes,
  maxFileSize,
  maxImageWidth = Infinity,
  maxImageHeight = Infinity,
}: types.GetFileUploadErrorProps) => {
  if (!allowedMimeTypes.includes(file.type)) {
    return errors.getMarkup(errors.ERROR_FILE_INVALID_TYPE);
  }

  if (maxFileSize < file.size / 1024 / 1024) {
    return errors.getMarkup(errors.ERROR_TOO_LARGE);
  }

  if ([MIME_TYPES.JPEG, MIME_TYPES.PNG, MIME_TYPES.SVG].includes(file.type)) {
    const imageDimensions: types.IImageDimensions = await new Promise((res) => {
      return getImageDimensions(URL.createObjectURL(file), res);
    });

    if (maxImageWidth < imageDimensions.width || maxImageHeight < imageDimensions.height) {
      return errors.getMarkup(errors.ERROR_DIMENSIONS, { width: maxImageWidth, height: maxImageHeight });
    }
  }

  return null;
};

export function getImageDimensions(url: string, cb: ({ width, height }: types.IImageDimensions) => void) {
  const img = new Image();
  img.onload = () => {
    cb({ width: img.naturalWidth, height: img.naturalHeight });
  };
  img.src = url;
}
