import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: [],
  pagination: {
    totalCount: 0,
    totalPages: 0,
  },
  consultants: [],
};

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsersAction(state, action) {
      const { data, meta } = action.payload;
      state.list = data;
      state.pagination = meta;
    },
    updateUserStatusAction(state, action) {
      const { id, status } = action.payload;
      const { list } = state;

      const index = list.findIndex((u) => u.id === id);
      if (index > -1) {
        list[index].status = status;
      }
    },
    updateUserAction(state, action) {
      const { id } = action.payload;
      const { list } = state;

      const index = list.findIndex((u) => u.id === id);
      if (index > -1) {
        list[index] = action.payload;
      }
    },
    resetUsersStoreAction: () => {
      return initialState;
    },
    setConsultantsAction(state, action) {
      state.consultants = action.payload;
    },
  },
});

export const { setUsersAction, updateUserStatusAction, resetUsersStoreAction, setConsultantsAction, updateUserAction } =
  slice.actions;

export default slice.reducer;
