import React, { ChangeEvent, FC, useCallback, useState } from 'react';

import classNames from 'classnames';
import { isNil, prop } from 'ramda';
import MaskedInput from 'react-text-mask';

import Button from 'components/shared/Button';
import BaseInput from 'components/shared/FormAdapters/FormBase/Input';
import { showSnackbar } from 'hooks/useSnackbar';
import { copyTextToClipboard, makeId } from 'utils/common';
import { getMask, getPlaceholder } from 'utils/form';

import styles from './index.module.css';
import { IDefaultInputProps } from './types';

const searchInputProps = {
  autoComplete: 'off',
  autoCorrect: 'off',
  autoCapitalize: 'off',
  spellCheck: 'false',
};

const DefaultInput: FC<IDefaultInputProps> = ({
  size,
  isError,
  disabled,
  prefix,
  postfix,
  search,
  marginBottom,
  noBorder,
  readOnly,
  className,
  startAdornment,
  adornmentProps = {},
  onFocus,
  onBlur,
  spellCheck,
  mask,
  typeofmask,
  placeholder,
  withBackground,
  postfixClassName,
  prefixClassName,
  hasCopyBtn,
  copyBtnText = 'Copy',
  copyBtnFn,
  featured,
  ...props
}) => {
  const defaultInputContainerClasses = classNames(
    styles.inputContainer,
    size && prop(`inputContainer-${size}`, styles),
    prefix && prop('inputContainer-withPrefix', styles),
    postfix && prop('inputContainer-withPostfix', styles),
    disabled && prop('inputContainer-disabled', styles),
    isError && prop('inputContainer-isError', styles),
    search && prop('inputContainer-search', styles),
    marginBottom && prop('inputContainer-marginBottom', styles),
    noBorder && prop('inputContainer-noBorder', styles),
    featured && prop('inputContainer-featured', styles),
    'custom-input-text-container',
    disabled && 'custom-input-text-container-disabled',
    isError && 'custom-input-text-container-isError',
  );

  const defaultInputClasses = classNames(
    styles.input,
    prefix && prop('input-withPrefix', styles),
    postfix && prop('input-withPostfix', styles),
    search && prop('input-search', styles),
    noBorder && prop('input-noBorder', styles),
    readOnly && prop('input-readOnly', styles),
    startAdornment && prop('input-startAdornment', styles),
    withBackground && prop('input-withBackground', styles),
    className,
    'custom-input-text',
    readOnly && 'custom-input-text-readOnly',
  );

  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [autoFocus, setAutoFocus] = useState<boolean | undefined>(props.autoFocus);
  const [internalValue, setInternalValue] = useState<string>('');
  const [key, setKey] = useState<string>(makeId(5));
  const value = props.onChange ? props.value : internalValue;
  const onChange = props.onChange
    ? props.onChange
    : (event: ChangeEvent<HTMLInputElement>) => setInternalValue(event.target.value);

  const resultMask = getMask(typeofmask, mask);
  const maskPlaceholder = getPlaceholder(typeofmask, placeholder);
  const maskedProps = {
    mask: resultMask,
    placeholder: maskPlaceholder,
  };

  //@ts-ignore
  const handleFocus = (...args) => {
    setIsFocused(true);
    //@ts-ignore
    onFocus && onFocus(...args);
  };
  //@ts-ignore
  const handleBlur = (...args) => {
    setIsFocused(false);
    //@ts-ignore
    onBlur && onBlur(...args);

    setAutoFocus(false);

    if (isNil(spellCheck)) {
      setKey(makeId(5));
    }
  };
  const handleCopyValue = useCallback(() => {
    if (typeof copyBtnFn === 'function') {
      return copyBtnFn();
    }

    copyTextToClipboard(value as string);
    //@ts-ignore
    showSnackbar({ text: 'Value has been copied to your keyboard' });
  }, [value]);

  const commonInputProps = {
    ...props,
    className: defaultInputClasses,
    size: placeholder?.length,
    disabled,
    readOnly,
    onFocus: handleFocus,
    onBlur: handleBlur,
    spellCheck: isNil(spellCheck) ? isFocused : spellCheck,
    autoFocus,
    style: { ...props.style, paddingLeft: adornmentProps?.width ? adornmentProps.width + 10 : undefined },
    value,
    onChange,
  };

  return (
    <div className={defaultInputContainerClasses} key={key}>
      {startAdornment && (
        <div style={{ width: adornmentProps?.width }} className={styles.startAdornment}>
          {startAdornment}
        </div>
      )}
      {prefix && <div className={classNames(styles.prefix, prefixClassName && prefixClassName)}>{prefix}</div>}

      {!resultMask && (
        //@ts-ignore
        <BaseInput {...(search ? searchInputProps : {})} {...commonInputProps} placeholder={placeholder} />
      )}
      {/*@ts-ignore*/}
      {resultMask && <MaskedInput {...commonInputProps} {...maskedProps} />}

      {postfix && <div className={classNames(styles.postfix, postfixClassName && postfixClassName)}>{postfix}</div>}
      {hasCopyBtn && (
        <Button htmlType="button" className={styles.copyBtn} onClick={handleCopyValue}>
          {copyBtnText}
        </Button>
      )}
    </div>
  );
};

export default DefaultInput;
