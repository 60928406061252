import { FC } from 'react';

import cx from 'classnames';

import { useEscAction } from 'hooks/useEscAction';
import noop from 'utils/noop';

import Modal from '../GlobalModalArea/Modal';
import s from './index.module.css';
import { IAModalProps } from './types';

export const AModal: FC<IAModalProps> = ({
  header,
  headerPlacement,
  headerClassName,
  bodyClassName,
  contentClassName,
  closeButtonClassName,
  size = 'lg',
  isOpen,
  onClose = noop,
  onReturn,
  withoutCloseBtn = false,
  withoutBackdrop,
  wrapperClassName,
  children,
  footerClassName,
  footer,
  customBackButton,
  withoutPadding,
  isCloseOnEsc,
}) => {
  useEscAction(() => (isCloseOnEsc ? onClose() : noop()));

  return (
    <Modal
      isOpen={isOpen}
      toClose={onClose}
      contentClassName={cx(s[`content-${size}`], contentClassName)}
      closeButtonClassName={cx(s.closeButton, closeButtonClassName)}
      returnButtonClassName={s.returnButton}
      iconSize="2.5rem"
      withoutCloseBtn={withoutCloseBtn}
      toReturn={onReturn}
      withReturnBtn={!!onReturn || !!customBackButton}
      withoutBackdrop={withoutBackdrop}
      wrapperClassName={wrapperClassName}
      customBackButton={customBackButton}
    >
      <div className={cx(s.header, headerPlacement && s[`header-${headerPlacement}`], headerClassName)}>{header}</div>
      <div id="AModalBody" className={cx(s.body, withoutPadding && s.withoutPadding, bodyClassName)}>
        {children}
      </div>
      {footer && <div className={cx(s.footerWrapper, footerClassName)}>{footer}</div>}
    </Modal>
  );
};
