import { createSlice } from '@reduxjs/toolkit';

const defaultOptions = {
  hasTags: true,
};

const initialState = {
  open: null,
  settings: {},
  options: defaultOptions,
  lastAsset: {},
};

const slice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    onSaveAction: () => {},
    onDeleteAction: () => {},
    onEditAction: () => {},
    onBackAction: () => {},
    onUploadAction: () => {},

    openModalAction: (state, action) => {
      state.open = action.payload.type;
      state.settings = action.payload.settings || {};
      state.options = action.payload.options || defaultOptions;
    },
    closeModalAction: (state) => {
      state.open = null;
      state.options = defaultOptions;
    },
    changeSettingsAction: (state, action) => {
      state.settings = { ...(state.settings || {}), ...action.payload };
    },
    changedLastAssetAction: (state, action) => {
      state.lastAsset = action.payload;
    },
  },
});

export const {
  onSaveAction,
  onDeleteAction,
  onEditAction,
  onBackAction,
  onUploadAction,
  openModalAction,
  closeModalAction,
  changeSettingsAction,
  changedLastAssetAction,
} = slice.actions;

export default slice.reducer;
