import { FC } from 'react';

import { useController } from 'react-hook-form';

import { BaseInput } from 'components/shared/BaseFormComponents/Input';

import { IHookInputProps } from './types';

export const HookInput: FC<IHookInputProps> = ({
  name,
  control,
  label,
  helpText = '',
  defaultValue = '',
  isDisabled = false,
  onChange: customOnChange = () => null,
}) => {
  const {
    field: { value, onBlur, onChange },
    fieldState: { error },
  } = useController({ name, control, defaultValue });

  return (
    <BaseInput
      value={value}
      onChange={(value) => {
        onChange(value);
        customOnChange(value.target.value);
      }}
      onBlur={onBlur}
      label={label}
      helpText={helpText}
      isDisabled={isDisabled}
      errorMessage={error?.message || ''}
    />
  );
};
