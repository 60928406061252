import { FORM_ERROR } from 'final-form';

import * as R from 'ramda';

import { camelizeKeys } from '../humps';
import {
  currencyMask,
  decimalCurrencyMask,
  decimalNumberMask,
  numberMask,
  percentMask,
  phoneMask,
  dateMask,
  ssnMask,
  cvvMask,
} from './masks';

export function throwSubmissionError(response: any) {
  const { title, description } = R.prop('errors', response) || {};

  let errors = {};

  if (title && typeof description !== 'string') {
    errors = { ...errors, [FORM_ERROR]: title };
  }

  if (description && typeof description === 'string') {
    errors = { ...errors, [FORM_ERROR]: description };
    // errorNotification(description);
  }

  if (description && typeof description !== 'string') {
    const camelizeddescription = camelizeKeys(description);
    errors = { ...errors, ...camelizeddescription };
  }

  return errors;
}

export function throwDataSubmissionError(error: Error) {
  if (R.path(['response', 'status'], error) === 400) {
    return R.path(['response', 'data'], error);
  }
  return;
}

export const getName = (nameSpace: string, name: string) => (nameSpace ? `${nameSpace}.${name}` : name);

export const percentParse = (v: string) => (parseInt(v, 10) >= 100 ? '100%' : v);

export const upperLettersOnlyParse = (value: string) => value.toUpperCase().match(/[A-Z]+/g);

export const getMask = (typeOfMask: string, mask?: any) => {
  if (mask) {
    if (typeof mask === 'string') {
      const arrMask = mask.split('').map((char) => {
        if (char === '9' || char === 'X') return /\d/;
        if (char === 'a') return /[a-zA-Z]/;
        return char;
      });
      return arrMask;
    }
    return mask;
  }

  switch (typeOfMask) {
    case 'currency':
      return currencyMask;
    case 'decimalCurrency':
      return decimalCurrencyMask;
    case 'decimalNumber':
      return decimalNumberMask;
    case 'number':
      return numberMask;
    case 'percent':
      return percentMask;
    case 'phone':
      return phoneMask;
    case 'date':
      return dateMask;
    case 'ssn':
      return ssnMask;
    case 'cvv':
      return cvvMask;
    default:
      return undefined;
  }
};

export const getPlaceholder = (typeOfMask: string, placeholder?: string) => {
  if (placeholder) return placeholder;

  switch (typeOfMask) {
    case 'phone':
      return '(123) 999-9999';
    case 'currency':
      return '';
    case 'decimalCurrency':
      return '';
    case 'percent':
      return '0%';
    case 'date':
      return 'mm/dd/yyyy';
    case 'ssn':
      return '__-__-____';
    default:
      return undefined;
  }
};

export const hasCapitalLetterPred = R.test(/[A-Z]+/);
export const hasLowerCaseLetterPred = R.test(/[a-z]+/);
export const hasSpecialCharacterPred = R.test(/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+/);
export const hasDigitPred = R.test(/[0-9]+/);
export const passwordsMustBeTheSamePred = R.equals;

// export const decamelizeObjectToFormData = (obj: any) => objectToFormData(decamelizeKeys(obj));

export function immutableFormData(data?: any) {
  const newData = new FormData();
  if (data) {
    for (const [n, v] of data) {
      newData.append(n, v);
    }
  }

  return newData;
}

/**
 * since final-form's array fields can return array of empty objects or nulls,
 * there is a need to clean it up
 *
 */
export function cleanUpArrayFields(page: any) {
  return Object.keys(page).reduce((acc, key) => {
    let currentValue = acc[key];
    if (!Array.isArray(currentValue)) {
      return acc;
    }

    currentValue = currentValue.filter((item) => !R.isNil(item) && !R.isEmpty(item));

    return { ...acc, [key]: currentValue };
  }, page);
}
