import mockData from './mockData';

export default {
  pageTypeContextProps: {
    leftSubheadline: 'A Note About Your Itinerary',
    leftHeadline: 'Tailor made travel',
    headlineHint: 'Don’t worry, this can be edited within an itinerary.',
    subheadlineEditable: true,
  },
  useMockData: mockData,
};
