import React from 'react';

const Pencil = ({ width, height, stroke = '#fff', ...props }) => (
  <svg width={width} height={height} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.69063 20.6559H4.5C4.30109 20.6559 4.11033 20.5769 3.96967 20.4363C3.82902 20.2956 3.75 20.1048 3.75 19.9059V15.7153C3.74966 15.6179 3.76853 15.5214 3.80553 15.4313C3.84253 15.3412 3.89694 15.2593 3.96563 15.1903L15.2156 3.9403C15.2854 3.86943 15.3686 3.81316 15.4603 3.77475C15.5521 3.73633 15.6505 3.71655 15.75 3.71655C15.8495 3.71655 15.9479 3.73633 16.0397 3.77475C16.1314 3.81316 16.2146 3.86943 16.2844 3.9403L20.4656 8.12155C20.5365 8.19134 20.5928 8.27452 20.6312 8.36626C20.6696 8.458 20.6894 8.55647 20.6894 8.65592C20.6894 8.75538 20.6696 8.85385 20.6312 8.94559C20.5928 9.03733 20.5365 9.12051 20.4656 9.1903L9.21563 20.4403C9.1466 20.509 9.06469 20.5634 8.9746 20.6004C8.88452 20.6374 8.78802 20.6563 8.69063 20.6559V20.6559Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M12.75 6.40591L18 11.6559"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Pencil;
