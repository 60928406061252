import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  hasChanges: false,

  multipleEntitiesRegister: {
    entityIds: [],
  },
};

const slice = createSlice({
  name: 'unsavedChanges',
  initialState,
  reducers: {
    setAppHasChangesAction: (state, action) => {
      state.hasChanges = action.payload;
    },
    resetUnsavedChangesState: () => initialState,

    registerEntity: (state, { payload }) => {
      state.multipleEntitiesRegister.entityIds.push(payload.id);
      state.multipleEntitiesRegister[payload.id] = false;
    },
    setEntityState: (state, { payload }) => {
      state.multipleEntitiesRegister[payload.id] = payload.value;
    },
  },
});

export const { setAppHasChangesAction, registerEntity, setEntityState, resetUnsavedChangesState } = slice.actions;

export default slice.reducer;
