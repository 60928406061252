import { useEffect, useState } from 'react';

import cx from 'classnames';

import Check from '../../../Icon/icons/Check';
import s from './index.module.css';

function PasswordRequirementContent({ value }: { value: string }) {
  const requirements = [
    { id: 1, title: 'One number (0-9)', isValid: false, regExp: /[0-9]/g },
    { id: 2, title: '8 characters min', isValid: false, regExp: /^.{8,}$/g },
    { id: 3, title: 'One uppercase', isValid: false, regExp: /[A-Z]/g },
    {
      id: 4,
      title: 'One lowercase',
      isValid: false,
      regExp: /[a-z]/g,
    },
    {
      id: 5,
      title: 'One symbol (./!/%/$/-/_)',
      isValid: false,
      regExp: /^(?=.*[^a-zA-Z0-9])/,
    },
  ];

  const [cases, setCases] = useState(requirements);

  useEffect(() => {
    const newRequirements = cases.map((el) => ({ ...el, isValid: !!value.match(el.regExp) }));
    setCases(newRequirements);
  }, [value]);

  useEffect(() => {}, [value]);

  return (
    <div className={s.container}>
      {cases.map(({ id, title, isValid }) => {
        return (
          <div className={cx(s.content, isValid && s.isValidContent)} key={id}>
            <Check />
            <span className={s.text}>{title}</span>
          </div>
        );
      })}
    </div>
  );
}

export default PasswordRequirementContent;
