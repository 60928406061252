import { replace } from 'ramda';

export const ROOT_PATH = '/';

export const UNAUTHORIZED_PATH = '/unauthorized';
export const UNAUTHORIZED_BLOCKED_PATH = '/unauthorized/blocked';
export const UNAUTHORIZED_DELETED_PATH = '/unauthorized/deleted';

//AUTH
export const AUTH_PATH = '/auth';
export const SIGN_IN_PATH = AUTH_PATH + '/sign-in';
export const RESET_PATH = AUTH_PATH + '/reset';
export const AUTH_INVITATION_PATH = AUTH_PATH + '/invitation/:token';
export const AUTH_PASSWORD_RESET_PATH = AUTH_PATH + '/password/:token';
export const LOGIN_PATH = AUTH_PATH + '/login';
export const LOGOUT_PATH = AUTH_PATH + '/logout';
export const GUEST_LOGOUT_PATH = '/guest' + LOGOUT_PATH;

//ROOT
export const ACC_ID = '/acc/:accountId';

//DASHBOARD
export const DASHBOARD_PATH = ACC_ID + '/dashboard';
export const PENDING_CONTENT_PATH = DASHBOARD_PATH + '/pending-content';
export const CONTENT_LIBRARY_PATH = ACC_ID + '/content-library';
export const CONTENT_LIBRARY_OPENED_TABLE_PATH = CONTENT_LIBRARY_PATH + '/:content';
export const RESOURCE_LIBRARY_PATH = DASHBOARD_PATH + '/resource-library';
export const RESOURCE_LIBRARY_OPENED_TABLE_PATH = RESOURCE_LIBRARY_PATH + '/:content';
export const DOCUMENT_LIBRARY_PATH = DASHBOARD_PATH + '/document-library';
export const MEDIA_LIBRARY_PATH = DASHBOARD_PATH + '/media-library';
export const REFERRALS_PATH = DASHBOARD_PATH + '/referrals';
export const HELP_PATH = DASHBOARD_PATH + '/help';
export const ITINERARIES_PATH = DASHBOARD_PATH + '/itineraries';
export const ITINERARIES_OPENED_TABLE_PATH = ITINERARIES_PATH + '/:content';
export const DASHBOARD_USERS_PATH = DASHBOARD_PATH + '/users';

//SETTINGS
export const SETTINGS_PATH = ACC_ID + '/settings';
export const SETTINGS_ACCOUNT_PATH = SETTINGS_PATH + '/personal';
export const SETTINGS_NOTIFICATIONS_PATH = SETTINGS_PATH + '/notifications';
export const SETTINGS_COMPANY_PATH = SETTINGS_PATH + '/company';
export const SETTINGS_USERS_PATH = SETTINGS_PATH + '/users';
export const SETTINGS_SUBS_PATH = SETTINGS_PATH + '/subscriptions';
export const SETTINGS_INTEGRATIONS_PATH = SETTINGS_PATH + '/integrations';

export const SETTINGS_SUBS_PADDLE_PATH = SETTINGS_PATH + '/subscriptions-v2';

// PAGES
export const PAGE_PATH = ACC_ID + '/:libType/page/:pageId';
export const PAGE_DETAILS_PATH = PAGE_PATH + '/details';
export const PAGE_RESOURCES_PATH = PAGE_PATH + '/resources';
export const PAGE_ROOMS_PATH = PAGE_PATH + '/rooms';
export const PAGE_LANDING_PATH = PAGE_PATH + '/landing';
export const PAGE_GENERAL_INFO_PATH = PAGE_PATH + '/general-info';
export const PAGE_COSTS_PATH = PAGE_PATH + '/costs';

// SAMPLE
export const SAMPLE_PATH = ACC_ID + '/:libType/:itemType/:pageId/:step';

export const ITINERARIES_ITEM_STEP_PATH = ACC_ID + '/:libType/:itemType/:itemId(\\d+)/:step';

// CSS REDACTOR
export const CSS_REDACTOR_PATH = ACC_ID + '/css-editor/:pageId(\\d+)';

// SSR
export const TASKS = '/tasks';
export const TASKS_PATH = ACC_ID + TASKS;
export const CONTACTS = '/contacts';
export const CONTACTS_PATH = ACC_ID + CONTACTS;
export const WEBVIEW_PATH = '/webview';
export const USERS = '/users';
export const USERS_PATH = ACC_ID + USERS;

// USERS
export const OVERRIDE_PATH = ACC_ID + '/users/:userId/override';
export const CANCEL_OVERRIDE_PATH = ACC_ID + '/users/override/cancel';

export const getPath = (path, params) => {
  const parsePath = replace(/\(\\d\+\)/g, '');

  let result = parsePath(path);
  Object.keys(params).forEach((key) => {
    result = result.replace(`:${key}`, params[key]);
  });
  return result;
};
