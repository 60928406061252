import React from 'react';

import cx from 'classnames';

import styles from './index.module.css';

const DescriptionName = ({ name, className }) => {
  return (
    <div className={styles.container}>
      <div className={cx(styles.name, className)}>{name}</div>
    </div>
  );
};

export default DescriptionName;
