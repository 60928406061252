import React, { FC, useState } from 'react';

import cx from 'classnames';
import { prop } from 'ramda';

import { FormFieldWrapper } from '..';
import Icon from '../../Icon';
import Visibility from '../../Icon/icons/Visibility';
import s from '../index.module.css';
import styles from './index.module.css';
import PasswordRequirement from './PasswordRequirement';

export const BasePasswordInput: FC<any> = ({
  value,
  onChange,
  onBlur,
  label,
  withRequirements,
  errorMessage,
  isDisabled = false,
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const inputType = showPassword ? 'text' : 'password';

  const iconClasses = cx(styles.icon, showPassword && prop('iconActive', styles));
  const handleToggleIcon = () => setShowPassword(!showPassword);

  return (
    <FormFieldWrapper errorMessage={errorMessage}>
      <div className={s.wrapper}>
        <div className={s.inputWrapper}>
          <input value={value} onChange={onChange} onBlur={onBlur} type={inputType} disabled={isDisabled} />
          {label && <label className={cx({ [s.filled]: value })}>{label}</label>}
          <button tabIndex={-1} type="button" onClick={handleToggleIcon} className={styles.iconBtn}>
            <Icon component={Visibility} className={iconClasses} />
          </button>
        </div>
        {withRequirements && <PasswordRequirement value={value} />}
      </div>
    </FormFieldWrapper>
  );
};
