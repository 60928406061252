import { useCallback, useState, useEffect } from 'react';

import { getDistance } from 'utils/common';

export function useMapPoints(props) {
  const { onReady, points } = props;
  const [activeMarker, setActiveMarker] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(21);
  const newPoints = useNewPoints(points, zoomLevel);

  const getOverviewMapListItems = (indexes = []) => {
    const overviewMapList = document.getElementById('overviewMapList');

    return indexes?.map((countVal) => overviewMapList.children[countVal]?.innerHTML);
  };

  const onLoad = useCallback(
    function callback(map) {
      const bounds = new window.google.maps.LatLngBounds();
      newPoints.forEach((stop) => {
        bounds.extend(new window.google.maps.LatLng(parseFloat(stop.lat), parseFloat(stop.lng)));
      });
      map.fitBounds(bounds);

      map.addListener('tilesloaded', () => {
        setTimeout(onReady, 1000);
      });

      map.addListener('zoom_changed', () => {
        if (newPoints?.length === 1) {
          setZoomLevel(8);
        } else {
          setZoomLevel(map.getZoom());
        }
      });
    },
    [newPoints, onReady],
  );

  const handleMouseOverMarker = (location, index) => {
    const currentLabel = document.getElementsByClassName(`markerLabel${index + 1}`)?.[0];
    if (currentLabel) {
      const pos = currentLabel.getBoundingClientRect();
      setActiveMarker({
        pageX: pos?.x,
        pageY: pos?.y,
        indexes: location?.indexes,
      });
    }
  };

  return {
    getOverviewMapListItems,
    newPoints,
    onLoad,
    activeMarker,
    setActiveMarker,
    handleMouseOverMarker,
    zoomLevel,
  };
}

function getSquashedPoints(points, zoomLevel) {
  const updatedPoints = points.map((point, i) => ({ ...point, title: `${i + 1}`, index: i }));
  const res = [{ ...updatedPoints[0], indexes: [updatedPoints[0].index] }];

  for (let i = 1; i < updatedPoints.length; i++) {
    for (let j = 0; j < res.length; j++) {
      const distance = getDistance(res[j], updatedPoints[i]);

      if (res[j].title === updatedPoints[i].title) {
        continue;
      }

      if (goodForUnion(distance, zoomLevel)) {
        const newPoint = {
          ...res[j],
          title: [res[j].title, updatedPoints[i].title].join(', '),
          indexes: res[j].indexes
            ? [...res[j].indexes, updatedPoints[i].index]
            : [res[j].index, updatedPoints[i].index],
        };

        res.splice(j, 1, newPoint);
        break;
      } else if (j === res.length - 1) {
        res.push({ ...updatedPoints[i], indexes: [updatedPoints[i].index] });
      }
    }
  }

  return res;
}

function useNewPoints(points, zoomLevel) {
  const [newPoints, setNewPoints] = useState(points);

  useEffect(() => {
    setNewPoints(getSquashedPoints(points, zoomLevel));
  }, [points, zoomLevel]);

  return newPoints;
}

function goodForUnion(distance, zoomLevel) {
  return (
    distance === 0 ||
    (distance < 1 && zoomLevel < 21) ||
    (distance < 10 && zoomLevel < 20) ||
    (distance < 100 && zoomLevel < 17) ||
    (distance < 1000 && zoomLevel < 13) ||
    (distance < 2000 && zoomLevel < 11) ||
    (distance < 5000 && zoomLevel < 9) ||
    (distance < 10000 && zoomLevel < 8) ||
    (distance < 20000 && zoomLevel < 7) ||
    (distance < 40000 && zoomLevel < 6) ||
    (distance < 100000 && zoomLevel < 5) ||
    (distance < 200000 && zoomLevel < 4) ||
    (distance < 500000 && zoomLevel < 3) ||
    (distance < 1000000 && zoomLevel < 2)
  );
}
