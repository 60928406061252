import React, { FC } from 'react';

import { IVideoViewProps } from '../types';
import s from './index.module.css';

const VideoView: FC<IVideoViewProps> = ({ url, width, height, onLoad }) => {
  return <video src={url} className={s.video} width={width} height={height} onLoad={() => onLoad && onLoad()} />;
};

export default VideoView;
