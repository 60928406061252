import { symbol } from './wrapper';

const buffer = new Map();

export const generatorMiddleware = (storeApi) => (next) => async (action) => {
  if (action[symbol] === true) {
    const generator = action(storeApi.dispatch.bind(storeApi), storeApi.getState.bind(storeApi));
    let step = await generator.next();

    while (!step.done) {
      if (typeof step.value === 'function') {
        await new Promise((resolve) => {
          buffer.set(step.value, resolve);
        });
      } else if (typeof step.value === 'string') {
        await new Promise((resolve) => {
          buffer.set((action) => action.type === step.value.type, resolve);
        });
      }

      step = await generator.next();
    }

    return step.value;
  }

  const result = await next(action);

  if (typeof action === 'object') {
    for (const fn of buffer.keys()) {
      const resolve = buffer.get(fn);

      if ((await fn(action)) === true) {
        buffer.delete(fn);
        resolve();
      }
    }
  }

  return result;
};
