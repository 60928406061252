import React from 'react';

import cx from 'classnames';

import styles from './index.module.css';
import { ILandingPagesContainerProps } from './types';

// FIXME.
// Нужно подключать эти стили динамически взависимости от темы, чтобы не грузить лишний css на другие страницы.
import './nomadThemeStyles.css';
import './jbdThemeStyles.css';
import './boatsThemeStyles.css';

export const LANDING_PAGES_CONTAINER_ID = 'LANDING_PAGES_CONTAINER_ID';

function LandingPagesContainer({ children, className }: ILandingPagesContainerProps) {
  return (
    <div id={LANDING_PAGES_CONTAINER_ID} className={cx(styles.container, className, 'custom-main')}>
      {/* Need this empty div as the first element of the container in order to sticky behavior work on old Safari (12, 13 and less) */}
      <div />

      {children}
    </div>
  );
}

export default LandingPagesContainer;
