export const itinerariesListSelector = (state) => state.admin.itineraries.list;
export const itinerariesMetaSelector = (state) => state.admin.itineraries.meta;
export const searchResultCountersSelector = (state) => state.admin.itineraries.searchResultCounters;

export const currentTourRequestSelector = (state, tourRequestId) => {
  const collection = itinerariesListSelector(state);
  const currentTourRequest = collection?.find((item) => item.id === tourRequestId);
  return currentTourRequest;
};

export const tourRequestItinerariesSelector = (state, tourRequestId) => {
  const currentTourRequest = currentTourRequestSelector(state, tourRequestId);
  return currentTourRequest?.itineraries || [];
};

export const tourRequestProposalsSelector = (state, tourRequestId) => {
  const currentTourRequest = currentTourRequestSelector(state, tourRequestId);
  return currentTourRequest?.proposals || [];
};

export const tourRequestProposalSelector = (state, tourRequestId, id) => {
  const proposals = tourRequestProposalsSelector(state, tourRequestId);
  const proposal = proposals.find((item) => item?.id === id);
  return proposal;
};
