/* eslint-disable import/named */
import {
  addIndex,
  anyPass,
  assoc,
  assocPath,
  complement,
  compose,
  curry,
  equals,
  filter,
  identical,
  is,
  isEmpty,
  isNil,
  join,
  keys,
  lens,
  map,
  or,
  path,
  pathOr,
  reduce,
  reject,
  set,
  split,
  view,
  when,
} from 'ramda';

const getPath = (_p) =>
  compose(
    when(is(String), (p) => [...split('.', p)]),
    when(is(Array), compose(getPath, join('.'))),
  )(_p);

export const getIn = curry((p, v) => path(getPath(p), v));
export const getInOr = curry((defaultValue, p, v) => pathOr(defaultValue, getPath(p), v));

export const assocIn = curry((path, value, object) => assocPath(getPath(path), value, object));

export const isNotDefined = anyPass([isEmpty, isNil]);

export const isDefined = complement(isNotDefined);

export const renameKeys = curry((keysMap, obj) =>
  reduce((acc, key) => assoc(keysMap[key] || key, obj[key], acc), {}, keys(obj)),
);

const makePropLens = (key) => lens(getIn(key), assocPath(getPath(key)));
export const setParam = curry((params, lensKey, key) => set(makePropLens(lensKey), key, params));
export const getParam = curry((params, lensKey) => view(makePropLens(lensKey), params));

export const mapIndexed = addIndex(map);
export const filterIndexed = addIndex(filter);
export const rejectIndexed = addIndex(reject);

export const alt = curry((f1, f2, ...args) => or(f1(...args), f2(...args)));

export const fork = (joinFunction, f1, f2) => (val) => joinFunction(f1(val), f2(val));

export const notEquals = complement(equals);
export const isTrue = identical(true);
export const isFalse = identical(false);
