import { FC } from 'react';

import cx from 'classnames';

import s from './index.module.css';
import { IBaseRadioButtonProps } from './types';

export const BaseRadioButton: FC<IBaseRadioButtonProps> = ({ label, value, isSelected, onChange, isDisabled }) => {
  return (
    <label className={cx(s.container, { [s.disabledContent]: isDisabled })}>
      <input type="radio" value={value || label} checked={isSelected} disabled={isDisabled} onChange={onChange} />
      {label}
      <span className={s.checkmark}></span>
    </label>
  );
};
