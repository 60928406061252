import axiosApi, { axiosAuth } from 'utils/axios';
import { getDomain, DOMAINS, isGPCustomDomain } from 'utils/domain';

import store from '../configureStore';
import { LOGOUT_PATH, GUEST_LOGOUT_PATH } from '../constants/routes';
import { accountIdSelector } from '../store/user/selectors';

export async function signIn({ email, password }) {
  return axiosAuth
    .post('/auth/login', {
      email,
      password,
    })
    .then(({ data }) => data);
}

export async function guestSignIn({ email, password, guestPortalId, isRulesAccepted }) {
  return axiosAuth
    .post('/auth/login', {
      email,
      password,
      isRulesAccepted,
      guestPortalId,
    })
    .then(({ data }) => data);
}

export async function signUp(credentials) {
  return axiosAuth.post('/auth/registration', credentials).then(({ data }) => data.data);
}

export async function checkInviteToken(token) {
  return axiosApi.get(`/auth/invitation/${token}`).then(({ data }) => data);
}

export async function acceptInvite(values) {
  return axiosApi.patch(`/auth/invitation/${values.token}`, values).then(({ data }) => data);
}

export async function resetPassword(payload) {
  return axiosAuth.post('/auth/reset-password-request', payload).then(({ data }) => data);
}

export async function setNewPassword(payload) {
  return axiosAuth.post('/auth/reset-password', payload).then(({ data }) => data);
}

export function getAccountId() {
  return accountIdSelector(store.getState());
}

export function getAccPath() {
  const state = store.getState();
  const accId = accountIdSelector(state);

  return `/acc/${accId}`;
}

export const logoutOnServer = async () => {
  const domain = getDomain();
  const logoutPath = domain === DOMAINS.GUEST || isGPCustomDomain() ? GUEST_LOGOUT_PATH : LOGOUT_PATH;

  return await axiosApi.post(window.location.origin + logoutPath);
};
