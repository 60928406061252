import React, { ChangeEvent, FC, useState } from 'react';

import cx from 'classnames';
import * as R from 'ramda';

import DefaultInput from 'components/shared/FormAdapters/FormDefault/Input';
import List from 'components/shared/FormAdapters/FormDefault/TagsInput/List';
import Icon from 'components/shared/Icon';
import EnterIcon from 'components/shared/Icon/icons/EnterIcon';
import Text from 'components/shared/Typography/Text';

import styles from './index.module.css';
import { ITagsInputProps } from './types';

const needToHandle = (e: KeyboardEvent & ChangeEvent<HTMLInputElement>) =>
  e.charCode === 13 && e.target.value.trim().length !== 0;
const onPress = R.pipe(needToHandle, R.always, R.both);

const TagsInput: FC<ITagsInputProps> = ({ value, onChange, type = 'default', ...rest }) => {
  const [tmpValue, setTmpValue] = useState<string>('');

  const clearInput = R.pipe(R.always(''), setTmpValue);
  const persistent = R.pipe(onChange, clearInput);

  const append = R.pipe(R.always(value), R.append(tmpValue.trim()), R.uniq, persistent);
  //@ts-ignore
  const reject = R.pipe(R.equals, R.reject, R.applyTo(value), onChange);
  //@ts-ignore
  const onKeyPress = R.pipe(onPress, R.applyTo(append), R.applyTo(true));
  const isArray = R.is(Array, value);

  return (
    <>
      <div className={cx(styles.inputWrapper, type === 'primary' && styles.inputWrapperPrimary)}>
        <DefaultInput
          {...rest}
          value={tmpValue}
          onChange={(e) => setTmpValue(e.target.value)}
          onKeyPress={(e: KeyboardEvent) => {
            if (e.key === 'Enter') {
              onKeyPress(e as KeyboardEvent & ChangeEvent<HTMLInputElement>);
              e.preventDefault();
            }
          }}
          onBlur={(e) => {
            const value = e.target?.value?.trim();
            value?.length && append(e);
          }}
        />
        <div className={styles.placeholder}>
          <Text accent type="gray">
            Press Enter to Add
          </Text>
          <Icon component={EnterIcon} width="1.9rem" height="1.3rem" className={styles.icon} />
        </div>
      </div>

      <div className={styles.listContainer}>
        <List type={type} collection={value} isArray={isArray} onDelete={reject} />
      </div>
    </>
  );
};

export default TagsInput;
