import React from 'react';

const Visibility = (props) => (
  <svg width="14" height="10" viewBox="0 0 14 10" {...props}>
    <path d="M6.99992 10.0004C3.91504 10.0086 1.14759 8.08971 0.0324764 5.16994C-0.00822129 5.06241 -0.00822129 4.93834 0.0324764 4.83081C1.53829 0.91849 5.8848 -1.00872 9.7348 0.521468C11.672 1.2907 13.2104 2.85397 13.9755 4.83081C14.0162 4.93834 14.0162 5.06241 13.9755 5.16994C12.8522 8.08971 10.0848 10.0086 6.99992 10.0004ZM0.935965 5.00451C2.37666 8.40402 6.26736 9.97556 9.61271 8.50327C11.1592 7.82502 12.3883 6.57606 13.0557 5.00451C11.615 1.60501 7.73248 0.0334612 4.38713 1.49748C2.84062 2.17573 1.60341 3.43297 0.935965 5.00451Z" />
    <path d="M7.00001 7.72529C5.51862 7.72529 4.31396 6.50114 4.31396 4.99576C4.31396 3.49039 5.51862 2.26624 7.00001 2.26624C8.48141 2.26624 9.68606 3.49039 9.68606 4.99576C9.68606 6.50941 8.48141 7.72529 7.00001 7.72529ZM7.00001 3.17608C6.00699 3.17608 5.20117 3.99494 5.20117 5.00404C5.20117 6.01313 6.00699 6.83199 7.00001 6.83199C7.99303 6.83199 8.79885 6.01313 8.79885 5.00404C8.79885 3.99494 7.99303 3.17608 7.00001 3.17608Z" />
  </svg>
);

export default Visibility;
