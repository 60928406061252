import React from 'react';

import Button from 'components/shared/Button';
import { WarningCircle } from 'components/shared/Icon/phosphorIcons';

import s from './index.module.css';

function UpdateVersionDialog({ withSaveWarning, onClick }) {
  return (
    <div className={s.adjuster}>
      <div className={s.caption}>
        <div>
          <WarningCircle size={24} className={s.warningIcon} />
        </div>
        <div className={s.body}>
          {withSaveWarning ? (
            <div>
              A new version of Safari Portal is available. Please{' '}
              <span className={s.important}>
                save your work <br className={s.br} /> first
              </span>{' '}
              and then click refresh to ensure your dashboard is up to date.
            </div>
          ) : (
            <div className={s.text}>
              A new version is available. Please click the refresh
              <br className={s.br} />
              button to ensure everything is up to date.
            </div>
          )}
          <div className={s.buttonWrapper}>
            <Button type="default" size="small-long" className={s.button} onClick={onClick}>
              Refresh
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateVersionDialog;
