import React from 'react';

import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import Form from 'components/shared/Assets/shared/Form';
import Button from 'components/shared/Button';
import View from 'components/shared/FileDropZone/View';
import FormActions from 'components/shared/FormAdapters/FormDefault/FormActions';
import Tag from 'components/shared/FormAdapters/FormDefault/TagsInput/Tag';
import { changeSettingsAction, closeModalAction, onBackAction, onDeleteAction, onSaveAction } from 'store/assets';
import { settingsSelector } from 'store/assets/selectors';
import { accountIdSelector, isSuperAdminSelector } from 'store/user/selectors';
import { ASSET_STORAGE_TYPES } from 'utils/asset';
import isNoData from 'utils/isNoData';

import i18n from '../../../../i18n';
import Loader from '../../Loader';
import Modal from '../../Modal';
import FormTitle from '../../Typography/FormTitle';
import style from './index.module.css';
import s from './index.module.css';

const getErrors = (diff, settings = {}) => {
  const errors = {};

  if (diff?.name === '') {
    errors.name = settings?.translate ? i18n.t('common.validation.required') : 'This is a required field';
  }

  if (settings.withTags && diff?.tags?.length === 0) {
    errors.tags = settings?.translate ? i18n.t('common.validation.required') : 'This is a required field';
  }

  return errors;
};

const EditModal = () => {
  const dispatch = useDispatch();
  const settings = useSelector(settingsSelector);
  const accountId = useSelector(accountIdSelector);
  const isCanUpdate = settings.asset?.policies?.update;

  const isMine =
    useSelector(isSuperAdminSelector) ||
    settings.asset.accountId === accountId ||
    settings.asset.storageType === ASSET_STORAGE_TYPES.DOCUMENTS ||
    isCanUpdate;

  const onSave = () => {
    dispatch(
      onSaveAction({
        errors: getErrors(settings.data, settings),
        isMine,
      }),
    );
  };

  if (!settings.asset) return null;

  let element;

  if (isMine) {
    element = (
      <Form
        asset={settings.asset}
        data={settings.data}
        namePlaceholder={settings.namePlaceholder}
        tagsSubTitle={settings.tagsSubTitle}
        tagsPlaceholder={settings.tagsPlaceholder}
        saveButtonText={settings.saveButtonText}
        errors={settings.errors}
        onUpdate={(diff) => {
          const data = { ...settings.data, ...diff };
          const update = { data };

          if (settings.errors) {
            update.errors = getErrors(data, settings);
          }

          dispatch(changeSettingsAction(update));
        }}
        onDelete={() => dispatch(onDeleteAction())}
        onSave={onSave}
        isPointEnabled={settings.isCenterOfImageEnabled && settings.asset.type === 'media'}
        fileAccId={settings.asset?.accountId}
        maxWidth={550}
        withTags={settings.withTags}
        withAssetSettings={settings.withAssetSettings}
        translate={settings.translate}
      />
    );
  } else {
    element = (
      <>
        <div className={s.elementWrapper}>
          <View
            url={settings.asset.url}
            assetStorageType={settings.asset.storageType}
            type={settings.asset.mimeType}
            filename={settings.asset.filename}
            height={settings.asset.height}
            width={settings.asset.width}
            point={settings.asset.imageCoords?.length === 2 ? settings.asset.imageCoords : [50, 50]}
            onSelectPoint={
              settings.isCenterOfImageEnabled &&
              settings.asset.type === 'media' &&
              ((p) => dispatch(changeSettingsAction({ asset: { ...settings.asset, imageCoords: p } })))
            }
            dynamic
            fileAccId={settings.asset?.accountId}
          />
        </div>
        {settings.withTags && !isNoData(settings.asset?.tags) && (
          <div className={s.tags}>
            <FormTitle>Tags</FormTitle>
            <div style={{ marginTop: '-1rem' }}>
              {settings.asset.tags.map((t) => (
                <Tag key={t} title={t} />
              ))}
            </div>
          </div>
        )}
        <FormActions isRight marginTop>
          <Button children="Back to Replace Photo" onClick={() => dispatch(onBackAction())} />
          <Button children={settings.saveButtonText} onClick={onSave} />
        </FormActions>
      </>
    );
  }

  return (
    <Modal
      isOpen={true}
      toClose={() => dispatch(closeModalAction())}
      className={cx(s.modal, 'custom-edit-modal')}
      contentClassName={style.modalContent}
      children={
        <div style={{ position: 'relative' }}>
          {Boolean(settings.loading) && <Loader isFillBlock />}

          <div className={style.modalHeader}>{settings.modalTitle}</div>

          {element}
        </div>
      }
    />
  );
};

export default EditModal;
