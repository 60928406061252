import React, { FC } from 'react';

import classNames from 'classnames';
import { prop } from 'ramda';

import BaseCheckboxButton from 'components/shared/FormAdapters/FormBase/Checkbox';

import styles from './index.module.css';
import { ICheckboxProps } from './types';

const Checkbox: FC<ICheckboxProps> = ({
  className,
  noMargin,
  size,
  children,
  isError,
  smText,
  mediumText,
  titleClassName,
  theme,
  checkboxClassName,
  centered,
  type = 'default',
  ...rest
}) => {
  const { disabled, checked } = rest;
  const checboxWrapperClasses = classNames(
    styles.checkboxWrapper,
    noMargin && prop('checkboxWrapper-noMargin', styles),
    checked && prop('checkboxWrapper-active', styles),
    isError && prop('checkboxWrapper-error', styles),
    disabled && prop('checkboxWrapper-disabled', styles),
    size && prop(`checkboxWrapper-${size}`, styles),
    className,
    'custom-checkbox-wrapper',
  );

  const checboxClasses = classNames(
    styles.checkbox,
    type === 'primary' && prop('checkbox-primary', styles),
    checked && prop('checkbox-active', styles),
    disabled && prop('checkbox-disabled', styles),
    theme && prop(`checkbox-${theme}`, styles),
    centered && prop('checkbox-y-centered', styles),
    checkboxClassName,
    'custom-checkbox',
  );

  const checboxTitleClasses = classNames(
    styles.checkboxTitle,
    smText && prop('checkboxTitle-smText', styles),
    mediumText && prop('checkboxTitle-mediumText', styles),
    titleClassName,
    'custom-checkbox-title',
  );

  return (
    <label className={checboxWrapperClasses}>
      <BaseCheckboxButton {...rest} className={styles.hiddenBaseCheckboxButton} />
      <span className={checboxClasses} />
      <span className={checboxTitleClasses}>{children}</span>
    </label>
  );
};

export default Checkbox;
