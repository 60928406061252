import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  type: '',
  data: null,
  loading: false,
};

const slice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModalAction(state, action) {
      const { type, data } = action.payload;
      state.data = data;
      state.type = type;
    },
    closeModalAction(state) {
      state.type = '';
      state.loading = initialState.loading;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

export const { openModalAction, closeModalAction, setLoading } = slice.actions;

export default slice.reducer;
