import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import * as authApi from 'api/auth';
import { setLoginAsUserId } from 'utils/loginAs';

const initialState = {
  isAuthenticated: false,
  afterLogout: false,
  isPending: false,
  isLoginAs: false,
  isUnauthorized: false,
  unauthorizedUser: undefined,
};

export const checkInviteToken = createAsyncThunk('auth/checkInvite', (token) => authApi.checkInviteToken(token));

// Slice
const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess: (state) => {
      state.isAuthenticated = true;
      state.afterLogout = false;
      state.isUnauthorized = false;
    },
    logoutSuccess: (state) => {
      state.isAuthenticated = false;
      state.afterLogout = true;
    },
    changeSignUpStatus: (state, action) => {
      state.signUpStatus = action.payload;
    },
    loginAsAction: (state, action) => {
      setLoginAsUserId(action.payload);

      state.isLoginAs = true;
    },
    logoutAsAction: (state) => {
      setLoginAsUserId();

      state.isLoginAs = false;
    },
    unauthorizedAction: (state, action) => {
      state.isUnauthorized = action.payload.isUnauthorized;
      state.isUserBlocked = action.payload.isUserBlocked;
      state.isUserDeleted = action.payload.isUserDeleted;
      state.isUserGuestOnly = action.payload.isUserGuestOnly;
      state.unauthorizedUser = action.payload.unauthorizedUser;
    },
  },
  extraReducers: {
    [checkInviteToken.fulfilled]: (state) => {
      state.isPending = false;
    },
    [checkInviteToken.pending]: (state) => {
      state.isPending = true;
    },
  },
});

// Actions
export const { loginSuccess, logoutSuccess, loginAsAction, logoutAsAction, unauthorizedAction } = slice.actions;

export default slice.reducer;
