// import { TASKS, TASK_SETS, CONTACTS, AUTH_PATH, WEBVIEW_PATH, USERS } from 'constants/routes';

// eslint-disable-next-line import/named
import { pipe, replace, trim } from 'ramda';

export const toSlug = pipe(
  trim,
  replace(/\s*[^A-Za-z0-9.-]\s*/g, '-'), // replace non-alphanumeric with hyphen
  replace(/-+/g, '-'), // replace multiple hyphens with single
  replace(/^-+|-+$/g, ''), // remove leading/trailing hyphens
  encodeURIComponent,
);

const createGuestDomainRegex = (isCustomDomain = false) => {
  const domainPart = isCustomDomain ? '[\\w.-]+' : 'guest\\.[\\w.-]+';
  // eslint-disable-next-line no-invalid-regexp
  return new RegExp(
    `^https?://${domainPart}(:\\d+)?/[\\w.%.-]+/[\\w.%.-]+(/(guest-portal|itinerary)/[\\w.%.-]+)?$`,
    'i',
  );
};

// export const checkUrlIsSSR = (url) => {
//   return (
//     gpDomainRouteRegexp.test(url.href) ||
//     url.pathname.startsWith('/api/') ||
//     url.pathname.startsWith('/disabled') ||
//     url.pathname.startsWith('/paddle/checkout') ||
//     accRouteRegexp.test(url.href) ||
//     url.pathname.includes(TASKS) ||
//     url.pathname.includes(TASK_SETS) ||
//     url.pathname.includes(CONTACTS) ||
//     url.pathname.includes(AUTH_PATH) ||
//     url.pathname.includes(WEBVIEW_PATH) ||
//     url.pathname.includes(USERS) ||
//     url.pathname.includes('/subscriptions-v2') ||
//     gpInitialRouteRegex.test(url.href) ||
//     gpWithCustomDomainInitialRouteRegex.test(url.href)
//   );
// };

const guestDomainRegex = createGuestDomainRegex();
const guestCustomDomainRegex = createGuestDomainRegex(true);

export const checkUrlIsGuestPortal = (url) => {
  const isSafariPortalOrLocalhost = /(safariportal.app|localhost)/i.test(url.href);
  const regexToUse = isSafariPortalOrLocalhost ? guestDomainRegex : guestCustomDomainRegex;
  return regexToUse.test(url.href);
};
