import { ITINERARIES_ITEM_TYPES } from 'constants/index';

import { EDetailsMode } from '../../components/Pages/Admin/Itinerary/Details/types';
import {
  GUEST_PORTAL_PATH_STEP_TO_STEP_MAP,
  GUEST_PORTAL_STEP_LIST,
  GUEST_PORTAL_STEP_LIST_CHRONOLOGICAL,
  GUEST_PORTAL_STEP_TO_LABEL_MAP,
  GUEST_PORTAL_STEP_TO_PATH_STEP_MAP,
} from './guestPortal/map';
import {
  ITINERARY_PATH_STEP_TO_STEP_MAP,
  ITINERARY_STEP_LIST,
  ITINERARY_STEP_TO_LABEL_MAP,
  ITINERARY_STEP_TO_PATH_STEP_MAP,
} from './itinerary/map';
import {
  LOOKBOOK_PATH_STEP_TO_STEP_MAP,
  LOOKBOOK_STEP_LIST,
  LOOKBOOK_STEP_TO_LABEL_MAP,
  LOOKBOOK_STEP_TO_PATH_STEP_MAP,
} from './lookbook/map';

export const ITINERARIES_ITEM_TYPE_TO_STEP_PATH_MAP = {
  [ITINERARIES_ITEM_TYPES.ITINERARY]: ITINERARY_PATH_STEP_TO_STEP_MAP,
  [ITINERARIES_ITEM_TYPES.LOOKBOOK]: LOOKBOOK_PATH_STEP_TO_STEP_MAP,
  [ITINERARIES_ITEM_TYPES.GUEST_PORTAL]: GUEST_PORTAL_PATH_STEP_TO_STEP_MAP,
};

export const ITINERARIES_ITEM_TYPE_TO_PATH_STEP_MAP = {
  [ITINERARIES_ITEM_TYPES.ITINERARY]: ITINERARY_STEP_TO_PATH_STEP_MAP,
  [ITINERARIES_ITEM_TYPES.LOOKBOOK]: LOOKBOOK_STEP_TO_PATH_STEP_MAP,
  [ITINERARIES_ITEM_TYPES.GUEST_PORTAL]: GUEST_PORTAL_STEP_TO_PATH_STEP_MAP,
};

export const ITINERARIES_ITEM_TYPE_TO_STEP_LABEL_MAP = {
  [ITINERARIES_ITEM_TYPES.ITINERARY]: ITINERARY_STEP_TO_LABEL_MAP,
  [ITINERARIES_ITEM_TYPES.LOOKBOOK]: LOOKBOOK_STEP_TO_LABEL_MAP,
  [ITINERARIES_ITEM_TYPES.GUEST_PORTAL]: GUEST_PORTAL_STEP_TO_LABEL_MAP,
};

export const ITINERARIES_ITEM_TYPE_TO_LABEL_MAP = {
  [ITINERARIES_ITEM_TYPES.ITINERARY]: 'Itinerary',
  [ITINERARIES_ITEM_TYPES.LOOKBOOK]: 'Lookbook',
  [ITINERARIES_ITEM_TYPES.GUEST_PORTAL]: 'Guest Portal',
};

export function getProposalSteps(type, proposal) {
  switch (type) {
    case ITINERARIES_ITEM_TYPES.ITINERARY:
      return ITINERARY_STEP_LIST;
    case ITINERARIES_ITEM_TYPES.LOOKBOOK:
      return LOOKBOOK_STEP_LIST;
    case ITINERARIES_ITEM_TYPES.GUEST_PORTAL:
      return proposal?.detailsMode === EDetailsMode.Chronological ||
        location.search.includes(`mode=${EDetailsMode.Chronological}`)
        ? GUEST_PORTAL_STEP_LIST_CHRONOLOGICAL
        : GUEST_PORTAL_STEP_LIST;
  }
}
