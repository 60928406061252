import { FC } from 'react';

import Icon from 'components/shared/Icon';
import Loading from 'components/shared/Icon/icons/Loading';

import { IButtonIconProps } from '../types';

const ButtonIcon: FC<IButtonIconProps> = ({ loading, component, ...rest }) => {
  if (loading) {
    return <Icon spin component={Loading} {...rest} />;
  }

  if (component) {
    return <Icon component={component} {...rest} />;
  }

  return null;
};

export default ButtonIcon;
