import { pickBy } from 'ramda';

import {
  ACCOUNT_TYPE_ADMIN,
  ACCOUNT_TYPE_AGENT,
  ACCOUNT_TYPE_OWNER,
  PAGE_TYPE_ABOUT_SAFARI,
  PAGE_TYPE_ABOUT_US,
  PAGE_TYPE_ACTIVITY,
  PAGE_TYPE_AREA,
  PAGE_TYPE_HELP_ARTICLE,
  PAGE_TYPE_HELP_TUTORIAL,
  PAGE_TYPE_MISC,
  PAGE_TYPE_PRE_TRAVEL_INFO,
  PAGE_TYPE_PROPERTY,
  PERMISSIONS,
} from '../../constants';

const allowedTypes = {
  [ACCOUNT_TYPE_ADMIN]: [
    PAGE_TYPE_ABOUT_US,
    PAGE_TYPE_PROPERTY,
    PAGE_TYPE_AREA,
    PAGE_TYPE_ACTIVITY,
    PAGE_TYPE_MISC,
    PAGE_TYPE_ABOUT_SAFARI,
    PAGE_TYPE_PRE_TRAVEL_INFO,
    PAGE_TYPE_HELP_TUTORIAL,
    PAGE_TYPE_HELP_ARTICLE,
  ],
  [ACCOUNT_TYPE_OWNER]: [PAGE_TYPE_PROPERTY, PAGE_TYPE_AREA, PAGE_TYPE_ACTIVITY, PAGE_TYPE_MISC],
  [ACCOUNT_TYPE_AGENT]: [
    PAGE_TYPE_ABOUT_US,
    PAGE_TYPE_PROPERTY,
    PAGE_TYPE_AREA,
    PAGE_TYPE_ACTIVITY,
    PAGE_TYPE_MISC,
    PAGE_TYPE_ABOUT_SAFARI,
    PAGE_TYPE_PRE_TRAVEL_INFO,
  ],
};

export const userSelector = (state) => state.user;
export const userNameSelector = (state) => `${state.user.firstName} ${state.user.lastName}`;
export const currentAccountSelector = (state) => state.user.currentAccount;
export const currentAccountTableSizeSelector = (state) => state.user.currentAccount.tableSize;
export const accountTypeSelector = (state) => state.user?.currentAccount?.type;
export const isSuperAdminSelector = (state) => state.user?.currentAccount?.type === ACCOUNT_TYPE_ADMIN;
export const isOwnerSelector = (state) => state.user?.currentAccount?.type === ACCOUNT_TYPE_OWNER;
export const isAgentSelector = (state) => state.user?.currentAccount?.type === ACCOUNT_TYPE_AGENT;
export const isOwnerOrSuperSelector = (state) => isOwnerSelector(state) || isSuperAdminSelector(state);
export const isAccountOwnerSelector = (state) => state.user?.currentAccount?.owner;
export const accountIdSelector = (state) => state.user?.currentAccount?.id;
export const isAccountFulfilledSelector = (state) => state.user?.currentAccount?.settingsFilled;
export const isAccountProcessedByStripe = (state) => state.user?.currentAccount?.processingByStripe;
export const accountListSelector = (state) => state.user?.accountList;
export const hasAgentAccountSelector = (state) => state.user.accountList.some((acc) => acc.type === ACCOUNT_TYPE_AGENT);
export const hasAdminAccountSelector = (state) => state.user.accountList.some((acc) => acc.type === ACCOUNT_TYPE_ADMIN);
export const hasOwnerAccountSelector = (state) => state.user.accountList.some((acc) => acc.type === ACCOUNT_TYPE_OWNER);
export const brandingSelector = (state) => state.user.branding;
export const loadingSelector = (state) => state.user.loading;
export const pageTypeOptionsSelector = (state) => {
  const accountType = accountTypeSelector(state);

  const pageTypes = [
    { label: 'Property', value: PAGE_TYPE_PROPERTY },
    { label: 'Area', value: PAGE_TYPE_AREA },
    { label: 'Activity', value: PAGE_TYPE_ACTIVITY },
    { label: 'Miscellaneous', value: PAGE_TYPE_MISC },
    { label: 'About Us', value: PAGE_TYPE_ABOUT_US },
    { label: 'Introductory Notes', value: PAGE_TYPE_ABOUT_SAFARI },
    { label: 'Pre Travel Information', value: PAGE_TYPE_PRE_TRAVEL_INFO },
    { label: 'Help Tutorial', value: PAGE_TYPE_HELP_TUTORIAL },
    { label: 'Help Article', value: PAGE_TYPE_HELP_ARTICLE },
  ];

  return pageTypes.filter((page) => allowedTypes[accountType].includes(page.value));
};

export const usersPermissionsSelector = (state) => {
  const onlyUsers = (val, key) => key.startsWith('users');

  return pickBy(onlyUsers, state.user?.currentAccount?.permissions);
};
export const canViewUsersDashboardSelector = (state) => canManageUsers(state, PERMISSIONS.USERS.VIEW_USER_DASHBOARD);
export const canAddNewUserSelector = (state) => canManageUsers(state, PERMISSIONS.USERS.ADD_NEW_USER);
export const canOverrideUsersSelector = (state) => canManageUsers(state, PERMISSIONS.USERS.OVERRIDE_USERS);
export const canToggleStatusSelector = (state) => canManageUsers(state, PERMISSIONS.USERS.TOGGLE_STATUS);
export const canManageTrialSelector = (state) => canManageUsers(state, PERMISSIONS.USERS.MANAGE_TRIAL);
export const canCreateItineraryLayoutsSelector = (state) =>
  hasPermission(state, PERMISSIONS.CONTENT_LIBRARY.CREATE_ITINERARY_LAYOUTS);
export const canCreateNewContentPageSelector = (state) =>
  hasPermission(state, PERMISSIONS.CONTENT_LIBRARY.CREATE_NEW_PAGE);
export const canEditContentPageSelector = (state) => hasPermission(state, PERMISSIONS.CONTENT_LIBRARY.EDIT_PAGE);
export const canDeleteContentPageSelector = (state) => hasPermission(state, PERMISSIONS.CONTENT_LIBRARY.DELETE_PAGE);
export const canManageItineraryDashboard = (state) =>
  hasPermission(state, PERMISSIONS.DASHBOARD.MANAGE_ITINERARY_DASHBOARD);

const canManageUsers = (state, permission) =>
  isSuperAdminSelector(state) &&
  (isAccountOwnerSelector(state) ||
    (!isAccountOwnerSelector(state) && state.user?.currentAccount?.permissions?.[permission]));

const hasPermission = (state, permission) => state.user?.currentAccount?.permissions?.[permission];

export const privacyPolicyFilledSelector = (state) => state.user?.currentAccount?.privacyPolicyFilled;

export const isGoogleIntegrationEnabledSelector = (state) => state.user?.currentAccount?.googleIntegrationEnabled;
