import React, { FC } from 'react';

import classNames from 'classnames';
import { prop } from 'ramda';

import styles from './index.module.css';
import { IIconProps } from './types';

const Icon: FC<IIconProps> = ({
  // affect outter <i>...</i>
  className,

  // affect inner <svg>...</svg>
  component: Component,
  viewBox,
  spin,
  spinDirection = 'clockwise',
  rotate,
  hide,

  tabIndex,
  onClick,
  disabled,

  width = '2rem',
  height = '2rem',
  fill = 'currentColor',
  stroke,
  strokeWidth,
  children,
  anchorRef,
  ...restProps
}) => {
  const classString = classNames(
    'icon',
    prop('icon', styles),
    spin && spinDirection === 'clockwise' && prop('icon-spin', styles),
    spin && spinDirection === 'counterclockwise' && prop('icon-spin-reverse', styles),
    onClick && !disabled && prop('icon-clickable', styles),
    disabled && prop('icon-disabled', styles),
    hide && prop('icon-hide', styles),
    className,
  );

  const svgStyle = rotate
    ? {
        msTransform: `rotate(${rotate}deg)`,
        transform: `rotate(${rotate}deg)`,
      }
    : undefined;

  const innerSvgProps = {
    width,
    height,
    fill,
    stroke,
    strokeWidth,
    style: svgStyle,
    viewBox,
  };

  if (!viewBox) {
    delete innerSvgProps.viewBox;
  }

  // component > children
  const renderInnerNode = () => {
    if (Component) {
      /*@ts-ignore*/
      return <Component {...innerSvgProps}>{children}</Component>;
    }

    if (children) {
      return (
        <svg {...innerSvgProps} viewBox={viewBox}>
          {children}
        </svg>
      );
    }

    return null;
  };

  let iconTabIndex = tabIndex;
  if (iconTabIndex === undefined && !disabled && onClick) {
    iconTabIndex = -1;
  }

  return (
    <span
      role="img"
      {...restProps}
      tabIndex={iconTabIndex}
      onClick={disabled ? undefined : onClick}
      className={classString}
      ref={anchorRef}
    >
      {renderInnerNode()}
    </span>
  );
};

export default Icon;
