import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { ViewHead } from 'components/shared/Assets/ViewModal/ViewHead';
import View from 'components/shared/FileDropZone/View';
import FormGroup from 'components/shared/FormAdapters/FormDefault/FormGroup/Base';
import Tag from 'components/shared/FormAdapters/FormDefault/TagsInput/Tag';
import FormTitle from 'components/shared/Typography/FormTitle';
import { closeModalAction } from 'store/assets';
import { settingsSelector, optionsSelector } from 'store/assets/selectors';
import { MIME_TYPES } from 'utils/asset';
import { calculateDimensionsByRatio } from 'utils/calculateDimensionsByRatio';

import Modal from '../../Modal';
import s from './index.module.css';

const ViewModal = () => {
  const dispatch = useDispatch();
  const preview = useSelector(settingsSelector);
  const options = useSelector(optionsSelector);
  const MAX_WIDTH = window.screen.availWidth - 150;
  const MAX_HEIGHT = 550;
  const normalizedDimensions = calculateDimensionsByRatio(preview?.width, preview?.height, MAX_WIDTH, MAX_HEIGHT);
  const isDocument = preview?.mimeType === MIME_TYPES.PDF;

  return (
    //@ts-ignore
    <Modal isOpen={true} toClose={() => dispatch(closeModalAction())} size="auto" className="custom-view-modal">
      {/*@ts-ignore*/}
      <div style={{ width: !isDocument && normalizedDimensions.width, maxWidth: '100%' }}>
        <ViewHead asset={preview} isDocument={isDocument} />
        <FormGroup className={s.imageContainer}>
          {/*@ts-ignore*/}
          <View
            url={preview?.url}
            assetStorageType={preview.storageType}
            type={preview?.mimeType}
            filename={preview?.filename}
            height={preview?.height}
            width={preview?.width}
            maxHeight={MAX_HEIGHT}
            maxWidth={MAX_WIDTH}
            dynamic
            fileAccId={preview?.accountId}
          />
        </FormGroup>
        {options.hasTags && (
          <>
            {/*@ts-ignore*/}
            <FormTitle>Tags</FormTitle>
            <div style={{ marginTop: '-1.8rem' }}>{preview?.tags.map((t: string) => <Tag key={t} title={t} />)}</div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default ViewModal;
