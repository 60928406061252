import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  email: '',
  firstName: '',
  lastName: '',
  accountList: [],
  currentAccount: {},
  branding: {
    template: 'split',
  },
  loading: false,
};

// reducer for app current user
const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getMeSuccessAction: (state, action) => {
      const { user, account } = action.payload;
      const { id, email, firstName, lastName, accountList, createdAt } = user;
      state.id = id;
      state.createdAt = createdAt;
      state.email = email;
      state.firstName = firstName;
      state.lastName = lastName;
      state.accountList = accountList;
      state.currentAccount = account;
    },
    setTableSizeAction(state, action) {
      state.currentAccount.tableSize = action.payload;
    },
    resetUserStateAction: () => {},
    updateProfile: (state, action) => {
      const { firstName, lastName } = action.payload;
      state.firstName = firstName;
      state.lastName = lastName;
    },
    changeAccountAction: (state, action) => {
      const account = state.accountList.find((acc) => acc.id === action.payload);

      if (account) {
        state.currentAccount = account;
      }
    },
    updateAccountAction: (state, action) => {
      state.currentAccount = action.payload;

      const index = state.accountList.findIndex((acc) => acc.id === state.currentAccount.id);

      if (index > -1) {
        state.accountList[index] = action.payload;
      }
    },
    updateBranding: (state, action) => {
      state.branding = {
        ...state.branding,
        ...action.payload,
      };
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setSettingsFilledAction: (state, action) => {
      state.currentAccount.settingsFilled = action.payload;
    },
    updatePrivacyPolicyFilledAction: (state) => {
      state.currentAccount.privacyPolicyFilled = true;
    },
  },
});

export const {
  getMeSuccessAction,
  setTableSizeAction,
  resetUserStateAction,
  updateProfile,
  changeAccountAction,
  updateAccountAction,
  updateBranding,
  setLoading,
  setSettingsFilledAction,
  updatePrivacyPolicyFilledAction,
} = slice.actions;

export default slice.reducer;
