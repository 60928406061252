import React from 'react';

import { useDispatch } from 'react-redux';

import { getFileUploadError } from 'components/shared/FileDropZone/utils';
import { showErrorSnackbar } from 'hooks/useSnackbar';
import { changeSettingsAction } from 'store/assets';

import { compressImage } from './utils';

type Props = {
  allowedMimeTypes: string[];
  maxFileSize: number;
  maxImageWidth?: number;
  maxImageHeight?: number;
  clipboardInputRef: React.RefObject<HTMLDivElement>;
  isModalStateChange: boolean;
};

const useClipboardImagePaste = ({
  allowedMimeTypes,
  maxFileSize,
  maxImageWidth = Infinity,
  maxImageHeight = Infinity,
  clipboardInputRef,
  isModalStateChange,
}: Props) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    const clipboardInputRefVar = clipboardInputRef.current;
    if (!clipboardInputRefVar) return;

    const handlePasteFile = async (event: ClipboardEvent) => {
      const files = event.clipboardData?.files;
      if (!files) return;

      const result = [];

      for (const file of files) {
        try {
          const compressedFile = await compressImage(file);

          const expectedError = await getFileUploadError({
            file: compressedFile,
            allowedMimeTypes,
            maxFileSize,
            maxImageHeight,
            maxImageWidth,
          });

          if (expectedError) return showErrorSnackbar(expectedError);

          result.push({
            url: URL.createObjectURL(compressedFile),
            mimeType: compressedFile.type,
            filename: compressedFile.name,
          });
        } catch (error) {
          let errorMessage = 'Something went wrong';
          if (error instanceof Error) errorMessage = error.message;
          console.error(error);
          showErrorSnackbar(errorMessage);
        }
      }

      dispatch(changeSettingsAction({ files: result }));
    };

    clipboardInputRefVar.addEventListener('paste', handlePasteFile);

    return () => {
      clipboardInputRefVar.removeEventListener('paste', handlePasteFile);
    };
  }, [allowedMimeTypes, clipboardInputRef, dispatch, maxFileSize, maxImageHeight, maxImageWidth, isModalStateChange]);
};

export { useClipboardImagePaste };
export default useClipboardImagePaste;
