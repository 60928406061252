// import testImgUrl from 'components/shared/images/basePreview.jpg';

const mockData = () => ({
  title: 'A Note About Your Itinerary',
  // primaryImage: testImgUrl,
  blocks: [
    {
      type: 'text',
      data: {
        placeholder:
          'Dear John and Jane:\n\nThis is where you can tell your guests about how amazing this safari itinerary is and why you have designed it the way you have. You may want to describe each part of the trip individually or just give a general overview: it is up to you. We have given you the flexibility here to make your expertise shine! There is no character limit on this section.\n\nWarm regards\nYour friendly Safari Consultant,',
      },
    },
  ],
});

export default mockData;
