/* eslint-disable no-console */

// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://bit.ly/CRA-PWA

import { invokeServiceWorkerUpdateFlow } from 'utils/updateApp';

export function register(config) {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      const swUrl = '/service-worker.js';
      registerSW(swUrl, config);
    });
  }
}

async function registerSW(swUrl) {
  const registration = await navigator.serviceWorker.register(swUrl);

  if (registration.waiting) {
    invokeServiceWorkerUpdateFlow(registration);
  }

  // detect controller change and refresh the page
  // Check to see if the page is currently controlled.
  let isControlled = Boolean(navigator.serviceWorker.controller);

  // Listen for a change to the current controller...
  navigator.serviceWorker.addEventListener('controllerchange', () => {
    if (isControlled) {
      // ...and if the page was previously controlled, reload the page.
      window.location.reload();
    } else {
      // ...otherwise, set the flag for future updates, but don't reload.
      isControlled = true;
    }
  });

  navigator.serviceWorker.ready.then(() => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'service-worker-ready', payload: null }));
    }
  });

  registration.onupdatefound = () => {
    const installingWorker = registration.installing;

    if (installingWorker == null) {
      return;
    }

    installingWorker.onstatechange = () => {
      if (installingWorker.state === 'installed') {
        if (navigator.serviceWorker.controller) {
          // At this point, the updated precached content has been fetched,
          // but the previous service worker will still serve the older
          // content until all client tabs are closed.

          // if there is a previous service worker - invoke updating dialog
          if (registration.waiting) {
            invokeServiceWorkerUpdateFlow(registration);
          }
        }
      }
    };
  };
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}

export async function checkSWAlone() {
  const id = Date.now().toString(36);
  const reg = await navigator.serviceWorker.ready;
  const active = reg.active;

  if (!active) return true;

  return new Promise((res) => {
    navigator.serviceWorker.addEventListener('message', function cb(msg) {
      if (msg.data?.id === id && msg.data.type === 'CHECK_ALONE') {
        navigator.serviceWorker.removeEventListener('message', cb);
        res(msg.data.alone);
      }
    });

    active.postMessage({ type: 'CHECK_ALONE', id });
  });
}
