const ACCESS_TOKEN = 'accessToken';
const REFRESH_TOKEN = 'refreshToken';

export const getAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN);
};

export const setAccessToken = (token) => {
  localStorage.setItem(ACCESS_TOKEN, token);
};

export const removeAccessToken = () => {
  localStorage.removeItem(ACCESS_TOKEN);
};

export const getRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKEN);
};

export const setRefreshToken = (token) => {
  localStorage.setItem(REFRESH_TOKEN, token);
};

export const removeRefreshToken = () => {
  localStorage.removeItem(REFRESH_TOKEN);
};

/**
 * sets access & refresh tokens in Cookies
 * @param tokenObj
 * @returns void
 */
export const setToken = ({ accessToken, refreshToken }) => {
  setAccessToken(accessToken);
  setRefreshToken(refreshToken);
};

export const isThereAnyToken = () => {
  return Boolean(getAccessToken() || getRefreshToken());
};

export function removeToken() {
  removeAccessToken();
  removeRefreshToken();
}
