import React from 'react';

import { useSelector } from 'react-redux';

import UploadModal from 'components/shared/Assets/UploadModal';
import ViewModal from 'components/shared/Assets/ViewModal';
import { openSelector } from 'store/assets/selectors';
import { ASSET_TYPE_EDIT, ASSET_TYPE_UPLOAD, ASSET_TYPE_VIEW } from 'store/assets/types';

import EditModal from './EditModal';

const types: Record<string, any> = {
  [ASSET_TYPE_VIEW]: ViewModal,
  [ASSET_TYPE_EDIT]: EditModal,
  [ASSET_TYPE_UPLOAD]: UploadModal,
};

const AssetsModals = () => {
  const openType = useSelector(openSelector);

  if (openType === null) return null;

  const Component = types[openType] || (() => null);

  return <Component />;
};

export default AssetsModals;
