import { ReactNode } from 'react';

import { groupWith, isEmpty, pipe, sort, times } from 'ramda';

import { AlertVariant } from 'components/shared/Alert/types';
import store from 'configureStore';
import { FLIGHTS, getAlertWithAttention } from 'constants/texts';
import {
  EFlightStatus,
  EOrganizedBy,
  ETimelineType,
  ETripEvent,
  IFlightAPIEventData,
  ITripEvent,
  TTripEvent,
} from 'detailsv2/structure';
import { itineraryScheduleTypeSelector } from 'store/itinerariesItem/selectors';
import { reverseFlightDateValues } from 'utils/common';
import { addDaysToDate, formatRepresentationDate } from 'utils/date';
import { isDefined } from 'utils/ramda';

import { EventOptionError } from './Event/types';
import s from './index.module.css';
import { IDay, IDayTripEvent, TDayTripEvent, TDayTripEventCommon } from './types';

export const getDayTripEvent = <T extends ETripEvent>({
  sourceTripEvent,
  index = 0,
}: {
  sourceTripEvent: ITripEvent<T>;
  index?: number;
}): IDayTripEvent<T> => {
  return {
    id: sourceTripEvent.id,
    icon: sourceTripEvent.icon,
    position: sourceTripEvent.positions[index] || 0,
    day: sourceTripEvent.daysRange.start! + index,
    date: sourceTripEvent.datesRange.start ? addDaysToDate(sourceTripEvent.datesRange.start, index) : null,
    attachedPages: sourceTripEvent.attachedPages,
    type: sourceTripEvent.type,
    data: sourceTripEvent.data,
    datesRange: sourceTripEvent.datesRange,
    daysRange: sourceTripEvent.daysRange,
  };
};

export const getAccommodationDayTripEvents = <T extends ETripEvent.Accommodation>({
  sourceTripEvent,
}: {
  sourceTripEvent: ITripEvent<T>;
}): IDayTripEvent<T>[] => {
  const accommodationTripEvents: IDayTripEvent<T>[] = [];
  times(
    (index) =>
      accommodationTripEvents.push(
        getDayTripEvent({
          sourceTripEvent,
          index,
        }),
      ),
    getEventDuration(sourceTripEvent),
  );

  return accommodationTripEvents.map((el) => {
    const timeLineType = () => {
      if (
        sourceTripEvent.daysRange.start === sourceTripEvent.daysRange.end ||
        (isDefined(sourceTripEvent.daysRange.start) && !isDefined(sourceTripEvent.daysRange.end))
      )
        return ETimelineType.OneDay;
      if (el.day === sourceTripEvent.daysRange.start) return ETimelineType.Start;
      if (el.day === sourceTripEvent.daysRange.end) return ETimelineType.End;
      return ETimelineType.Middle;
    };

    return {
      ...el,
      timelineType: timeLineType(),
    };
  });
};

export const getRouteDayTripEvents = <T extends ETripEvent.Flight | ETripEvent.Transfer>({
  sourceTripEvent: tempTripEvent,
}: {
  sourceTripEvent: ITripEvent<ETripEvent.Transfer> | ITripEvent<ETripEvent.Flight>;
}): Array<TDayTripEventCommon[T]> => {
  const routeTripEvents: Array<TDayTripEventCommon[T]> = [];
  const sourceTripEvent = tempTripEvent.data.isIDL ? reverseFlightDateValues(tempTripEvent, true) : tempTripEvent;
  const duration = getEventDuration(sourceTripEvent);

  times((index) => {
    if (index === 0 || index === duration - 1) {
      routeTripEvents.push(
        getDayTripEvent({
          sourceTripEvent,
          index: sourceTripEvent.data.isIDL ? index - duration + 1 : index,
        }) as TDayTripEventCommon[T],
      );
    }
  }, duration);

  return routeTripEvents.map((el) => {
    const timeLineType = () => {
      if (sourceTripEvent.type === ETripEvent.Flight && sourceTripEvent.data.fromFsService) {
        const arrivalDate = formatRepresentationDate(sourceTripEvent.data.arrivalTime);
        const departureDate = formatRepresentationDate(sourceTripEvent.data.departureTime);
        if (sourceTripEvent.datesRange.start !== sourceTripEvent.data.flightDate) {
          if (arrivalDate === departureDate) return ETimelineType.OneDay;
          if (el.date === sourceTripEvent.datesRange.start) return ETimelineType.Start;
          return ETimelineType.End;
        } else {
          if (arrivalDate === departureDate) return ETimelineType.OneDay;
          if (el.date! === departureDate) return ETimelineType.Start;
          if (el.date! === arrivalDate) return ETimelineType.End;
        }
      } else {
        if (sourceTripEvent.daysRange.start === sourceTripEvent.daysRange.end) return ETimelineType.OneDay;
        if (el.day === sourceTripEvent.daysRange.start) return ETimelineType.Start;
        if (el.day === sourceTripEvent.daysRange.end) return ETimelineType.End;
      }
    };

    return {
      ...el,
      timelineType: timeLineType(),
    };
  });
};

function getActivityDayTripEvents<T extends ETripEvent.Activity>({
  sourceTripEvent,
}: {
  sourceTripEvent: ITripEvent<T>;
}): IDayTripEvent<T>[] {
  const activityTripEvents: IDayTripEvent<T>[] = [];
  times(
    (index) =>
      activityTripEvents.push(
        getDayTripEvent({
          sourceTripEvent,
          index,
        }),
      ),
    getEventDuration(sourceTripEvent),
  );

  return activityTripEvents.map((el) => {
    const timeLineType = () => {
      if (sourceTripEvent.daysRange.start === sourceTripEvent.daysRange.end) return ETimelineType.OneDay;
      if (el.day === sourceTripEvent.daysRange.start) return ETimelineType.Start;
      if (el.day === sourceTripEvent.daysRange.end) return ETimelineType.End;
      return ETimelineType.Middle;
    };

    return {
      ...el,
      timelineType: timeLineType(),
    };
  });
}

export const getEventDuration = (tripEvent: TTripEvent) => {
  if (!isDefined(tripEvent.daysRange.end)) {
    return 1;
  }

  return Math.abs(tripEvent.daysRange.end! - tripEvent.daysRange.start!) + 1;
};

export const getDayTripEvents = (tripEvents: TTripEvent[]) => {
  return tripEvents.reduce<TDayTripEvent[]>((acc, sourceTripEvent) => {
    const eventDuration = getEventDuration(sourceTripEvent);

    switch (sourceTripEvent.type) {
      case ETripEvent.Flight:
      case ETripEvent.Transfer:
        acc.push(...getRouteDayTripEvents({ sourceTripEvent }));
        break;
      case ETripEvent.Area:
        acc.push(getDayTripEvent({ sourceTripEvent }));
        break;
      case ETripEvent.Accommodation:
        acc.push(...getAccommodationDayTripEvents({ sourceTripEvent }));
        break;
      case ETripEvent.Activity:
        acc.push(...getActivityDayTripEvents({ sourceTripEvent }));
        break;
      default:
        times((index) => acc.push(getDayTripEvent({ sourceTripEvent, index })), eventDuration);
    }

    return acc;
  }, []);
};

export const getGroupedDayTripEvents = (tripEvents: TTripEvent[]) => {
  const dayTripEvents = getDayTripEvents(tripEvents);

  const sortByDay = sort<TDayTripEvent>((a, b) => a.day - b.day);
  const groupByDay = groupWith<TDayTripEvent>((a, b) => a.day === b.day);

  return pipe(sortByDay, groupByDay)(dayTripEvents);
};

export const getTimelineDays = (tripEvents: TTripEvent[]): IDay[] => {
  const tripEventsGroupedByDays = getGroupedDayTripEvents(tripEvents);

  const timelineDays: IDay[] = tripEventsGroupedByDays.reduce<IDay[]>((acc, dayTripEvents, currentIndex) => {
    if (currentIndex === 0 && dayTripEvents[0].day > 1) {
      const emptyDays = times<IDay>(
        (i) => ({
          day: i + 1,
          date: dayTripEvents[0].date ? addDaysToDate(dayTripEvents[0].date, i + 1 - dayTripEvents[0].day) : null,
          tripEvents: [],
        }),
        dayTripEvents[0].day - 1,
      );

      acc.push(...emptyDays);
    }

    acc.push({
      day: dayTripEvents[0].day,
      date: dayTripEvents[0].date,
      tripEvents: dayTripEvents.sort((t1, t2) => t1.position - t2.position),
    });

    if (tripEventsGroupedByDays[currentIndex + 1]) {
      const emptyDaysCount = tripEventsGroupedByDays[currentIndex + 1][0].day - dayTripEvents[0].day - 1;
      if (emptyDaysCount > 0) {
        times(
          (i) =>
            acc.push({
              day: dayTripEvents[0].day + i + 1,
              date: dayTripEvents[0].date ? addDaysToDate(dayTripEvents[0].date, i + 1) : null,
              tripEvents: [],
            }),
          emptyDaysCount,
        );
      }
    }

    return acc;
  }, []);

  return sortDayTripEventsByType(
    timelineDays
      .sort((a, b) => a.day! - b.day!)
      .map((el, i) => {
        return {
          ...el,
          tripEvents: el.tripEvents.map((el) => ({
            ...el,
            date: timelineDays[0].date ? addDaysToDate(timelineDays[0].date, i) : null,
          })),
          date: timelineDays[0].date ? addDaysToDate(timelineDays[0].date!, i) : null,
        };
      }),
  );
};

export function validateTripEvent(tripEvent: TDayTripEvent) {
  if (tripEvent.type === ETripEvent.Flight && tripEvent.data.fromFsService) {
    const organizedBy = itineraryScheduleTypeSelector(store.getState());

    if (tripEvent.data.flightStatus !== EFlightStatus.Common) {
      return createEventOptionError('danger', [
        getAlertWithAttention(FLIGHTS.ALERTS.CHANGE),
        FLIGHTS.ALERTS.CHECK_INFO,
      ]);
    }

    if (organizedBy === EOrganizedBy.Day) {
      return createEventOptionError(
        'danger',
        [FLIGHTS.ALERTS.ORG_BY_DAY, FLIGHTS.ALERTS.NOT_ACCURATE],
        s.changedDateAlert,
      );
    } else if (tripEvent.timelineType !== ETimelineType.End && tripEvent.data.flightDate !== tripEvent.date) {
      return createEventOptionError(
        'danger',
        `${FLIGHTS.ALERTS.CHANGED_DATES} ${FLIGHTS.ALERTS.NOT_ACCURATE}`,
        s.changedDateAlert,
      );
    }
  }
}

/**
 * Helper function for creating error objects for {@link EventOption}.
 */
const createEventOptionError = (variant: AlertVariant, content: ReactNode, className?: string): EventOptionError => {
  return {
    variant,
    content,
    className,
  };
};

export function isTripEventValid(tripEvent: TTripEvent, organizedBy: EOrganizedBy) {
  if (tripEvent._destroy) {
    return true;
  }

  const infiniteIfNull = (value: number | null) => (value === null ? Infinity : value);
  const isChronological = (t: TTripEvent) => {
    const hasStartDay = t.daysRange.start !== null;
    const hasEndDay = t.daysRange.end !== null;
    const hasOnlyStartDay = hasStartDay && !hasEndDay;
    const hasOnlyEndDay = !hasStartDay && hasEndDay;
    if (hasOnlyEndDay) return false;

    const hasStartDate = t.datesRange.start !== null;
    const hasEndDate = t.datesRange.end !== null;
    const hasOnlyStartDate = hasStartDate && !hasEndDate;
    const hasOnlyEndDate = !hasStartDate && hasEndDate;
    if (hasOnlyEndDate) return false;

    const daysOk =
      hasOnlyStartDay || t.type === ETripEvent.Flight || t.daysRange.start <= infiniteIfNull(t.daysRange.end);
    if (!daysOk) return false;

    if (organizedBy === EOrganizedBy.Date) {
      const datesOk = hasOnlyStartDate || t.type === ETripEvent.Flight || t.datesRange.start! <= t.datesRange.end!;
      if (!datesOk) return false;
    }

    return true;
  };
  const isPositionsValid = (t: TTripEvent) => t.positions.length === getEventDuration(t);

  return isChronological(tripEvent) && isPositionsValid(tripEvent);
}

export const sortTripEventsByType = (a: TDayTripEvent, b: TDayTripEvent) => {
  if (
    a.type === ETripEvent.Accommodation &&
    b.type === ETripEvent.Accommodation &&
    a.timelineType === ETimelineType.Middle &&
    b.timelineType === ETimelineType.Middle &&
    a.data.isAlternate &&
    !b.data.isAlternate
  ) {
    return 1;
  }

  if (
    a.type === ETripEvent.Accommodation &&
    b.type === ETripEvent.Accommodation &&
    a.timelineType === ETimelineType.Middle &&
    b.timelineType === ETimelineType.Middle &&
    !a.data.isAlternate &&
    b.data.isAlternate
  ) {
    return -1;
  }

  if (
    a.type === ETripEvent.Accommodation &&
    b.type === ETripEvent.Accommodation &&
    a.timelineType !== ETimelineType.Middle &&
    b.timelineType === ETimelineType.Middle
  ) {
    return -1;
  }

  if (
    a.type === ETripEvent.Accommodation &&
    a.timelineType === ETimelineType.Middle &&
    b.type !== ETripEvent.Accommodation
  ) {
    return 1;
  }

  if (
    b.type === ETripEvent.Accommodation &&
    b.timelineType === ETimelineType.Middle &&
    a.type !== ETripEvent.Accommodation
  ) {
    return -1;
  }

  return 0;
};

export const sortDayTripEventsByType = (days: IDay[]): IDay[] => {
  return days.map((day: IDay) => {
    return {
      ...day,
      tripEvents: day.tripEvents.sort(sortTripEventsByType),
    };
  });
};

export function getFlightStatus(data: IFlightAPIEventData) {
  if (data.cancelled) {
    return EFlightStatus.Cancelled;
  }
  if (
    (data.newArrivalTime && !isEmpty(data.newArrivalTime)) ||
    (data.newDepartureTime && !isEmpty(data.newDepartureTime))
  ) {
    return EFlightStatus.Rescheduled;
  }
  return EFlightStatus.Common;
}
