import React from 'react';

import classes from 'classnames';

import Icon from 'components/shared/Icon';
import LoaderIcon from 'components/shared/Icon/icons/LoaderIcon';

import Text from '../Typography/Text';
import styles from './index.module.css';

const defaultProps = {
  width: '4.8rem',
  height: '5.1rem',
  isFillBlock: false,
};

const Loader = (props) => {
  const { width, height, isFillBlock, style, text, sticky, transparent = false, iconClassName } = props;
  const wrapperClasses = classes({
    [styles.wrapper]: isFillBlock,
    [styles.stickyParent]: sticky,
    [styles.wrapperTransparent]: transparent,
  });
  const innerClasses = classes({ [styles.sticky]: sticky });

  return (
    <div className={wrapperClasses} style={style}>
      <div className={innerClasses} style={{ textAlign: 'center' }}>
        <Icon className={classes(styles.loader, iconClassName)} component={LoaderIcon} width={width} height={height} />
        {Boolean(text) && (
          <div style={{ textAlign: 'center' }}>
            <Text accent>{text}</Text>
          </div>
        )}
      </div>
    </div>
  );
};

Loader.defaultProps = defaultProps;

export default Loader;
