// values should be the same as on the backend, do not change them if not sure
export const LOOKBOOK_STEPS = {
  GENERAL_INFO: 'general_info',
  LANDING: 'lookbook',
};

export const LOOKBOOK_STEP_TO_PATH_STEP_MAP = {
  [LOOKBOOK_STEPS.GENERAL_INFO]: 'general-info',
  [LOOKBOOK_STEPS.LANDING]: 'landing',
};

export const LOOKBOOK_PATH_STEP_TO_STEP_MAP = {
  'general-info': LOOKBOOK_STEPS.GENERAL_INFO,
  landing: LOOKBOOK_STEPS.LANDING,
};

export const LOOKBOOK_STEP_TO_LABEL_MAP = {
  [LOOKBOOK_STEPS.GENERAL_INFO]: 'General Info',
  [LOOKBOOK_STEPS.LANDING]: 'Lookbook',
};

export const LOOKBOOK_STEP_LIST = [LOOKBOOK_STEPS.GENERAL_INFO, LOOKBOOK_STEPS.LANDING];
