import React from 'react';

import cx from 'classnames';

import styles from './index.module.css';
import { IFormGroupProps } from './types';

const FormGroup = ({ children, className }: IFormGroupProps) => {
  return <div className={cx(styles.formGroup, className)}>{children}</div>;
};

export default FormGroup;
