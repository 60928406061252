import { CSSProperties, forwardRef, LegacyRef, useState } from 'react';

import Observer from '@researchgate/react-intersection-observer';
import cx from 'classnames';

import { checkIsWebview } from 'utils/mobileApplication';

import Text from '../Typography/Text';
import {
  ASSET_RATIO,
  DIMENSIONS_STRATEGIES,
  DIMENSIONS_STRATEGY_TO_ITEM_STYLES_MAP,
  DIMENSIONS_STRATEGY_TO_CONTAINER_STYLES_MAP,
} from './constants';
import styles from './index.module.css';
import { IImageLoadingHandlerProps } from './types';

const ImageLoadingHandler = forwardRef(
  (
    {
      src,
      alt,
      onLoad,
      allowSave = false,
      imageClassName,
      itemClassName,
      containerStyle,
      dimensionsStrategy = DIMENSIONS_STRATEGIES.ADAPTIVE_HEIGHT,
      imageStyles,
      placeholderStyles,
      ratio = ASSET_RATIO,
      noLazyLoading = false,
      pictureSources,
      defaultSrc,
    }: IImageLoadingHandlerProps,
    ref: LegacyRef<HTMLImageElement>,
  ) => {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const noLazyLoadingFlag = checkIsWebview() ? true : noLazyLoading;
    const [isInViewport, setIsInViewport] = useState<boolean>(noLazyLoadingFlag);
    const [isError, setIsError] = useState<boolean>(false);
    let pictureErrorCount = 0;

    const handleImgLoaded = () => {
      setIsLoaded(true);

      if (onLoad) onLoad();
    };

    const imageLoadingStyles: CSSProperties = isLoaded
      ? {}
      : {
          visibility: 'hidden',
          opacity: 0,
          position: 'absolute',
        };

    const itemStyles = {
      ...DIMENSIONS_STRATEGY_TO_ITEM_STYLES_MAP[dimensionsStrategy],
    };
    const containerStyles = {
      ...DIMENSIONS_STRATEGY_TO_CONTAINER_STYLES_MAP[dimensionsStrategy],
    };

    const handleIntersection = (event: IntersectionObserverEntry, unobserve: () => void) => {
      if (event.isIntersecting) {
        setIsInViewport(true);
        unobserve();
      }
    };

    const handleError = () => {
      setIsError(true);
    };

    /*@ts-ignore*/
    const handlePictureError = (e) => {
      if (pictureErrorCount > 0) return setIsError(true);

      e.target.onerror = null;
      pictureSources.forEach((_, i) => {
        e.target.parentNode.children[i].srcset = src;
      });
      pictureErrorCount = 1;
    };

    return (
      <div
        className={cx(styles.container, !allowSave && styles.containerWithoutSave, 'custom-imagehandler-loading')}
        style={{ position: 'relative', ...containerStyle, ...containerStyles }}
      >
        {!isLoaded && (
          <Observer disabled={noLazyLoadingFlag} onChange={handleIntersection}>
            <div
              className={cx(styles.item, styles.placeholder, itemClassName, 'custom-imagehandler-item')}
              style={{ ...itemStyles, ...placeholderStyles }}
            >
              {isError ? (
                <Text className={styles.errorMessage}>
                  Failed
                  <br />
                  to load
                </Text>
              ) : (
                <PlaceholderIcon defaultSrc={defaultSrc} />
              )}
            </div>
          </Observer>
        )}

        {isInViewport &&
          (pictureSources ? (
            <>
              <picture>
                {pictureSources?.map((source, i) => <source key={i} media={source.media} srcSet={source.srcSet} />)}

                <img
                  ref={ref}
                  src={src}
                  alt={alt}
                  onLoad={handleImgLoaded}
                  onError={handlePictureError}
                  className={cx(styles.item, styles.image, imageClassName, itemClassName, 'custom-imagehandler-item')}
                  style={{ ...imageLoadingStyles, ...itemStyles, ...imageStyles }}
                  draggable={allowSave}
                  crossOrigin=""
                />
              </picture>
            </>
          ) : (
            <img
              ref={ref}
              src={src}
              alt={alt}
              onLoad={handleImgLoaded}
              onError={handleError}
              className={cx(styles.item, styles.image, imageClassName, itemClassName, 'custom-imagehandler-item')}
              style={{ ...imageLoadingStyles, ...itemStyles, ...imageStyles }}
              draggable={allowSave}
              crossOrigin=""
            />
          ))}

        {dimensionsStrategy === DIMENSIONS_STRATEGIES.ADAPTIVE_HEIGHT && (
          <div style={{ paddingBottom: (ratio[1] / ratio[0]) * 100 + '%' }} />
        )}
      </div>
    );
  },
);
/*@ts-ignore*/
ImageLoadingHandler.DIMENSIONS_STRATEGIES = DIMENSIONS_STRATEGIES;

export default ImageLoadingHandler;

function PlaceholderIcon({ defaultSrc }: { defaultSrc: string }) {
  if (defaultSrc) {
    return <img src={defaultSrc} alt={defaultSrc} className={styles.defaultSrc} />;
  }

  return (
    <svg width="28" height="23" viewBox="0 0 28 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.4161 0H1.58388C0.710448 0 0 0.710448 0 1.58388V21.0836C0 21.957 0.710448 22.6675 1.58388 22.6675H26.4161C27.2896 22.6675 28 21.957 28 21.0836V1.58388C28 0.710448 27.2896 0 26.4161 0ZM1.58388 1.25373H26.4161C26.6 1.25373 26.7463 1.4 26.7463 1.58388V20.1098L21.5851 13.0054C21.2884 12.5958 20.8119 12.3493 20.3063 12.3534C19.7964 12.3451 19.3116 12.5916 19.0149 13.0054L16.9755 15.8054L11.9606 8.90149C11.4466 8.19522 10.4561 8.03642 9.74985 8.55045C9.61612 8.64657 9.4991 8.76776 9.39881 8.90149L1.25373 20.1098V1.58388C1.25373 1.4 1.4 1.25373 1.58388 1.25373ZM13.231 21.4137H2.50328C2.37791 21.4137 2.26507 21.3427 2.21075 21.234C2.15224 21.1254 2.1606 20.9916 2.23582 20.8913L10.4227 9.66209C10.4812 9.57433 10.5815 9.52418 10.686 9.52836C10.7904 9.52836 10.8907 9.57851 10.9534 9.66209L19.1361 20.8913C19.2448 21.0334 19.2155 21.2382 19.0734 21.3469C19.0149 21.3887 18.9439 21.4137 18.8728 21.4137H13.231ZM20.4191 21.4137C20.511 20.9749 20.4149 20.5236 20.1516 20.16L17.7612 16.8752L20.0346 13.7451C20.0973 13.6615 20.1976 13.6113 20.3021 13.6113C20.4066 13.6072 20.5069 13.6573 20.5654 13.7451L25.7558 20.8913C25.8645 21.0334 25.8352 21.2382 25.6931 21.3469C25.6346 21.3887 25.5636 21.4137 25.4925 21.4137H20.4191Z"
        fill="#DADADA"
      />
      <path
        d="M21.3475 8.87701C22.8603 8.86865 24.0806 7.64 24.0723 6.12716C24.0639 4.61433 22.8353 3.39403 21.3224 3.40239C19.8138 3.41074 18.5977 4.63104 18.5977 6.1397C18.6018 7.65253 19.8305 8.88119 21.3433 8.87701H21.3475ZM21.3475 4.65612C22.1666 4.66448 22.8269 5.33313 22.8186 6.15224C22.8102 6.97134 22.1415 7.63164 21.3224 7.62328C20.5075 7.61492 19.8514 6.95462 19.8514 6.1397C19.8472 5.32059 20.5075 4.65194 21.3266 4.64776C21.335 4.64776 21.3391 4.64776 21.3475 4.64776V4.65612Z"
        fill="#DADADA"
      />
    </svg>
  );
}
