import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  termsOfServiceModal: {
    isOpen: false,
    type: undefined,
  },
};

// reducer for app current user
const slice = createSlice({
  name: 'termsOfUse',
  initialState,
  reducers: {
    setTermsOfServiceModalOpenAction: (state, { payload }) => {
      state.termsOfServiceModal.isOpen = true;
      state.termsOfServiceModal.type = payload;
    },
    setTermsOfServiceModalCloseAction: (state) => {
      state.termsOfServiceModal.isOpen = false;
      state.termsOfServiceModal.type = undefined;
    },
  },
});

export const { setTermsOfServiceModalOpenAction, setTermsOfServiceModalCloseAction } = slice.actions;

export default slice.reducer;
