import { FC } from 'react';

import cx from 'classnames';

import BaseMask from 'components/shared/FormAdapters/FormBase/Mask';

import { FormFieldWrapper } from '..';
import s from '../index.module.css';
import { IBaseInputProps } from './types';

export const BaseInput: FC<IBaseInputProps> = ({
  value,
  onFocus,
  onChange,
  onBlur,
  label,
  errorMessage,
  isError,
  helpText = '',
  isDisabled = false,
  typeofmask,
  readOnly,
  isAnimationDisabled,
  className,
}) => {
  return (
    <div className={cx(s.wrapper)}>
      <FormFieldWrapper helpText={helpText} errorMessage={errorMessage}>
        <div
          className={cx(s.inputWrapper, className, {
            [s.errorBorder]: errorMessage || isError,
            animationDisabled: isAnimationDisabled,
          })}
        >
          {typeofmask ? (
            // @ts-ignore
            <BaseMask
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              onFocus={onFocus}
              type="text"
              readOnly={readOnly}
              disabled={isDisabled}
              typeOfMask={typeofmask}
              withoutPlaceholder
            />
          ) : (
            <input
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              onFocus={onFocus}
              readOnly={readOnly}
              type="text"
              disabled={isDisabled}
            />
          )}
          {label && <label className={cx({ [s.filled]: value })}>{label}</label>}
        </div>
      </FormFieldWrapper>
    </div>
  );
};
