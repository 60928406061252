export const guestPortalDefaultPagesConfig = {
  pageTypeContextProps: {
    blockAddingAvailable: false,
    noDelete: true,
  },
};

export const guestPortalTripManifestConfig = {
  pageTypeContextProps: {
    blockAddingAvailable: true,
    noDelete: false,
  },
};
