import queryString from 'query-string';

import { isEmpty } from 'ramda';
import { baseApiURL } from 'utils/axios';
import logger from 'utils/logger';

export const ASSET_STORAGE_TYPES = {
  ASSETS: 'assets',
  DOCUMENTS: 'documents',
};

export const ASSET_SIZE_TYPES = {
  FULL: 'full',
  MEDIUM: 'medium',
  PREVIEW: 'preview',
  VERSION_400: 'version_400',
  VERSION_800: 'version_800',
  VERSION_1200: 'version_1200',
  VERSION_1600: 'version_1600',
  VERSION_2400: 'version_2400',
};

export function getAssetSrcFromId(id, mode = ASSET_SIZE_TYPES.FULL) {
  return baseApiURL + '/assets/' + id + '/' + mode;
}

export const getAssetSrc = (versionSrc, versionSrc2x, fileId) => {
  if (!versionSrc && fileId) return getAssetSrcFromId(fileId);
  return `${versionSrc}, ${versionSrc2x} 2x`;
};

export function getArchiveWithImages(ids, pageId) {
  const query = queryString.stringify({ media_ids: ids }, { arrayFormat: 'bracket' });
  return baseApiURL + `/pages/${pageId}/media/archive?${query}`;
}

export const ASSET_TYPES = {
  MEDIA: 'media',
  DOCUMENT: 'document',
  FILE: 'file',
};

export const MIME_TYPES = {
  JPEG: 'image/jpeg',
  PNG: 'image/png',
  PDF: 'application/pdf',
  MP4: 'video/mp4',
  SVG: 'image/svg+xml',
};

export const MIME_TYPE_TO_LABEL_MAP = {
  [MIME_TYPES.JPEG]: 'jpg',
  [MIME_TYPES.PNG]: 'png',
  [MIME_TYPES.PDF]: 'pdf',
  [MIME_TYPES.MP4]: 'mp4',
  [MIME_TYPES.SVG]: 'svg',
};

export const mapMime2AssetType = (mimeType) => {
  switch (mimeType) {
    case MIME_TYPES.JPEG:
    case MIME_TYPES.PNG:
      return ASSET_TYPES.MEDIA;
    case MIME_TYPES.PDF:
      return ASSET_TYPES.DOCUMENT;

    default:
      logger.error(`Unknown mime type: '${mimeType}'`);
  }
};

export const getAttrsFromMIMETypes = (mimeTypes = []) => {
  const attrs = { accept: undefined };

  if (isEmpty(mimeTypes)) {
    return attrs;
  }

  attrs.accept = mimeTypes.join(', ');

  return attrs;
};

export const ASSET_DEFAULT_MAX_SIZE = 10;
export const ASSET_RECOMMENDED_MAX_SIZE = 5;

export const DOCUMENT_MIME_TYPES = [MIME_TYPES.PDF];
export const MEDIA_MIME_TYPES = [MIME_TYPES.JPEG, MIME_TYPES.PNG];
export const FILE_MIME_TYPES = [...DOCUMENT_MIME_TYPES, ...MEDIA_MIME_TYPES];

export const ASSET_TYPE_TO_MIME_TYPES_MAP = {
  [ASSET_TYPES.MEDIA]: MEDIA_MIME_TYPES,
  [ASSET_TYPES.DOCUMENT]: DOCUMENT_MIME_TYPES,
  [ASSET_TYPES.FILE]: FILE_MIME_TYPES,
};

export const stringifyMimeTypes = (mimeTypes = []) =>
  mimeTypes.map((mime) => MIME_TYPE_TO_LABEL_MAP[mime].toUpperCase()).join(', ');

export const DOCUMENTS_CATEGORIES = {
  INSURANCE: 'insurance',
  PASSPORT: 'passport',
  VOUCHER: 'voucher',
  TICKET: 'ticket',
  WAIVER: 'waiver',
  MISC: 'misc',
  GUEST_MISC: 'guest_misc',
};
