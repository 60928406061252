function generateRandomString(length, isNUmber) {
  let result = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const possibleNumbers = '0123456789';
  const choosePossible = () => {
    if (isNUmber) return possibleNumbers;
    return possible;
  };

  const choosedPossible = choosePossible();

  for (let i = 0; i < length; i += 1)
    result += choosedPossible.charAt(Math.floor(Math.random() * choosedPossible.length));
  if (isNUmber) return Number(result);
  return result;
}

export default generateRandomString;
