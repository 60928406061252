import React from 'react';

const ArrowLeft = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_10649_50632)">
        <path d="M20.25 12H3.75" stroke="#3F3C43" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M10.5 5.25L3.75 12L10.5 18.75"
          stroke="#3F3C43"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_10649_50632">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ArrowLeft;
