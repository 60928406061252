import { setLoginAsHeader } from './axios';

const LOGIN_AS = 'LOGIN_AS';
const PARENT_ACC_ID = 'PARENT_ACC_ID';

export function setLoginAsUserId({ userId, accId } = {}) {
  if (userId) {
    localStorage.setItem(LOGIN_AS, userId);
    localStorage.setItem(PARENT_ACC_ID, accId);
  } else {
    localStorage.removeItem(LOGIN_AS);
    localStorage.removeItem(PARENT_ACC_ID);
  }

  setLoginAsHeader({ userId, accId });
}

export function getLoginAsUserId() {
  const hasItem = Boolean(localStorage.getItem(LOGIN_AS));

  return (
    hasItem && {
      userId: localStorage.getItem(LOGIN_AS),
      accId: localStorage.getItem(PARENT_ACC_ID),
    }
  );
}
