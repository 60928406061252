import React, { ChangeEvent, FC } from 'react';

import * as R from 'ramda';

import { Alert } from 'components/shared/Alert';
import { DocumentSettings } from 'components/shared/Assets/shared/Form/Document';
import MediaSettings from 'components/shared/Assets/shared/Form/Media';
import Button from 'components/shared/Button';
import View from 'components/shared/FileDropZone/View';
import FormActions from 'components/shared/FormAdapters/FormDefault/FormActions';
import FormGroup from 'components/shared/FormAdapters/FormDefault/FormGroup/Base';
import Input from 'components/shared/FormAdapters/FormDefault/Input';
import TagsInput from 'components/shared/FormAdapters/FormDefault/TagsInput';
import i18n from 'i18n';
import { capitalizeAllWordsForInput } from 'utils/common';

import FormTitle from '../../../Typography/FormTitle';
import Text from '../../../Typography/Text';
import { oneOf } from '../../utils';
import s from './index.module.css';
import { IFormProps } from './types';

// @ts-ignore
const setValue = R.flip(R.uncurryN(2, R.pipe(R.always, R.when(R.isNil))));

const types: Record<string, any> = {
  media: MediaSettings,
  document: DocumentSettings,
};

const Form: FC<IFormProps> = ({
  asset = {},
  data = {},
  namePlaceholder = 'Elephant Family',
  tagsSubTitle = 'Tags make it easier to find and categorize images.\n',
  tagsPlaceholder = 'Elephant',
  saveButtonText = 'Save',
  errors = {},
  children = null,
  isPointEnabled = false,
  onUpdate = () => {},
  onDelete,
  onSave = () => {},
  infoAlertText,
  fileAccId,
  maxWidth,
  withAssetSettings,
  withTags,
  translate,
}) => {
  const coords = oneOf(data.imageCoords, asset.imageCoords);
  const name = oneOf(data.name, asset.name);
  const tags = oneOf(data.tags, asset.tags);

  return (
    <div>
      <div className={s.view}>
        <View
          url={asset.url!}
          assetStorageType={asset.storageType}
          type={asset.mimeType}
          filename={asset.filename!}
          height={asset.height!}
          width={asset.width!}
          point={coords?.length === 2 ? coords : [50, 50]}
          onSelectPoint={isPointEnabled && ((p: number[]) => onUpdate({ imageCoords: p }))}
          dynamic
          fileAccId={fileAccId}
          maxWidth={maxWidth}
        />
      </div>
      <FormGroup>
        {/*@ts-ignore*/}
        <FormTitle
          size="small"
          hint={translate ? i18n.t('proposal_content.file.please_add_custom_name') : 'Please add a custom name'}
        >
          {translate ? i18n.t('proposal_content.file.document_name') : 'Document Name'}
        </FormTitle>
        <Input
          placeholder={namePlaceholder}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            capitalizeAllWordsForInput(e);
            onUpdate({ name: e.target.value });
          }}
          value={setValue(name, '')}
          isError={Boolean(errors.name)}
        />

        {Boolean(errors.name) && <Text children={errors.name} type="danger" size="small" accent />}

        {infoAlertText && <Alert className={s.infoBlock} variant="info" content={infoAlertText} />}
      </FormGroup>
      {withTags && (
        <FormGroup>
          {/*@ts-ignore*/}
          <FormTitle hint={tagsSubTitle}>Keyword Tags</FormTitle>

          <TagsInput
            placeholder={tagsPlaceholder}
            onChange={(tags: string[]) => onUpdate({ tags })}
            value={setValue(tags, []) as string[]}
            isError={Boolean(errors.tags)}
          />

          {Boolean(errors.tags) && <Text children={errors.tags} type="danger" size="small" accent />}
        </FormGroup>
      )}

      {children}

      {withAssetSettings &&
        types[asset.type!] &&
        React.createElement(types[asset.type!], {
          asset,
          data,
          onUpdate,
        })}

      <FormActions className={s.footer} isRight marginTop>
        {/*@ts-ignore*/}
        {Boolean(onDelete) && (
          <Button type="default" children={translate ? i18n.t('common.buttons.delete') : 'Delete'} onClick={onDelete} />
        )}
        {/*@ts-ignore*/}
        <Button children={saveButtonText} onClick={() => onSave()} />
      </FormActions>
    </div>
  );
};

export default Form;
