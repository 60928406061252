// values should be the same as on the backend, do not change them if not sure
export const ITINERARY_STEPS = {
  DETAILS: 'details',
  GENERAL_INFO: 'general_info',
  COSTS: 'costs',
  LANDING: 'itinerary',
};

export const ITINERARY_STEP_TO_PATH_STEP_MAP = {
  [ITINERARY_STEPS.DETAILS]: 'details',
  [ITINERARY_STEPS.GENERAL_INFO]: 'general-info',
  [ITINERARY_STEPS.COSTS]: 'costs',
  [ITINERARY_STEPS.LANDING]: 'landing',
};

export const ITINERARY_PATH_STEP_TO_STEP_MAP = {
  'general-info': ITINERARY_STEPS.GENERAL_INFO,
  details: ITINERARY_STEPS.DETAILS,
  costs: ITINERARY_STEPS.COSTS,
  landing: ITINERARY_STEPS.LANDING,
};

export const ITINERARY_STEP_TO_LABEL_MAP = {
  [ITINERARY_STEPS.DETAILS]: 'Details',
  [ITINERARY_STEPS.GENERAL_INFO]: 'General Info',
  [ITINERARY_STEPS.COSTS]: 'Costs',
  [ITINERARY_STEPS.LANDING]: 'Itinerary',
};

export const ITINERARY_STEP_LIST = [
  ITINERARY_STEPS.GENERAL_INFO,
  ITINERARY_STEPS.DETAILS,
  ITINERARY_STEPS.COSTS,
  ITINERARY_STEPS.LANDING,
];
