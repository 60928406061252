import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import { accountIdSelector } from 'store/user/selectors';

import { generatorMiddleware } from './middlewares/generator';
import createRootReducer from './store';

export const history = createBrowserHistory();

export function configureAppStore(preloadedState) {
  const middleware = [generatorMiddleware, routerMiddleware(history), ...getDefaultMiddleware()];

  return configureStore({
    reducer: createRootReducer(history),
    middleware,
    preloadedState,
    devTools: process.env.NODE_ENV !== 'production',
    enhancers: [],
  });
}

const store = configureAppStore();

let previousId;
store.subscribe(() => {
  const accountId = accountIdSelector(store.getState());
  if (accountId && previousId === accountId) return;
  previousId = accountId;

  if (accountId && accountId !== localStorage.getItem('accountId')) {
    localStorage.setItem('accountId', accountId);
    return;
  }
  if (!localStorage.getItem('accessToken') && localStorage.getItem('accountId')) {
    localStorage.removeItem('accountId');
  }
});

export default store;
