import { FORM_ERROR } from 'final-form';

import { signIn, acceptInvite, guestSignIn, logoutOnServer } from 'api/auth';
import { OVERRIDDEN_BY_COOKIE, OVERRIDDEN_USER_COOKIE } from 'constants/cookies';
import { LOGIN_PATH } from 'constants/routes';
import { removeToken, setToken } from 'utils/accessToken';
import axiosApi, { setAuthToken } from 'utils/axios';
import { getErrors } from 'utils/common';
import { deleteCookie } from 'utils/cookies';
import logger from 'utils/logger';

import { getMeThunk } from '../user/thunks';

import { loginAsAction, logoutAsAction, unauthorizedAction } from './index';

export const signInThunk =
  ({ email, password }) =>
  async (dispatch) => {
    try {
      const data = await signIn({ email, password });

      setAuthToken(data.accessToken);
      setToken(data);

      dispatch(getMeThunk());
    } catch (err) {
      const isUserBlocked = err?.response?.data?.errorDescription === 'blocked';

      if (isUserBlocked) {
        return dispatch(unauthorizedThunk({ isUnauthorized: true, isUserBlocked, isUserDeleted: false }));
      }
      return {
        [FORM_ERROR]: 'Invalid email or password',
      };
    }
  };

export const guestSignInThunk =
  ({ email, password, guestPortalId, isRulesAccepted = false }) =>
  async (dispatch) => {
    try {
      const data = await guestSignIn({ email, password, guestPortalId, isRulesAccepted });

      setAuthToken(data.accessToken);
      setToken(data);

      dispatch(getMeThunk());
    } catch (err) {
      const isUserBlocked = err?.response?.data?.errorDescription === 'blocked';

      if (isUserBlocked) {
        return dispatch(unauthorizedThunk({ isUnauthorized: true, isUserBlocked, isUserDeleted: false }));
      }
      return {
        [FORM_ERROR]: 'Invalid email or password',
      };
    }
  };

export const acceptInviteThunk = (values) => async () => {
  try {
    return await acceptInvite(values);
  } catch (error) {
    logger.error('Title', getErrors(error));
    throw error;
  }
};

export const logoutThunk =
  ({ getSignInPath } = {}) =>
  async () => {
    try {
      try {
        await logoutOnServer();
      } catch (err) {
        logger.error(err);
      } finally {
        removeToken();
        setAuthToken();

        window.location.replace(getSignInPath ? getSignInPath() : LOGIN_PATH);
      }

      /**
       * old log out flow with no hard reload
       *
       * reason of removing soft redirect: https://app.asana.com/0/0/1200924364809913/f
       */
      // batch(() => {
      //   dispatch(resetUserStateAction());
      //   dispatch(logoutSuccess());
      // });
    } catch (e) {
      throw new Error(e);
    }
  };

export const loginAsThunk = (userId, accId) => async (dispatch) => {
  dispatch(loginAsAction({ userId, accId }));
};

export const logoutAsThunk = () => async (dispatch) => {
  deleteCookie(OVERRIDDEN_BY_COOKIE);
  deleteCookie(OVERRIDDEN_USER_COOKIE);
  dispatch(logoutAsAction());
};

export const unauthorizedThunk = (value) => async (dispatch) => {
  dispatch(unauthorizedAction(value));
};
