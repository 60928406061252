import Appsignal from '@appsignal/javascript';
import { plugin as breadcrumbsConsolePlugin } from '@appsignal/plugin-breadcrumbs-console';
import { plugin as breadcrumbsNetworkPlugin } from '@appsignal/plugin-breadcrumbs-network';

const appsignal = new Appsignal({ key: process.env.REACT_APP_APPSIGNAL_KEY, revision: process.env.REACT_APP_COMMIT });

appsignal.use(breadcrumbsNetworkPlugin());
appsignal.use(breadcrumbsConsolePlugin());

appsignal.addDecorator((span) => {
  return span.setTags({
    link: window.location.href,
    serviceWorker: 'serviceWorker' in navigator,
    cookieEnabled: 'cookieEnabled' in navigator && navigator.cookieEnabled,
  });
});

export default appsignal;
