import { LIST_PLACEHOLDER_IMAGE_TYPE } from 'constants/index';

const { HIGHLIGHTS, FACTS } = LIST_PLACEHOLDER_IMAGE_TYPE;

const mockData = (page) => ({
  title: page.name || 'Property',
  blocks: [
    {
      type: 'header',
      availableInBasic: true,
      data: { subheadline: 'Property Overview' },
    },
    {
      type: 'text',
      availableInBasic: true,
      data: {
        placeholder:
          'Click into this text block to write your own content. Please write in third person. Instead of writing "We look forward to welcoming you," please write "The staff here will give you a warm welcome upon arrival." There is a character limit on this text block to ensure that the text is split up with images and videos which studies show is much more effective than long blocks of text. You can continue your text beneath the media block and in the other sections.',
      },
    },
    {
      type: 'image',
      availableInBasic: true,
      data: {},
    },
    {
      type: 'text',
      availableInBasic: true,
      data: {
        placeholder:
          'Please write in third person. Include high level information like how many rooms the property has, how many guests it can accommodate, if there is a pool, outdoor dining, fitness or spa facilities, etc. This information should be the same even if guests are in different room types. There is a character limit on this text block but you can add another text block if you absolutely must write more. Please try to be concise.',
      },
    },
    {
      type: 'header',
      data: { subheadline: 'Experience & Activities' },
    },
    {
      type: 'text',
      data: {
        placeholder:
          'This section should be used to describe the experience and activities that guests can expect. There is a character limit on this text block to ensure that the text is split up with images and videos which studies show is much more effective than long blocks of text. You can continue your text beneath the media block and in the other sections.',
      },
    },
    {
      type: 'image',
      data: {},
    },
    {
      type: 'text',
      data: {
        placeholder:
          'You do not need to write anything in this section if not needed. If you do not need it please delete it. There is a character limit on this text block but you can add another text block if you absolutely must write more. It is better to be concise and let the images do the talking.',
      },
    },
    ...(page?.rooms?.length > 0 ? [{ type: 'header', data: { subheadline: 'Accommodations', noMenu: true } }] : []),
    {
      type: 'accommodations',
      data: {},
    },
    {
      type: 'header',
      data: { subheadline: 'Fast Facts' },
    },
    {
      type: 'list',
      data: {
        items: [
          {
            imagePlaceholder: HIGHLIGHTS,
            titlePlaceholder: 'Highlights',
            title: 'Highlights',
            descPlaceholder:
              'Amazing location \nExcellent wildlife viewing \nFantastic staff and menus \nPanoramic views \nBathtubs in every room',
            desc: '',
          },
          {
            imagePlaceholder: FACTS,
            titlePlaceholder: 'Quick facts',
            title: 'Quick facts',
            descPlaceholder: 'Only 10 rooms \nMain pool \nAl fresco dining \nOwner-managed \nVery good wi-fi',
            desc: '',
          },
        ],
      },
    },
  ],
});

export default mockData;
