import React, { useState, useEffect } from 'react';

import { render } from 'react-dom';

import AlertModal from 'components/shared/GlobalModalArea/Modal/AlertModal';

import s from './index.module.css';
import { IConfirmAlertProps } from './types';

function ConfirmAlert({
  text,
  title = 'Heads Up!',
  confirmButtonText = 'Okay, got it',
  cancelButtonText = 'Cancel',
}: IConfirmAlertProps) {
  const [isModalOpened, setIsModalOpened] = useState(false);

  const closeModal = () => {
    setIsModalOpened(false);
    removeElementReconfirm();
  };

  const openModal = () => {
    setIsModalOpened(true);
  };

  useEffect(() => {
    openModal();
  }, []);

  return (
    <>
      {isModalOpened ? (
        <AlertModal
          headerText={title}
          bodyText={text}
          confirmButtonText={confirmButtonText}
          closeButtonText={cancelButtonText}
          res={closeModal}
          onAccept={closeModal}
          footerClassName={s.footer}
        />
      ) : null}
    </>
  );
}

function createElementReconfirm(properties: IConfirmAlertProps) {
  let divTarget = document.getElementById('confirm-alert');

  divTarget = document.createElement('div');
  divTarget.id = 'confirm-alert';
  document.body.appendChild(divTarget);
  render(<ConfirmAlert {...properties} />, divTarget);
}

function removeElementReconfirm() {
  const target = document.getElementById('confirm-alert');

  if (target) {
    target.parentNode?.removeChild(target);
  }
}

export function confirmAlert(properties: IConfirmAlertProps) {
  createElementReconfirm(properties);
}
