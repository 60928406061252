import { useCallback } from 'react';

import qs from 'query-string';

import { ITINERARIES_ITEM_STEP_PATH } from 'constants/routes';
import { useAccountPath } from 'hooks/useAccountPath';
import { isNil } from 'ramda';

import { ITINERARIES_ITEM_TYPE_TO_PATH_STEP_MAP } from '../map';

import { mapItemType2PathSegment } from '.';

export const useItinerariesItemStepPath = (itemType) => {
  const getPath = useAccountPath();

  return useCallback(
    (step, itemId, pathParams = {}, queryParams) => {
      const path = getPath(ITINERARIES_ITEM_STEP_PATH, {
        itemType: mapItemType2PathSegment(itemType),
        itemId,
        step: ITINERARIES_ITEM_TYPE_TO_PATH_STEP_MAP[itemType][step],
        ...pathParams,
      });

      const queryString = isNil(queryParams) ? '' : `?${qs.stringify(queryParams)}`;

      return `${path}${queryString}`;
    },

    [getPath, itemType],
  );
};
