import React from 'react';

import { ConnectedRouter } from 'connected-react-router';
import { Route } from 'react-router';
import { QueryParamProvider } from 'use-query-params';

import './polyfills/resizeObserver';

import AssetsModals from 'components/shared/Assets';
import ErrorBoundary from 'components/shared/ErrorBoundary';
import GlobalModalArea from 'components/shared/GlobalModalArea';
import CheckCookieEnabledMiddleware from 'components/shared/Middlewares/CheckCookieEnabledMiddleware';
import HelperRoutesMiddleware from 'components/shared/Middlewares/HelperRoutesMiddleware';
import TokenMiddleware from 'components/shared/Middlewares/TokenMiddleware';

import { history } from './configureStore';
import LazyAppRoutes from './LazyAppRoutes';
import AppQueryClientProvider from './providers/AppQueryClientProvider';

function App() {
  return (
    <ConnectedRouter history={history}>
      <AppQueryClientProvider>
        <ErrorBoundary>
          <QueryParamProvider ReactRouterRoute={Route}>
            <CheckCookieEnabledMiddleware>
              <TokenMiddleware>
                <HelperRoutesMiddleware>
                  <LazyAppRoutes />
                  <AssetsModals />
                  <GlobalModalArea />
                </HelperRoutesMiddleware>
              </TokenMiddleware>
            </CheckCookieEnabledMiddleware>
          </QueryParamProvider>
        </ErrorBoundary>
      </AppQueryClientProvider>
    </ConnectedRouter>
  );
}

export default App;
