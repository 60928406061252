import { CSSProperties, FC, MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
// eslint-disable-next-line import/no-unresolved
import { DragEndEvent } from '@dnd-kit/core/dist/types';
import {
  horizontalListSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import cx from 'classnames';
import { isEmpty, isNil } from 'ramda';
import { createPortal } from 'react-dom';

import MediaItem from 'components/shared/MediaItem';
import { ASSET_SIZE_TYPES } from 'utils/asset';

import Icon from '../../Icon';
import LeftFill from '../../Icon/icons/LeftFill';
import RightFill from '../../Icon/icons/RightFill';
import DescriptionName from '../../MediaItem/DescriptionName';
import s from './index.module.css';
import { IReorderableItemProps, ISliderProps } from './types';

const ReorderableItem: FC<IReorderableItemProps> = ({ dndId, children, disabled }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: dndId, disabled });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transform ? transition : undefined,
    userSelect: 'none',
    pointerEvents: isDragging ? 'none' : undefined,
    zIndex: isDragging ? 9999 : undefined,
    opacity: isDragging ? 0.2 : undefined,
  };

  return (
    <>
      <div ref={setNodeRef} style={style as CSSProperties} {...attributes} {...listeners} className={s.sliderItem}>
        {children}
      </div>
    </>
  );
};

function DragOverlayItem({ children }: any) {
  return (
    <div
      style={{
        touchAction: 'none',
        userSelect: 'none',
        pointerEvents: 'none',
        boxShadow: '0 0 0 1px rgba(63, 63, 68, 0.05), 0px 15px 15px 0 rgba(34, 33, 81, 0.25)',
      }}
      className={s.sliderItem}
    >
      {children}
    </div>
  );
}

const Slider: FC<ISliderProps> = ({
  images,
  onDelete,
  onEdit,
  onSelect,
  onReorder,
  text,
  elementRef,
  reorderEnabled,
}) => {
  const sliderRef = useRef() as MutableRefObject<HTMLInputElement>;
  const sliderWrapRef = useRef() as MutableRefObject<HTMLInputElement>;

  const dndImageIds = useMemo(() => images!.map(({ id }) => String(id)), [images]);
  const [draggingImageId, setDraggingImageId] = useState<string | null>(null);
  const draggingImage = useMemo(() => {
    const idx = dndImageIds.indexOf(draggingImageId!);
    if (idx === -1) {
      return null;
    }
    return images![idx];
  }, [dndImageIds, draggingImageId, images]);
  const [[leftDisabled, rightDisabled], setDisabled] = useState([true, true]);

  const resetScroll = useCallback(() => {
    sliderWrapRef.current.scrollLeft = 0;
  }, []);
  const updateActiveButtons = useCallback(() => {
    setDisabled([
      sliderWrapRef.current.scrollLeft === 0,
      Math.floor(sliderWrapRef.current.scrollWidth - sliderWrapRef.current.scrollLeft) -
        sliderWrapRef.current.clientWidth <=
        1,
    ]);
  }, []);

  useEffect(() => {
    updateActiveButtons();
  }, [images, updateActiveButtons]);

  useEffect(() => {
    if (elementRef) {
      elementRef.current = {
        resetScroll,
      };
    }

    return () => {
      elementRef!.current = null;
    };
  }, []);

  useEffect(() => {
    const containerElem = sliderWrapRef.current;
    if (!containerElem) {
      return;
    }

    const listener = () => {
      updateActiveButtons();
    };
    containerElem.addEventListener('scroll', listener);

    updateActiveButtons();

    return () => {
      containerElem.removeEventListener('scroll', listener);
    };
  }, [sliderWrapRef.current]);

  const scroll = useCallback((vector = 1) => {
    const style = getComputedStyle(sliderRef.current.children[0]);
    const width = parseInt(style.width) + parseInt(style.marginLeft) + parseInt(style.marginRight);
    sliderWrapRef.current.scrollBy({
      left: width * vector * 4,
      behavior: 'smooth',
    });
  }, []);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 0,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  function handleDragEnd(event: DragEndEvent) {
    setDraggingImageId(null);

    const { active, over } = event;
    if (!(active && over)) {
      return;
    }

    if (active.id !== over.id) {
      const oldIndex = dndImageIds.indexOf(active.id as string);
      const newIndex = dndImageIds.indexOf(over.id as string);
      onReorder && onReorder(oldIndex, newIndex);
    }
  }

  return (
    <div className={s.container}>
      <div className={cx(s.left, { [s.disabled]: leftDisabled })} onClick={() => scroll(-1)}>
        <Icon component={LeftFill} height="1.7rem" width="0.8rem" />
      </div>
      <div className={cx(s.right, { [s.disabled]: rightDisabled })} onClick={() => scroll(1)}>
        <Icon component={RightFill} height="1.7rem" width="0.8rem" />
      </div>
      <div className={s.sliderWrap} ref={sliderWrapRef}>
        <div className={s.title}>{text}</div>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={({ active }) => {
            if (!active) {
              return;
            }
            setDraggingImageId(active.id as string);
          }}
          onDragEnd={handleDragEnd}
          onDragCancel={() => setDraggingImageId(null)}
        >
          <SortableContext items={dndImageIds} strategy={horizontalListSortingStrategy}>
            <div className={s.slider} ref={sliderRef}>
              {images!.map((image) => (
                <ReorderableItem key={image.id} dndId={String(image.id)} disabled={!reorderEnabled}>
                  {/*@ts-ignore*/}
                  <MediaItem
                    id={image.id}
                    updatable={onDelete}
                    onClick={onSelect && (() => onSelect(image))}
                    onDelete={onDelete && (() => onDelete(image))}
                    onEdit={onEdit && (() => onEdit(image))}
                    name={image.name}
                    imageSizeType={ASSET_SIZE_TYPES.PREVIEW}
                    imageCoords={image.imageCoords}
                    type={image.type}
                    url={image.url}
                    renderName={({ name }) => <DescriptionName name={name} className={s.descriptionName} />}
                    versionsProcessingStatus={image?.versionsProcessingStatus}
                  />
                </ReorderableItem>
              ))}
            </div>
          </SortableContext>

          {createPortal(
            <DragOverlay adjustScale={false} className={s.dragOverlay}>
              {draggingImageId ? (
                //@ts-ignore
                <DragOverlayItem key={draggingImage.id} id={draggingImage.id}>
                  {/*@ts-ignore*/}
                  <MediaItem
                    id={draggingImage!.id}
                    updatable={onDelete}
                    onDelete={onDelete && (() => onDelete(draggingImage!))}
                    onEdit={onEdit && (() => onEdit(draggingImage!))}
                    imageSizeType={ASSET_SIZE_TYPES.PREVIEW}
                    imageCoords={draggingImage!.imageCoords}
                    type={draggingImage!.type}
                    url={draggingImage!.url}
                    renderName={({ name }) => <DescriptionName name={name} className={s.descriptionName} />}
                    versionsProcessingStatus={draggingImage?.versionsProcessingStatus}
                  />
                </DragOverlayItem>
              ) : null}
            </DragOverlay>,
            document.body,
          )}
        </DndContext>
      </div>
    </div>
  );
};

const SliderContainer: FC<ISliderProps> = ({ images, ...props }) => {
  if (isNil(images) || isEmpty(images)) {
    return null;
  }

  return <Slider {...props} images={images} />;
};

export default SliderContainer;
