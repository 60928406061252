import { FC } from 'react';

import Button from 'components/shared/Button';
import FormActions from 'components/shared/FormAdapters/FormDefault/FormActions';
import FormTitle from 'components/shared/Typography/FormTitle';
import { DOMAINS, getDomain } from 'utils/domain';

import useErrorScreen, { getStatusTitle } from './functions';
import s from './index.module.css';
import { IErrorScreenProps } from './types';

const ErrorScreen: FC<IErrorScreenProps> = ({
  status,
  noButton = false,
  customTitle,
  customHint,
  customFooter,
  buttonText: externalButtonText,
  buttonAction,
  hideStatusCode,
  coverImage,
}) => {
  const { hintBottom, buttonUrlFn, buttonText, title, titleProps, isUnauthorized, backgroundImage } = useErrorScreen({
    status,
    coverImage,
  });

  return (
    <div style={{ backgroundImage }} className={s.container} data-testid="errorScreenContainer">
      <div className={s.mainContent}>
        <div>
          {!isUnauthorized && status !== 'crashed' && !hideStatusCode && (
            <div className={s.status}>{getStatusTitle(status)}</div>
          )}
          <FormTitle
            // @ts-ignore
            hintBottom={customHint || hintBottom}
            center
            {...titleProps}
          >
            {customTitle || title}
          </FormTitle>

          {(buttonAction || getDomain() === DOMAINS.ACCOUNT) && !noButton && (
            <FormActions isCenter>
              <Button type="primary" htmlType="button" onClick={buttonAction ?? buttonUrlFn}>
                {externalButtonText ?? buttonText}
              </Button>
            </FormActions>
          )}
        </div>
        {customFooter && <div className={s.footer}>{customFooter}</div>}
      </div>
    </div>
  );
};

export default ErrorScreen;
