import { FC } from 'react';

import { useController } from 'react-hook-form';

import { BasePasswordInput } from '../../BaseFormComponents/PasswordInput';
import { IHookPasswordInputProps } from './types';

export const HookPasswordInput: FC<IHookPasswordInputProps> = ({
  name,
  control,
  label,
  defaultValue = '',
  isDisabled = false,
  withRequirements = false,
  withError = false,
}) => {
  const {
    field: { value, onBlur, onChange },
    fieldState: { error, isTouched },
  } = useController({ name, control, defaultValue });
  const errorMessage = (() => {
    if (withError && isTouched && error?.message) {
      return error?.message;
    }

    return '';
  })();

  return (
    <BasePasswordInput
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      label={label}
      isDisabled={isDisabled}
      withRequirements={withRequirements}
      errorMessage={errorMessage}
    />
  );
};
