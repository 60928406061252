import { createSlice } from '@reduxjs/toolkit';
import { addDays, differenceInDays, format, isValid, parseISO } from 'date-fns';
import { both, complement, equals, insert, is, move } from 'ramda';

import { recalculateRanges } from 'components/Pages/Admin/Itinerary/Details/utils';
import { getDefaultFormatDate, getLocalDateFromString } from 'utils/date';
import generateRandomString from 'utils/generateRandomString';
import { SCHEDULE_ITEM_TYPES } from 'utils/itineraryDetails/constants';
import { isDefined } from 'utils/ramda';

export const isInternalScheduleItem = (item) => typeof item.id === 'string';
export const isMarkedToDelete = (item) => item._destroy === true;

const switchItemTypeAdapter = (item, typeToSwitch) => {
  const SCHEDULE_ITEM_TYPE_TO_TRANSFORM_FN_MAP = {
    [SCHEDULE_ITEM_TYPES.OVERNIGHT]: (item) => ({
      ...item,
      type: typeToSwitch,
    }),
    [SCHEDULE_ITEM_TYPES.DAY_ROOM]: (item) => ({
      ...item,
      type: typeToSwitch,
      daysRange: { start: item.daysRange.start, end: null },
      datesRange: { start: item.datesRange.start, end: null },
    }),
    [SCHEDULE_ITEM_TYPES.ACTIVITY]: (item) => ({
      ...item,
      type: typeToSwitch,
    }),
  };

  return SCHEDULE_ITEM_TYPE_TO_TRANSFORM_FN_MAP[typeToSwitch](item);
};

export const createScheduleItem = (defaults) => {
  return {
    datesRange: {},
    daysRange: {},
    details: {
      contentState: {},
      removedPageIds: [],
    },
    accommodation: {
      contentState: {},
      removedPageIds: [],
    },
    type: null,
    ...defaults,
    id: generateRandomString(8),
  };
};

export const initialState = {
  schedule: {
    items: [],
    travelStartDate: null,
  },
  initialized: false,
};

const updateArrayById = (arr, id, cb) => {
  return arr.map((item) => (item.id === id ? cb(item) : item));
};

const updateArray = (arr, cb) => {
  return arr.map((item) => cb(item));
};

const slice = createSlice({
  name: 'itineraryDetails',
  initialState,
  reducers: {
    initializeScheduleAction: (state, { payload }) => {
      state.schedule = payload.schedule;
      state.initialized = true;
    },
    resetItineraryDetailsAction: () => initialState,
    updateAllDatesAction: (state, { payload: { dateDifference, travelStartDate } }) => {
      if (isValid(travelStartDate)) state.schedule.travelStartDate = travelStartDate;
      state.schedule.items = updateArray(state.schedule.items, (item) => {
        const updateDate = (point) => {
          return isDefined(item.datesRange.start)
            ? format(addDays(getLocalDateFromString(point), dateDifference), 'yyyy-MM-dd')
            : null;
        };
        return {
          ...item,
          datesRange: {
            start: !isDefined(item.datesRange.start) ? null : updateDate(item.datesRange.start),
            end: item.datesRange.end === null ? null : updateDate(item.datesRange.end),
          },
        };
      });
    },
    updateTravelStartDateAction: (state, { payload }) => {
      if (isValid(parseISO(payload))) state.schedule.travelStartDate = payload;
    },
    updateDatesByDaysAction: (state) => {
      const parsedTravelStartDate = parseISO(state.schedule.travelStartDate);
      const startDays = state.schedule.items
        .filter(complement(isMarkedToDelete))
        .map((item) => item?.daysRange?.start)
        .filter(both(is(Number), Number.isFinite));

      const initialDaysShift = Math.min(...startDays, 1);

      const updatedItems = updateArray(state.schedule.items, (item) => {
        const daysShift = (item.daysRange.start ?? initialDaysShift) - initialDaysShift;
        const difference =
          item.daysRange.end && item.daysRange.start ? item.daysRange.end - item.daysRange.start : null;
        const safeTravelStartDate = isValid(parsedTravelStartDate) ? parsedTravelStartDate : new Date();

        const start = isDefined(item.daysRange.start)
          ? getDefaultFormatDate(addDays(safeTravelStartDate, daysShift))
          : null;
        const end =
          isDefined(item.daysRange.end) && isDefined(difference)
            ? getDefaultFormatDate(addDays(parseISO(start), difference))
            : null;

        return { ...item, datesRange: { start, end } };
      });

      if (equals(updatedItems, state.schedule.items)) return;

      state.schedule.items = updatedItems;
    },
    updateDaysByDatesAction: (state) => {
      const parsedTravelStartDate = parseISO(state.schedule.travelStartDate);

      const updatedItems = updateArray(state.schedule.items, (item) => {
        const parsedItemStartDate = parseISO(item.datesRange?.start);
        const parsedItemEndDate = parseISO(item.datesRange?.end);

        const start = isValid(parsedItemStartDate)
          ? differenceInDays(parsedItemStartDate, parsedTravelStartDate) + 1
          : null;
        const end = isValid(parsedItemEndDate)
          ? start + differenceInDays(parsedItemEndDate, parsedItemStartDate)
          : null;

        return { ...item, daysRange: { start, end } };
      });

      if (equals(updatedItems, state.schedule.items)) return;

      state.schedule.items = updatedItems;
    },
    setDatesRangeAction: (state, { payload }) => {
      state.schedule.items = updateArrayById(state.schedule.items, payload.id, (item) => ({
        ...item,
        datesRange: payload.range,
      }));
    },
    setDaysRangeAction: (state, { payload }) => {
      state.schedule.items = updateArrayById(state.schedule.items, payload.id, (item) => ({
        ...item,
        daysRange: payload.range,
      }));
    },
    setScheduleTypeAction: (state, { payload }) => {
      state.schedule.type = payload;
    },
    setDetailsContentStateAction: (state, { payload }) => {
      state.schedule.items = updateArrayById(state.schedule.items, payload.id, (item) => ({
        ...item,
        details: {
          ...item.details,
          contentState: payload.rawContentState,
        },
      }));
    },
    setAccommodationContentStateAction: (state, { payload }) => {
      state.schedule.items = updateArrayById(state.schedule.items, payload.id, (item) => ({
        ...item,
        accommodation: {
          ...item.accommodation,
          contentState: payload.rawContentState,
        },
      }));
    },
    setDetailsRemovedPageAction: (state, { payload }) => {
      state.schedule.items = updateArrayById(state.schedule.items, payload.id, (item) => ({
        ...item,
        details: {
          ...item.details,
          removedPageIds: [...item.details.removedPageIds, payload.pageId],
        },
      }));
    },
    setAccommodationRemovedPageAction: (state, { payload }) => {
      state.schedule.items = updateArrayById(state.schedule.items, payload.id, (item) => ({
        ...item,
        accommodation: {
          ...item.accommodation,
          removedPageIds: [...item.accommodation.removedPageIds, payload.pageId],
        },
      }));
    },
    createScheduleItemAction: (state) => {
      state.schedule.items = [...state.schedule.items, createScheduleItem()];
    },
    copyScheduleItemAction: (state, { payload }) => {
      const itemIdxToCopy = state.schedule.items.findIndex((item) => item.id === payload.id);
      const itemToCopy = state.schedule.items[itemIdxToCopy];
      const newItem = createScheduleItem(itemToCopy);
      state.schedule.items = insert(itemIdxToCopy + 1, newItem, state.schedule.items);
    },
    moveScheduleItemAction: (state, { payload }) => {
      const sourceIndex = state.schedule.items.findIndex((item) => item.id === payload.sourceItem.id);
      const targetIndex = state.schedule.items.findIndex((item) => item.id === payload.targetItem.id);

      state.schedule.items = move(sourceIndex, targetIndex, state.schedule.items);
      state.schedule.items[targetIndex] = {
        ...state.schedule.items[targetIndex],
        daysRange: {},
        datesRange: {},
      };
    },

    /**
     * Merges the given schedules instead of the item `payload.scheduleItemId`
     *
     * @param {object} payload - object with the following properties:
     * - `scheduleItemId`: id of the item to merge the schedules with
     * - `schedules`: array of schedule items to be merged
     * - `referenceDate`: date in the format 'yyyy-MM-dd' which is used to calculate the start date of the merged schedule items
     * - `referenceDay`: number which is used to calculate the start date of the merged schedule items
     * - `targetOrganizedBy`: the organized by value of the target item
     * - `sourceOrganizedBy`: the organized by value of the source item
     */
    mergeScheduleItems: (state, { payload }) => {
      const itemIndex = state.schedule.items.findIndex((item) => item.id === payload.scheduleItemId);
      const schedulesFromTemplate = payload.schedules.map(createScheduleItem);
      const recalculateSchedulesFromTemplate = recalculateRanges.recalculateRangesForOriginal({
        itemsArray: schedulesFromTemplate,
        targetOrganizedBy: payload.targetOrganizedBy,
        sourceOrganizedBy: payload.sourceOrganizedBy,
        referenceDate: payload.referenceDate,
        referenceDay: payload.referenceDay,
      });

      const newSchedules = state.schedule.items.toSpliced(itemIndex, 1, ...recalculateSchedulesFromTemplate);
      state.schedule.items = newSchedules;
    },
    deleteScheduleItemAction: (state, { payload }) => {
      const itemToDelete = state.schedule.items.find((item) => item.id === payload.id);
      if (isInternalScheduleItem(itemToDelete)) {
        state.schedule.items = state.schedule.items.filter((item) => item.id !== payload.id);
      } else {
        state.schedule.items = updateArrayById(state.schedule.items, payload.id, (item) => ({
          ...item,
          _destroy: true,
        }));
      }
    },
    switchScheduleItemTypeAction: (state, { payload }) => {
      state.schedule.items = updateArrayById(state.schedule.items, payload.id, (item) =>
        switchItemTypeAdapter(item, payload.type),
      );
    },
    setOrganizedByAction: (state, { payload }) => {
      state.schedule.type = payload;
    },
  },
});

export const {
  resetItineraryDetailsAction,
  setDatesRangeAction,
  setScheduleTypeAction,
  setDaysRangeAction,
  setDetailsContentStateAction,
  setAccommodationContentStateAction,
  initializeScheduleAction,
  copyScheduleItemAction,
  createScheduleItemAction,
  deleteScheduleItemAction,
  mergeScheduleItems,
  switchScheduleItemTypeAction,
  moveScheduleItemAction,
  setDetailsRemovedPageAction,
  setAccommodationRemovedPageAction,
  updateAllDatesAction,
  updateDatesByDaysAction,
  updateDaysByDatesAction,
  updateTravelStartDateAction,
} = slice.actions;

export default slice.reducer;

export const itineraryReducer = slice.reducer;
