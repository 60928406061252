import React, { FC } from 'react';

import classes from 'classnames';
import { prop } from 'ramda';

import styles from './index.module.css';
import { IFormActionsProps } from './types';

const FormActions: FC<IFormActionsProps> = ({
  children,
  isRight,
  isSpaceBetween,
  isCenter,
  marginBottom,
  marginTop,
  className,
}) => {
  const childrenLength = children?.length;
  const wrapClasses = classes(
    styles.formAction,
    className,
    isRight && prop('formActionRight', styles),
    isSpaceBetween && prop('formActionBetween', styles),
    isCenter && prop('formActionCenter', styles),
    marginBottom && prop('marginBottom', styles),
    marginTop && prop('marginTop', styles),
    'custom-form-actions-wrapper',
  );

  if (childrenLength > 1) {
    return (
      <div className={wrapClasses}>
        {React.Children.map(children, (child, i) => (
          <div key={i} className={classes(styles.formActionItem, 'custom-form-actions-item')}>
            {child}
          </div>
        ))}
      </div>
    );
  }

  return <div className={wrapClasses}>{children}</div>;
};

export default FormActions;
