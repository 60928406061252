import Axios from 'axios';

import { SHARE_TYPE_TO_ENDPOINT_MAP } from 'components/Pages/Admin/Itineraries/Share/constants';
import { ITINERARIES_ITEM_TYPES } from 'constants/index';
import api from 'utils/axios';
import { axiosGetAutoCancelDecorator } from 'utils/axiosGetAutoCancelDecorator';
import { decamelizeKeys } from 'utils/humps';
import { ITINERARIES_ITEM_TYPE_TO_ROUTE_SEGMENT_MAP } from 'utils/itineraries/functions';
import { trackEvent, EMixpanelEvents } from 'utils/mixpanel';
import { getIn } from 'utils/ramda';

import { accountIdSelector } from '../../user/selectors';
import { tourRequestProposalSelector } from './selectors';

import {
  setTourRequestsAction,
  updateTourRequestAction,
  updateFileNameAction,
  deleteTourRequestAction,
  updateTourRequestRecentEventAction,
  setSearchResultCountersAction,
} from './index';

const decoratedAxiosGet = axiosGetAutoCancelDecorator();
export const getTourRequestsTableThunk = (queryParams) => async (dispatch, getStore) => {
  const accountId = accountIdSelector(getStore());

  try {
    const response = await decoratedAxiosGet(`/acc/${accountId}/tour_requests`, {
      params: decamelizeKeys(queryParams),
    });
    dispatch(setTourRequestsAction({ data: response.data?.data, meta: response.data?.meta }));
  } catch (err) {
    if (!(err instanceof Axios.Cancel)) {
      throw err;
    }
  }
};

export const updateFileNameThunk =
  (id, { fileName }) =>
  async (dispatch, getStore) => {
    const accountId = accountIdSelector(getStore());
    const data = await api.patch(`/acc/${accountId}/tour_requests/${id}`, { fileName }).then(getIn('data'));

    dispatch(updateFileNameAction(data));
    return data;
  };

export const updateTourRequestsTableThunk = (id, params) => async (dispatch, getStore) => {
  const accountId = accountIdSelector(getStore());
  const data = await api.patch(`/acc/${accountId}/tour_requests/${id}`, params).then(({ data }) => data);

  dispatch(updateTourRequestAction(data));
};

export const moveToTourRequestThunk =
  ({ id, itineraryId, reason, status, queryParams, overnights, guestsCount }) =>
  async (dispatch, getStore) => {
    const accountId = accountIdSelector(getStore());
    return await api
      .put(`/acc/${accountId}/tour_requests/${id}/${status}`, { itineraryId, reason, guestsCount, overnights })
      .then((res) => {
        dispatch(getTourRequestsTableThunk(queryParams));
        return res.data;
      })
      .catch((error) => {
        return Promise.reject(error?.errors);
      });
  };

export const assignConsultantThunk =
  ({ id, consultantId, queryParams }) =>
  async (dispatch, getStore) => {
    const accountId = accountIdSelector(getStore());
    return await api.patch(`/acc/${accountId}/tour_requests/${id}/consultant`, { consultantId }).then(() => {
      return dispatch(getTourRequestsTableThunk(queryParams));
    });
  };

export const deleteTourRequestThunk = (id) => async (dispatch, getStore) => {
  const accountId = accountIdSelector(getStore());
  return await api
    .delete(`/acc/${accountId}/tour_requests/${id}`)
    .then(() => {
      dispatch(deleteTourRequestAction(id));
    })
    .catch((error) => {
      return Promise.reject(error?.errors);
    });
};

export const deleteItineraryThunk =
  ({ tourRequestId, id }) =>
  async (dispatch, getStore) => {
    const accountId = accountIdSelector(getStore());
    const itinerariesItem = tourRequestProposalSelector(getStore(), tourRequestId, id);

    trackEvent(EMixpanelEvents.DELETE_ITINERARY, { id });
    return await api
      .delete(`/acc/${accountId}/${ITINERARIES_ITEM_TYPE_TO_ROUTE_SEGMENT_MAP[itinerariesItem.type]}/${id}`)
      .catch((error) => {
        return Promise.reject(error?.errors);
      });
  };

export const shareWithUserTypeThunk = (values, shareType) => (dispatch, getStore) => {
  const accountId = accountIdSelector(getStore());
  const { id, itinerariesItemType } = values;
  const segment = ITINERARIES_ITEM_TYPE_TO_ROUTE_SEGMENT_MAP[itinerariesItemType];

  return api
    .post(`/acc/${accountId}/${segment}/${id}/share_with_${SHARE_TYPE_TO_ENDPOINT_MAP[shareType]}`, { ...values })
    .then(({ data = {} }) => {
      if (itinerariesItemType !== ITINERARIES_ITEM_TYPES.GUEST_PORTAL) {
        dispatch(updateTourRequestRecentEventAction({ id, recentEvent: data, itinerariesItemType }));
        return;
      }
    })
    .catch((error) => {
      return Promise.reject(error?.errors);
    });
};

export const getSamplesItinerariesThunk = (queryParams) => async (dispatch, getStore) => {
  const accountId = accountIdSelector(getStore());
  const data = await api
    .get(`/acc/${accountId}/sample_itineraries`, { params: decamelizeKeys(queryParams) })
    .then(({ data = {} }) => ({ data: data?.data, meta: data?.meta }));

  dispatch(setTourRequestsAction(data));
};

export const saveSampleItineraryThunk = (id, data) => async (dispatch, getStore) => {
  const accountId = accountIdSelector(getStore());
  return await api
    .post(`/acc/${accountId}/sample_itineraries/${id}`, data)
    .then(({ data = {} }) => data)
    .catch((error) => {
      return Promise.reject(error?.errors);
    });
};

export const deleteSampleItineraryThunk = (id) => async (dispatch, getStore) => {
  const accountId = accountIdSelector(getStore());
  return await api
    .delete(`/acc/${accountId}/sample_itineraries/${id}`)
    .then(({ data = {} }) => {
      dispatch(deleteTourRequestAction({ id }));
      return data;
    })
    .catch((error) => {
      return Promise.reject(error?.errors);
    });
};

export const getSamplesLookboksThunk = (queryParams) => async (dispatch, getStore) => {
  const accountId = accountIdSelector(getStore());
  const data = await api
    .get(`/acc/${accountId}/sample_lookbooks`, { params: decamelizeKeys(queryParams) })
    .then(({ data = {} }) => ({ data: data?.data, meta: data?.meta }));

  dispatch(setTourRequestsAction(data));
};

export const saveSampleLookbookThunk = (id, data) => async (dispatch, getStore) => {
  const accountId = accountIdSelector(getStore());
  return await api
    .post(`/acc/${accountId}/sample_lookbooks/${id}`, data)
    .then(({ data = {} }) => data)
    .catch((error) => {
      return Promise.reject(error?.errors);
    });
};

export const deleteSampleLookbookThunk = (id) => async (dispatch, getStore) => {
  const accountId = accountIdSelector(getStore());
  return await api
    .delete(`/acc/${accountId}/sample_lookbooks/${id}`)
    .then(({ data = {} }) => {
      dispatch(deleteTourRequestAction({ id }));
      return data;
    })
    .catch((error) => {
      return Promise.reject(error?.errors);
    });
};

const decoratedAxiosGetCounter = axiosGetAutoCancelDecorator();
export const getItineraryDashboardSearchCountersThunk =
  (q, params = {}) =>
  async (dispatch, getStore) => {
    const accountId = accountIdSelector(getStore());

    try {
      const response = await decoratedAxiosGetCounter(`/acc/${accountId}/tour_requests/search_counters`, {
        params: decamelizeKeys({ q, ...params }),
      });

      dispatch(setSearchResultCountersAction(response.data));
    } catch (err) {
      if (!(err instanceof Axios.Cancel)) {
        throw err;
      }
    }
  };
