import { createSlice } from '@reduxjs/toolkit';

import { getQueryParam } from 'utils/common';

const initialState = {
  data: [],
  nextPage: 1,
  isGlobalLoader: true,
  search: '',
  ownership: getQueryParam('ownership') || 'all',
};

const slice = createSlice({
  name: 'admin/files',
  initialState,
  reducers: {
    updateSearch(state, action) {
      state.search = String(action.payload);
      state.nextPage = initialState.nextPage;
      state.data = initialState.data;
      state.isGlobalLoader = true;
    },
    clearAssetsList(state, action) {
      state.data = initialState.data;
      state.nextPage = initialState.nextPage;

      if (action.payload) {
        state.search = '';
      }
    },
    setGlobalLoader(state, action) {
      state.isGlobalLoader = action.payload;
    },
    loadNewChunk(state, action) {
      state.data.push(...action.payload.data);
      state.nextPage = action.payload.meta.nextPage;
    },
    setOwnershipAction(state, action) {
      state.ownership = action.payload;
    },
    updateAssetAction(state, action) {
      const asset = action.payload;
      const index = state.data.findIndex((el) => el.id === asset.id);
      if (index > -1) {
        state.data[index] = asset;
      }
    },
  },
});

export const { clearAssetsList, setGlobalLoader, loadNewChunk, updateSearch, setOwnershipAction, updateAssetAction } =
  slice.actions;

export default slice.reducer;
