export const SCHEDULE_TYPES = {
  DATES: 'dates',
  DAYS: 'days',
};

export const SCHEDULE_ITEM_TYPES = {
  ACTIVITY: 'activity',
  OVERNIGHT: 'overnight',
  DAY_ROOM: 'day_room',
  INSERT_ITINERARY: 'insert_itinerary',
} as const;

export const SCHEDULE_ITEM_TYPE_TO_LABEL_MAP = {
  [SCHEDULE_ITEM_TYPES.OVERNIGHT]: 'Overnight Stay',
  [SCHEDULE_ITEM_TYPES.DAY_ROOM]: 'Day Room',
  [SCHEDULE_ITEM_TYPES.ACTIVITY]: 'Activity',
  [SCHEDULE_ITEM_TYPES.INSERT_ITINERARY]: 'Insert Itinerary',
};

export const PAGE_MENTION_ENTITY_TYPE = 'PAGE_MENTION';
