import { EOrganizedBy, TTripEvent } from 'detailsv2/structure';
import { IDetailsScheduleItems } from 'utils/itineraryDetails/types';

export type ItemWithRanges = {
  datesRange: {
    start: string | null;
    end: string | null;
  };
  daysRange: {
    start: number | null;
    end: number | null;
  };
};

export enum MergeTypes {
  DatesToDates = 'datesToDates',
  DatesToDays = 'datesToDays',
  DaysToDates = 'daysToDates',
  DaysToDays = 'daysToDays',
}

export type Schedule = IDetailsScheduleItems & {
  datesRange: {
    start: string | null;
    end: string | null;
  };
  daysRange: {
    start: number | null;
    end: number | null;
  };
};
export type RecalculateRangesForOriginalAgruments = {
  itemsArray: Schedule[];
  targetOrganizedBy: EOrganizedBy;
  sourceOrganizedBy: EOrganizedBy;
  referenceDate?: string;
  referenceDay?: number;
};

export type TripEvent = TTripEvent & {
  datesRange: {
    start: string;
    end: string;
  };
  daysRange: {
    start: number;
    end: number;
  };
};
export type RecalculateRangesForChronologicalAgruments = {
  itemsArray: TripEvent[];
  targetOrganizedBy: EOrganizedBy;
  sourceOrganizedBy: EOrganizedBy;
  referenceDate: string | null;
  referenceDay: number | null;
};
