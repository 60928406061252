import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: [],
};

const slice = createSlice({
  name: 'members',
  initialState,
  reducers: {
    setMembersAction(state, action) {
      state.list = action.payload;
    },
    addMemberAction(state, action) {
      state.list.push(action.payload);
    },
    deleteMemberAction(state, action) {
      const id = action.payload;
      const { list } = state;

      const index = list.findIndex((u) => u.id === id);
      if (index > -1) {
        list.splice(index, 1);
      }
    },
    replaceMemberAction(state, action) {
      const { id } = action.payload;
      const { list } = state;

      const index = list.findIndex((u) => u.id === id);
      if (index > -1) {
        list[index] = action.payload;
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    resetMembersAction: (state, _action) => (state = initialState),
  },
});

export const { setMembersAction, addMemberAction, deleteMemberAction, replaceMemberAction, resetMembersAction } =
  slice.actions;

export default slice.reducer;
