import { FC, ReactNode } from 'react';

import cx from 'classnames';
import { isNil } from 'ramda';

import Icon from '../Icon';
import InfoCircle2 from '../Icon/icons/InfoCircle2';
import WarningCircle from '../Icon/icons/WarningCircle';
import s from './index.module.css';
import { IAlertProps } from './types';

/**
 * Variants:
 * * "info" - blue background, dark text
 * * "warning" - pink background, blue text
 * * "danger" - grey background, red text
 */
export const Alert: FC<IAlertProps> = ({ variant, content, className }) => {
  if (isNil(content)) return null;

  const lines = Array.isArray(content) ? content : [content];

  return (
    <div className={cx(s.wrapper, s[`alert_${variant}`], className)}>
      <Icon component={getAlertIcon(variant)} width="24px" height="24px" />
      <div className={s.alertContainer}>
        {lines.map((el: ReactNode, id: number) => (
          <div key={id} className={s.content}>
            {el}
          </div>
        ))}
      </div>
    </div>
  );
};

const getAlertIcon = (variant: string) => {
  if (variant === 'info') {
    return InfoCircle2;
  }
  if (variant === 'danger') {
    return WarningCircle;
  }
  if (variant === 'warning') {
    return WarningCircle;
  }
  return null;
};
