import { useCallback, useEffect } from 'react';

export function useEscAction(cb) {
  const escFunction = useCallback(
    (event) => {
      if (event?.keyCode === 27) cb();
    },
    [cb],
  );

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => document.removeEventListener('keydown', escFunction, false);
  }, [escFunction]);
}
