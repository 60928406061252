import React, { useContext } from 'react';

import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import cx from 'classnames';
import { createPortal } from 'react-dom';

import mapIcon from 'components/shared/images/map_icon.png';
import LandingPageContext from 'components/shared/LandingPage/contexts/LandingPageContext';
import { GOOGLE_MAP_CONTAINER_CLASSNAME } from 'constants/index';
import { useSetTabIndex } from 'hooks/useSetTabIndex';
import { LANDING_THEMES } from 'utils/itineraries/functions';

import mapStyles from '../mapStyles';
import { useMapPoints } from './functions';
import s from './index.module.css';

const apiKey = process.env.REACT_APP_GOOGLE_MAP;

function MapPoints(props) {
  const { landingTheme: contextLandingTheme, page } = useContext(LandingPageContext);
  const mapSettings = props.mapSettings || page.mapSettings;
  const landingTheme = props.landingTheme ?? contextLandingTheme;

  const mapRef = React.useRef(null);
  useSetTabIndex(mapRef, ['*'], -1, 1_500);

  const { points, containerStyle, onReady = () => {} } = props;
  const {
    getOverviewMapListItems,
    newPoints,
    onLoad,
    activeMarker,
    setActiveMarker,
    handleMouseOverMarker,
    zoomLevel,
  } = useMapPoints({ onReady, points });

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: apiKey,
  });

  const { width = '100%', height = '23rem' } = containerStyle;

  // const options = {
  //   imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m', // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
  // };

  const tooltip = () => {
    const texts = getOverviewMapListItems(activeMarker?.indexes);
    const tooltipTextClasses = cx({
      [s.tooltipText]: true,
      [s.tooltipTextWithCount]: texts?.length > 1,
    });

    const mapWrapper = document.querySelector(GOOGLE_MAP_CONTAINER_CLASSNAME);
    const gmBlock = mapWrapper.querySelector('.gm-style');

    return createPortal(
      <div
        type="tooltip"
        className={s.tooltip}
        style={{
          top: activeMarker?.pageY + 30,
          left: activeMarker?.pageX,
          position: 'fixed',
        }}
      >
        {texts?.map((text, i) => (
          <div key={i} className={tooltipTextClasses} dangerouslySetInnerHTML={{ __html: text }} />
        ))}
      </div>,
      gmBlock,
    );
  };

  const createMapOptions = {
    styles: landingTheme === LANDING_THEMES.jbd ? undefined : (mapSettings?.mapTheme ?? mapStyles),
    mapTypeControl: true,
    mapTypeId: landingTheme === LANDING_THEMES.jbd ? 'terrain' : 'roadmap',
    mapTypeControlOptions: {
      mapTypeIds: landingTheme === LANDING_THEMES.jbd ? [] : ['roadmap', 'hybrid'],
    },
    streetViewControl: false,
    scrollwheel: false,
  };
  const getIconProps = () => ({
    url: mapSettings?.pinUrl ?? mapIcon,
    scaledSize: new window.google.maps.Size(21, 31),
  });

  return (
    <div style={{ width, height, position: 'relative', zIndex: 2 }} className={s.mapPoints} ref={mapRef}>
      {activeMarker?.pageX && tooltip()}
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          options={createMapOptions}
          onLoad={onLoad}
          // please do not rename container class name, it is used on BE side
          mapContainerClassName="mapContainerClassName"
          onClick={() => setActiveMarker(null)}
          zoom={zoomLevel}
        >
          {newPoints.map((location, i) => (
            <Marker
              tabIndex={-1}
              key={i}
              position={location}
              icon={getIconProps()}
              label={{ text: location?.title, className: [s.markerLabel, `markerLabel${i + 1}`].join(' ') }}
              onMouseOver={() => handleMouseOverMarker(location, i)}
              onMouseOut={() => setActiveMarker(null)}
              onClick={() => handleMouseOverMarker(location, i)}
            />
          ))}
        </GoogleMap>
      )}
    </div>
  );
}

export default React.memo(MapPoints);
