export const immutableRowUpdate = (rows, row) => {
  return [...rows].map((_row) => {
    if (_row.id === row.id) {
      return row;
    }
    return _row;
  });
};

export const immutableRowRemove = (rows, row) => rows.filter((item) => item.id !== (row.id || row));

export const updateArrayById = (arr, id, cb) => {
  return arr.map((item) => (item.id === id ? cb(item) : item));
};

export const updateArrayBy = (arr, match, cb) => {
  return arr.map((item) => (match(item) ? cb(item) : item));
};
