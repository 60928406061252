import React from 'react';

const LoaderIcon = (props) => (
  <svg width="49" height="52" viewBox="0 0 49 52" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.5714 22.8126L42.1537 9.04176C42.4533 8.73887 42.4533 8.24725 42.1537 7.94357C41.855 7.6399 41.3701 7.6399 41.0705 7.94357L27.4797 21.7222C27.8965 22.0266 28.265 22.3948 28.5714 22.8126Z"
      fill="#231F20"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.4483 28.8516L6.51459 42.9627C6.21584 43.2653 6.21584 43.7551 6.51459 44.0569C6.81258 44.3595 7.29624 44.3595 7.59499 44.0569L21.5203 29.9535C21.1092 29.6432 20.7478 29.2725 20.4483 28.8516Z"
      fill="#231F20"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.2122 20.7102L25.2068 1.2409C25.2072 0.812101 24.8641 0.464472 24.4404 0.464362C24.0172 0.463702 23.6744 0.811152 23.6745 1.2405L23.6794 20.7214C24.1865 20.638 24.704 20.6343 25.2122 20.7102Z"
      fill="#231F20"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.5854 30.8007L23.5799 50.7624C23.5798 51.1904 23.9216 51.5367 24.3435 51.5361C24.7653 51.5365 25.1073 51.19 25.1074 50.762L25.1123 30.8117C24.6051 30.8869 24.0909 30.8837 23.5854 30.8007Z"
      fill="#231F20"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.1892 21.7068L7.59907 7.94385C7.30015 7.64034 6.81498 7.64034 6.51529 7.94385C6.21561 8.24659 6.21561 8.73796 6.51529 9.04147L20.1131 22.813C20.4135 22.3907 20.7768 22.0173 21.1892 21.7068Z"
      fill="#231F20"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.1487 29.9377L41.0747 44.0566C41.3732 44.3594 41.8566 44.3594 42.1545 44.0566C42.4531 43.7547 42.4531 43.2646 42.1545 42.9619L28.2361 28.8514C27.9299 29.268 27.5641 29.6342 27.1487 29.9377Z"
      fill="#231F20"
    />
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="29" y="24" width="20" height="3">
      <path fillRule="evenodd" clipRule="evenodd" d="M29.4708 24.8257H48.9734V26.3913H29.4708V24.8257Z" fill="white" />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48.2135 24.8257H29.4708C29.5081 25.0817 29.5347 25.3415 29.5347 25.6085C29.5347 25.8754 29.5081 26.1353 29.4708 26.3913H48.2135C48.6332 26.3913 48.9738 26.0406 48.9738 25.6085C48.9738 25.1764 48.6332 24.8257 48.2135 24.8257Z"
      fill="#231F20"
    />
    <g mask="url(#mask0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.2135 24.8257H29.4708C29.5081 25.0817 29.5347 25.3415 29.5347 25.6085C29.5347 25.8754 29.5081 26.1353 29.4708 26.3913H48.2135C48.6332 26.3913 48.9738 26.0406 48.9738 25.6085C48.9738 25.1764 48.6332 24.8257 48.2135 24.8257Z"
        fill="#231F20"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.5338 24.8257H0.763934C0.342242 24.8257 0 25.2014 0 25.6644C0 26.1274 0.342242 26.5031 0.763934 26.5031H19.5338C19.4956 26.2288 19.4696 25.9504 19.4696 25.6644C19.4696 25.3784 19.4956 25.0999 19.5338 24.8257Z"
      fill="#231F20"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.5 22.4775C22.6711 22.4775 21.1892 23.9799 21.1892 25.8324C21.1892 27.6857 22.6711 29.1872 24.5 29.1872C26.329 29.1872 27.8108 27.6857 27.8108 25.8324C27.8108 23.9799 26.329 22.4775 24.5 22.4775Z"
      fill="#231F20"
    />
  </svg>
);

export default LoaderIcon;
