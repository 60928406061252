import { createSlice } from '@reduxjs/toolkit';

export const GUEST_PORTAL_AUTH_FLOW = {
  ORIGINAL: 'original',
  ACCESS: 'access',
};

const initialState = {
  isLoading: false,
  guestPortalAuthFlow: null,
  guestPortalId: undefined,
  showMobileInfo: undefined,
};

// reducer for app current user
const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppIsLoadingAction: (state, action) => {
      state.isLoading = action.payload;
    },
    setGuestPortalAuthFlowAction: (state, action) => {
      state.guestPortalAuthFlow = action.payload;
    },
    setGuestPortalIdAction: (state, action) => {
      state.guestPortalId = action.payload;
    },
    setShowMobileInfo: (state, action) => {
      state.showMobileInfo = action.payload;
    },
  },
});

export const { setAppIsLoadingAction, setGuestPortalAuthFlowAction, setGuestPortalIdAction, setShowMobileInfo } =
  slice.actions;

export default slice.reducer;
