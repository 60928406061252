import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  targetLang: 'EN-US',
  originalBlocks: [],
  originalTexts: '',
  translateFromServer: [],
};

const slice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setLangAction(state, action) {
      state.targetLang = action.payload;
    },
    setOriginalBlocksAction(state, action) {
      state.originalBlocks = action.payload;
    },
    setOriginalTextsAction(state, action) {
      state.originalTexts = action.payload;
    },
  },
});

export const { setLangAction, setOriginalBlocksAction, setOriginalTextsAction } = slice.actions;

export default slice.reducer;
