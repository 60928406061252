// import testImgUrl from 'components/shared/images/basePreview.jpg';

const mockData = () => ({
  title: 'Pre travel information',
  // primaryImage: testImgUrl,
  blocks: [
    {
      type: 'accordion',
      data: {
        spoilers: [
          {
            title: 'Passports & Visas',
            body: {
              placeholder:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            },
          },
          {
            title: 'Health & Vaccines',
            body: {
              placeholder:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            },
          },
          {
            title: 'Electricity, Plugs & WiFi',
            body: {
              placeholder:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            },
          },
          {
            title: 'Cash & Cards',
            body: {
              placeholder:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            },
          },
          {
            title: 'Suggested Packing List',
            body: {
              raw: {
                blocks: [
                  {
                    key: '88t8r',
                    text: 'You can find most of what you need for your safari from The Safari Store',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [],
                    entityRanges: [{ offset: 56, length: 16, key: 0 }],
                    data: {},
                  },
                  {
                    key: '8hkbn',
                    text: '2-3 x shorts ',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: 'e6te0',
                    text: '2-3 x pants ',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: '2lvdi',
                    text: '1 x warm jacket ',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: '9fshs',
                    text: '1x light rain jacket ',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: 'duq3a',
                    text: '2 x cardigans/ sweaters ',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: '9k15f',
                    text: '3 x short sleeve shirts ',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: '9g059',
                    text: '3 x tee shirts ',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: '8t5if',
                    text: '2 x long sleeved shirts ',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: '57s8g',
                    text: '1 x sandals ',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: '6vqvb',
                    text: '1 x comfortable walking shoes or sneakers ',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: 'da5g1',
                    text: '1 x swimming costume ',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: 'feetm',
                    text: '1 x hat ',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: '8rubd',
                    text: '1 x sunglasses ',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: '3l8sr',
                    text: '1 x lip balm/ chapstick ',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: 'ds9m',
                    text: 'socks ',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: '5ohaq',
                    text: 'sunblock ',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: '1k6je',
                    text: 'insect repellent ',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: '596o6',
                    text: 'antihistamine cream ',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: 'ectrd',
                    text: 'flashlight ',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: 'f1cb2',
                    text: 'undergarments ',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: '3mnro',
                    text: 'preferred toiletries ',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: '2cpvl',
                    text: 'contacts or eyeglasses if needed ',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: '66mke',
                    text: 'camera, spare camera batteries, dust proof case, cleaning equipment, and memory cards ',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: 'cbbr7',
                    text: 'universal worldwide travel adaptor',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: 'e7ii8',
                    text: 'gloves ',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: '8do0p',
                    text: 'beanie/warm hat ',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: '4uj18',
                    text: '1 x binoculars (“binos” in the range 8x40 or 10x40 are most useful)',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [],
                    entityRanges: [],
                    data: {},
                  },
                ],
                entityMap: {
                  0: {
                    type: 'LINK',
                    mutability: 'MUTABLE',
                    data: { url: 'https://www.thesafaristore.com/en/?aid=23731355' },
                  },
                },
              },
            },
          },
          {
            title: 'Gratuities',
            body: {
              placeholder:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            },
          },
          {
            title: 'Travel Insurance',
            body: {
              raw: {
                blocks: [
                  {
                    key: '5rcn2',
                    text: 'Travel insurance is often required by properties as a condition of your booking. ',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: '67jfe',
                    text: 'We recommend that you purchase a travel protection plan to help protect you and your travel investment against the unexpected. Travel protection plans can include coverage for Trip Cancellation, Trip Interruption, Emergency Medical and Emergency Evacuation/Repatriation, Trip Delay, Baggage Delay and more. ',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: '2883s',
                    text: 'For your convenience, we offer travel protection through Travelex Insurance Services for US residents. ',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: 'c6fpr',
                    text: 'For more information on the available plans or to enroll, go to www.travelex.com or contact Travelex Insurance Services at 800-228-9792 and reference location number 09-1185. ',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [],
                    entityRanges: [{ offset: 64, length: 16, key: 0 }],
                    data: {},
                  },
                  {
                    key: 'ald0m',
                    text: 'Please Note: To be eligible for the waiver of pre-existing medical condition exclusion, the protection plan must be purchased within 15 days (Travel Basic) or 21 days (Travel Select) from the time you make your initial trip deposit. However, the plan can be purchased any time prior to departure. ',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: 'phtc',
                    text: 'The product textriptions provided here are only brief summaries. The full coverage terms and details, including limitations and exclusions, are contained in the insurance policy. Travelex Insurance Services, Inc CA Agency License #0D10209. All products listed are underwritten by, Berkshire Hathaway Specialty Insurance Company, NAIC #22276. 11.17 83I',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [],
                    entityRanges: [],
                    data: {},
                  },
                ],
                entityMap: {
                  0: {
                    type: 'LINK',
                    mutability: 'MUTABLE',
                    data: { url: 'https://www.travelexinsurance.com/?location=09-1185&go=bp' },
                  },
                },
              },
            },
          },
          {
            title: 'Suggested Reading List',
            body: {
              raw: {
                blocks: [
                  {
                    key: '4t582',
                    text: 'Historical',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [{ offset: 0, length: 10, style: 'BOLD' }],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: '9jgk0',
                    text: 'Safari - A Chronicle Of Adventure - Bartle Bull',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [{ offset: 0, length: 47, style: 'ITALIC' }],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: 'blio5',
                    text: 'Blue Nile & White Nile - Alan Moorehead',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [{ offset: 0, length: 39, style: 'ITALIC' }],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: 'cfgue',
                    text: 'White Man’s Country - Lord Delamere',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [{ offset: 0, length: 35, style: 'ITALIC' }],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: '967qv',
                    text: 'Scramble For Africa - Thomas Pakenham',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [{ offset: 0, length: 37, style: 'ITALIC' }],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: '3eamm',
                    text: 'Bartle Safari – Bartle Bull',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [{ offset: 0, length: 27, style: 'ITALIC' }],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: '7ake5',
                    text: 'Another Day of Life / The Shadow of the Sun: My African Life  - Ryszard Kapuscinski       ',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [{ offset: 0, length: 90, style: 'ITALIC' }],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: '7aid8',
                    text: 'Dark Star Safari - Paul Theroux        ',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [{ offset: 0, length: 39, style: 'ITALIC' }],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: '97urb',
                    text: 'Africa: Biography of a Continent - John Reader',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [{ offset: 0, length: 46, style: 'ITALIC' }],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: 'adckc',
                    text: '',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: 'd5hb9',
                    text: 'Novels & Autobiographies',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [{ offset: 0, length: 24, style: 'BOLD' }],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: '1qvgm',
                    text: 'West with The Night - Beryl Markham',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [{ offset: 0, length: 35, style: 'ITALIC' }],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: 'dk3k5',
                    text: 'Straight On Till Morning (Beryl Markham) - Mary Lovell',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [{ offset: 0, length: 54, style: 'ITALIC' }],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: '6rauq',
                    text: 'White Mischief - James Fox',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [{ offset: 0, length: 26, style: 'ITALIC' }],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: '20oa7',
                    text: 'The Poisonwood Bible - Barbara Kingsolver',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [{ offset: 0, length: 41, style: 'ITALIC' }],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: '3o18s',
                    text: 'Cry the Beloved Country - Alan Paton',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [{ offset: 0, length: 36, style: 'ITALIC' }],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: '1sdje',
                    text: 'Long walk to Freedom - Nelson Mandela',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [{ offset: 0, length: 37, style: 'ITALIC' }],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: '4kooc',
                    text: 'The Power of One-Bryce Courtenay',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [{ offset: 0, length: 32, style: 'ITALIC' }],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: 'cno6',
                    text: 'Tandia - Bryce Courtenay',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [{ offset: 0, length: 24, style: 'ITALIC' }],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: 'cb57n',
                    text: 'Born Free - Joy Adamson',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [{ offset: 0, length: 23, style: 'ITALIC' }],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: '6cag3',
                    text: "Don't Let's Go to the Dogs Tonight - Alexandra Fuller",
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [{ offset: 0, length: 53, style: 'ITALIC' }],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: 'bimsa',
                    text: 'A Good Man in Africa - William Boyd',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [{ offset: 0, length: 35, style: 'ITALIC' }],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: '5ocoh',
                    text: 'Dark Star Safari: Overland from Cairo to Cape Town – Paul Theroux',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [{ offset: 0, length: 65, style: 'ITALIC' }],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: '1ie1n',
                    text: 'Out of Africa - Karen Blixen / Isaac Dinesen ',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [{ offset: 0, length: 45, style: 'ITALIC' }],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: '7k9lk',
                    text: 'A Far Off Place - Laurens van der Post',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [{ offset: 0, length: 38, style: 'ITALIC' }],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: 'fvcuf',
                    text: 'Jock of the Bushveld - Pery Fitzpatrick',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [{ offset: 0, length: 39, style: 'ITALIC' }],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: '7frt1',
                    text: 'The Bang-Bang Club - Marinovich and Silva',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [{ offset: 0, length: 41, style: 'ITALIC' }],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: '9aoq0',
                    text: 'Mukiwa - Peter Godwin',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [{ offset: 0, length: 21, style: 'ITALIC' }],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: '4rlh5',
                    text: 'King Leopolds Ghost - Adam Hochschild',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [{ offset: 0, length: 37, style: 'ITALIC' }],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: '2e7up',
                    text: 'Snows of Kilimanjaro - Ernest Hemmingway',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [{ offset: 0, length: 40, style: 'ITALIC' }],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: '171l4',
                    text: 'Gorillas in the Mist - Dian Fossey',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [{ offset: 0, length: 34, style: 'ITALIC' }],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: '9oiov',
                    text: 'Life Of My Choice - Wilfred Thesiger',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [{ offset: 0, length: 36, style: 'ITALIC' }],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: '88loi',
                    text: 'No 1 Ladies Detective Agency - Alexander Mccall Smith',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [{ offset: 0, length: 53, style: 'ITALIC' }],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: '8ridf',
                    text: 'The Tree Where Man Was Born - Peter Matthiessen ',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [{ offset: 0, length: 48, style: 'ITALIC' }],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: '1t6jo',
                    text: 'Among the Elephants - Iain and Oria Douglas Hamilton ',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [{ offset: 0, length: 53, style: 'ITALIC' }],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: 'e6vqk',
                    text: 'Almost Human - Shirley Strum (Baboons)',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [{ offset: 0, length: 38, style: 'ITALIC' }],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: 'evfhi',
                    text: 'Elephant Memories - Cynthia Moss (Elephants)',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [{ offset: 0, length: 44, style: 'ITALIC' }],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: 'a5gfb',
                    text: '',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: '6ehk1',
                    text: 'Films',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [{ offset: 0, length: 5, style: 'BOLD' }],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: '9dqhn',
                    text: 'Born Free',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [{ offset: 0, length: 9, style: 'ITALIC' }],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: 'f8mgs',
                    text: 'The First Grader',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [{ offset: 0, length: 16, style: 'ITALIC' }],
                    entityRanges: [],
                    data: {},
                  },
                  {
                    key: 'fgqqu',
                    text: 'The Ghost and the Darkness',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [{ offset: 0, length: 26, style: 'ITALIC' }],
                    entityRanges: [],
                    data: {},
                  },
                ],
                entityMap: {},
              },
            },
          },
          {
            title: 'Social Media',
            body: {
              text: 'If you plan to document your trip on social media, please remember to tag us @safariportal @safariportalapp',
            },
          },
        ],
      },
    },
  ],
});

export default mockData;
