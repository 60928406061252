import React, { useCallback, useState } from 'react';

import s from './index.module.css';
import popup from './popup.svg';

const ContainerWithPoint = ({ onSelectPoint, point = [50, 50], hidden, children }) => {
  const [isPopup, setPopup] = useState(true);

  const onClick = useCallback(
    (fn) => {
      setPopup(false);
      fn();
    },
    [setPopup],
  );

  return (
    <div className={s.image} style={{ display: hidden ? 'none' : 'block' }}>
      <div onClick={(e) => onClick(() => onSelectPoint(getPoint(e)))} children={children} />
      <div className={s.point} style={{ left: point[0] + '%', top: point[1] + '%' }} />
      {isPopup && (
        <div className={s.popup} style={{ left: point[0] + '%', top: point[1] + '%' }}>
          <img src={popup} alt="Popup" style={{ width: '100%' }} />
          <div className={s.text}>Click anywhere on the image to set the central focal point</div>
        </div>
      )}
    </div>
  );
};

function getPoint(e) {
  const { left, top, width, height } = e.target.getBoundingClientRect();

  const x = Math.floor(((e.clientX - left) / width) * 10000) / 100;
  const y = Math.floor(((e.clientY - top) / height) * 10000) / 100;

  return [x, y].map((n) => Number(n.toFixed(4)));
}

export default ContainerWithPoint;
