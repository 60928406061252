import { createSlice, current } from '@reduxjs/toolkit';

import { LAST_ACTIVE_PAGE_TYPE } from '../../constants';

const initialState = {
  type: '',
  landing: {},
  initialRoomPositions: [],
};

const slice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    setPageDataAction: (state, action) => {
      localStorage.setItem(LAST_ACTIVE_PAGE_TYPE, action.payload?.type);

      return (state = {
        ...action.payload,
        initialRoomPositions: action.payload?.rooms?.map((room) => room.id),
      });
    },

    resetPageDataAction: () => initialState,
    setLandingData: (state, action) => {
      state.landing = action.payload;
    },
    setRoomPosition: (state, { payload }) => {
      state.rooms = current(state)
        .rooms.map((room) => {
          const position = payload.findIndex((item) => item.id === room.id);
          if (position === -1) return room;

          return { ...room, position };
        })
        .sort((a, b) => a.position - b.position);
    },
  },
});

export const { setPageDataAction, resetPageDataAction, setLandingData, setRoomPosition } = slice.actions;

export default slice.reducer;
