import { useCallback } from 'react';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getPath } from 'constants/routes';
import { userSelector } from 'store/user/selectors';

import { LIBRARY_CONTENT } from '../constants';

export function useAccountPath() {
  const user = useSelector(userSelector);
  const { libType = LIBRARY_CONTENT } = useParams();
  const accId = user?.currentAccount?.id;

  return useCallback(
    (path, enteredParams) => {
      return getPath(path, { accountId: accId, libType, ...enteredParams });
    },
    [accId, libType],
  );
}
