import mixpanel from 'mixpanel-browser';
import queryString from 'query-string';
import { partition } from 'ramda';
import { batch } from 'react-redux';

import { ACCOUNT_TYPE_ADMIN, ACCOUNT_TYPE_GUEST } from 'constants/index';
import { setAppIsLoadingAction } from 'store/app';
import { loginSuccess } from 'store/auth';
import api, { upload } from 'utils/axios';
import { DOMAINS, extractAccountIdFromURL, extractItemIdOnSharedDomain, getDomain } from 'utils/domain';
import logger from 'utils/logger';

import { guestPortalIdSelector } from '../app/selectors';
import { unauthorizedThunk } from '../auth/thunks';
import { accountIdSelector, currentAccountSelector } from './selectors';

import { setLoading, getMeSuccessAction, updateAccountAction, updateProfile, updateBranding } from './index';

const getAccount = (accountList = [], gpId) => {
  const [guestAccounts, restAccounts] = partition((acc) => acc.type === ACCOUNT_TYPE_GUEST, accountList);

  if (getDomain() !== DOMAINS.ACCOUNT) {
    const itemId = gpId ?? extractItemIdOnSharedDomain();

    return guestAccounts.find((acc) => acc.guestPortalId === itemId);
  }

  if (getDomain() === DOMAINS.ACCOUNT) {
    let account;

    const extractedAccountIdFromURL = extractAccountIdFromURL();
    if (extractedAccountIdFromURL) {
      account = restAccounts.find((acc) => acc.id === extractedAccountIdFromURL);
    }

    if (!account) {
      account = restAccounts.find((acc) => acc.id === localStorage.getItem('accountId'));
    }
    if (!account) {
      account = restAccounts.find((acc) => acc.type === ACCOUNT_TYPE_ADMIN);
    }
    if (!account) {
      account = restAccounts[0];
    }

    return account;
  }

  return null;
};

const isUserGuestOnly = (accountList = []) => {
  return accountList.every((acc) => acc.type === ACCOUNT_TYPE_GUEST);
};

export const getMeThunk =
  ({ soft = false } = {}) =>
  async (dispatch, getState) => {
    if (!soft) {
      dispatch(setAppIsLoadingAction(true));
    }

    try {
      const isAccountDomain = getDomain() === DOMAINS.ACCOUNT;
      const query = queryString.stringify({ without_guests: isAccountDomain });
      const user = await api.get('/me?' + query).then(({ data = {} }) => data);

      const account = getAccount(user?.accountList, guestPortalIdSelector(getState()));

      if (!account) {
        dispatch(
          unauthorizedThunk({
            isUnauthorized: true,
            isUserBlocked: false,
            isUserDeleted: true,
            isUserGuestOnly: isUserGuestOnly(user?.accountList),
            unauthorizedUser: user,
          }),
        );
        return;
      }

      const accountTrackingData = {
        id: account.id,
        is_paying: account.paid,
        plan_level: account.plan,
        type: account.type,
        first_name: account.accountOwnerFirstName,
        last_name: account.accountOwnerLastName,
      };

      if (process.env.REACT_APP_MIXPANEL_TOKEN) {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
          persistence: 'localStorage',
        });
        mixpanel.identify(user.id);
        mixpanel.people.set_once({
          ...accountTrackingData,
          created_at: account.createdAt,
          email: user.email,
          id: user.id,
          account_id: account.id,
        });
      }

      batch(() => {
        dispatch(getMeSuccessAction({ user, account }));
        dispatch(loginSuccess());
      });
    } catch (e) {
      logger.error('e :>> ', e);
    } finally {
      if (!soft) {
        dispatch(setAppIsLoadingAction(false));
      }
    }
  };

export const saveAccountSettingsThunk = (values) => async (dispatch, getStore) => {
  const accountId = accountIdSelector(getStore());
  const currentAccount = currentAccountSelector(getStore());

  const data = await api.patch(`/acc/${accountId}/settings`, values).then(({ data }) => data);
  const { settingsFilled } = data;
  const { companyName } = values;

  dispatch(updateAccountAction({ ...currentAccount, settingsFilled, companyName }));
};

export const fetchBrandSettingsThunk = () => async (dispatch, getStore) => {
  const accountId = accountIdSelector(getStore());
  const { data } = await api.get(`/acc/${accountId}/brand_settings`);

  dispatch(updateBranding(data));
};

export const updateBrandSettingsThunk = (values) => async (dispatch, getStore) => {
  const accountId = accountIdSelector(getStore());

  try {
    const { data } = await api.patch(`/acc/${accountId}/brand_settings`, values);

    dispatch(updateBranding(data));
  } catch (err) {
    throw err.errors;
  }
};

export const checkCustomDomain = () => async (dispatch, getStore) => {
  const accountId = accountIdSelector(getStore());

  try {
    const { data } = await api.post(`/acc/${accountId}/brand_settings/check_custom_domain`);

    dispatch(updateBranding(data));
  } catch (err) {
    throw err.errors.source;
  }
};

export const updateLogoThunk =
  ({ url, filename }) =>
  async (dispatch, getState) => {
    const accountId = accountIdSelector(getState());

    try {
      dispatch(setLoading(true));

      const blob = await fetch(url).then((r) => r.blob());
      const formData = new FormData();
      formData.append('logo', new File([blob], filename));

      const { data } = await upload(`/acc/${accountId}/logo`, 'PUT', formData);

      batch(() => {
        dispatch(setLoading(false));
        dispatch(updateBranding(data));
      });
    } catch (err) {
      dispatch(setLoading(false));
      throw err.errors.source;
    }
  };

export const deleteLogoThunk = () => async (dispatch, getState) => {
  const accountId = accountIdSelector(getState());

  try {
    dispatch(setLoading(true));

    const { data } = await api.delete(`/acc/${accountId}/logo`);

    batch(() => {
      dispatch(setLoading(false));
      dispatch(updateBranding(data));
    });
  } catch (err) {
    dispatch(setLoading(false));
    return err.errors.source;
  }
};

export const saveProfileSettingsThunk = (values) => async (dispatch, getState) => {
  const accountId = accountIdSelector(getState());
  values = { ...values, accountId };

  await api.patch('/me/settings', values);

  dispatch(updateProfile(values));
};

export const updatePasswordThunk = (values) => async () => {
  await api.patch('/me/password', values);
};
