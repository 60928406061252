// values should be the same as on the backend, do not change them if not sure
export const GUEST_PORTAL_STEPS = {
  GENERAL_INFO: 'general_info',
  GUEST_FORMS: 'guests',
  RELOCATIONS: 'relocations',
  TRAVEL_WALLET: 'travel_wallet',
  LANDING: 'guest_portal',
};

export const GUEST_PORTAL_STEP_TO_PATH_STEP_MAP = {
  [GUEST_PORTAL_STEPS.GENERAL_INFO]: 'general-info',
  [GUEST_PORTAL_STEPS.GUEST_FORMS]: 'guest-forms',
  [GUEST_PORTAL_STEPS.RELOCATIONS]: 'relocations',
  [GUEST_PORTAL_STEPS.TRAVEL_WALLET]: 'travel-wallet',
  [GUEST_PORTAL_STEPS.LANDING]: 'landing',
};

export const GUEST_PORTAL_PATH_STEP_TO_STEP_MAP = {
  'general-info': GUEST_PORTAL_STEPS.GENERAL_INFO,
  'guest-forms': GUEST_PORTAL_STEPS.GUEST_FORMS,
  relocations: GUEST_PORTAL_STEPS.RELOCATIONS,
  'travel-wallet': GUEST_PORTAL_STEPS.TRAVEL_WALLET,
  landing: GUEST_PORTAL_STEPS.LANDING,
};

export const GUEST_PORTAL_STEP_TO_LABEL_MAP = {
  [GUEST_PORTAL_STEPS.GENERAL_INFO]: 'General Info',
  [GUEST_PORTAL_STEPS.GUEST_FORMS]: 'Guest Forms',
  [GUEST_PORTAL_STEPS.RELOCATIONS]: 'Flights',
  [GUEST_PORTAL_STEPS.TRAVEL_WALLET]: 'Travel Wallet',
  [GUEST_PORTAL_STEPS.LANDING]: 'Guest Portal',
};

export const GUEST_PORTAL_STEP_LIST = [
  GUEST_PORTAL_STEPS.GENERAL_INFO,
  GUEST_PORTAL_STEPS.GUEST_FORMS,
  GUEST_PORTAL_STEPS.RELOCATIONS,
  GUEST_PORTAL_STEPS.TRAVEL_WALLET,
  GUEST_PORTAL_STEPS.LANDING,
];

export const GUEST_PORTAL_STEP_LIST_CHRONOLOGICAL = [
  GUEST_PORTAL_STEPS.GENERAL_INFO,
  GUEST_PORTAL_STEPS.GUEST_FORMS,
  GUEST_PORTAL_STEPS.TRAVEL_WALLET,
  GUEST_PORTAL_STEPS.LANDING,
];
