import { Dispatch, SetStateAction } from 'react';

import { Channel } from '@anycable/web';

import { camelizeKeys } from 'utils/humps';
import { EProposalItems } from 'utils/itineraryDetails/types';

import { Actions, Message, Params, Events, EEvents, TabData, User, Config } from './types';

const CHANNEL_IDENTIFIER = 'Proposals::UserActivityChannel';
const KEEP_ALIVE_INTERVAL = 10_000;

export class UserActivityChannel<T extends EProposalItems> extends Channel<Params<T>, Message, Events, Actions<T>> {
  static identifier = CHANNEL_IDENTIFIER;
  private readonly currentUserId: string;
  private readonly setActiveUsers: Dispatch<SetStateAction<User[]>>;
  private readonly setShowChangesDetectedModal: Dispatch<SetStateAction<boolean>>;
  private readonly setUserConnected: Dispatch<SetStateAction<boolean>>;
  private keepAliveIntervalId: ReturnType<typeof setInterval> | undefined;

  constructor(params: Params<T>, config: Config) {
    // @ts-ignore
    super(params);
    this.currentUserId = config.currentUserId;
    this.setActiveUsers = config.setActiveUsers;
    this.setShowChangesDetectedModal = config.setShowChangesDetectedModal;
    this.setUserConnected = config.setUserConnected;
  }

  async sendJoin(data: TabData<T>) {
    await this.ensureSubscribed();
    return this.perform('join', data);
  }

  async sendChangeTab(data: TabData<T>) {
    await this.ensureSubscribed();
    return this.perform('change_tab', data);
  }

  private async sendKeepAlive() {
    try {
      await this.ensureSubscribed();
      return this.perform('keep_alive');
    } catch (e) {
      console.error('---- e', e);
    }
  }

  setKeepAliveInterval() {
    this.keepAliveIntervalId = setInterval(() => {
      this.sendKeepAlive();
    }, KEEP_ALIVE_INTERVAL);
  }

  removeKeepAliveInterval() {
    this.keepAliveIntervalId && clearInterval(this.keepAliveIntervalId);
  }

  receive(message: Message) {
    const msg = camelizeKeys(message) as Message;

    if ('activeUsers' in msg) {
      if (msg?.activeUsers && msg.activeUsers?.length > 0) {
        this.setActiveUsers(msg.activeUsers);
      }
      if (msg?.activeUsers && msg.activeUsers?.length > 1) {
        this.setUserConnected(true);
      }
    }

    if ('event' in msg) {
      if (msg.event === EEvents.USER_JOINED) {
        this.setActiveUsers(function addUserToActiveUsersWS(users) {
          return [...users, msg];
        });
      }

      if (msg.event === EEvents.USER_LEFT) {
        this.setActiveUsers(function removeUserFromActiveUsersWS(users) {
          return users.filter(({ sid }) => sid !== msg.sid);
        });
      }

      if (msg.event === EEvents.USER_CHANGED_TAB) {
        this.setActiveUsers(function changeUserTabWS(users) {
          return users.map((user) => {
            if (user.sid === msg.sid) {
              return { ...user, tab: msg.tab };
            }
            return user;
          });
        });
      }

      // Show the 'Changes Detected' modal to everyone except the current tab
      if (msg.event === EEvents.PROPOSAL_UPDATED) {
        if (sessionStorage.getItem('hideConfirmChangesForCurrentTab')) {
          sessionStorage.removeItem('hideConfirmChangesForCurrentTab');
        } else {
          this.setShowChangesDetectedModal(true);
        }
      }
    }

    super.receive(msg);
  }
}
