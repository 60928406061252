import axios from 'axios';

import appsignal from './appsignal';
import logger from './logger';

export const shouldReportAxiosError = (axiosError = {}) => {
  if (axiosError instanceof axios.Cancel) {
    return false;
  }

  if ([401, 403, 404, 422, 499].includes(axiosError.response?.status)) {
    return false;
  }

  if (axiosError.message === 'Network Error') {
    return false;
  }

  if (axiosError.message === 'Request aborted') {
    return false;
  }

  if (axiosError.message.startsWith('timeout')) {
    return false;
  }

  return true;
};

const errorTracking = (() => {
  const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
  const isDevelopment = process.env.REACT_APP_ENV === 'development';
  const doNotTrackError = isLocalhost || isDevelopment;

  const sendError = (error) => {
    if (doNotTrackError) {
      logger.error(`[ErrorLogger]: "${error}"`);
    }

    appsignal.sendError(error);
  };

  return { sendError };
})();

export default errorTracking;
