import React from 'react';

import cx from 'classnames';

interface ISimpleDivProps {
  onClick?: () => void;
  className?: string;
  text?: string;
  style?: React.CSSProperties;
}

const SimpleDiv: React.FC<ISimpleDivProps> = (props) => {
  return (
    <div className={cx(props.className)} style={props.style}>
      {props.text}
    </div>
  );
};

export default SimpleDiv;
