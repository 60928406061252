import React, { FC } from 'react';

import cx from 'classnames';
import { useFlowValue } from 'modal.react.js';
import { prop } from 'ramda';

import Icon from 'components/shared/Icon';
import ArrowLeft from 'components/shared/Icon/icons/ArrowLeft';
import Close from 'components/shared/Icon/icons/Close';

import noop from '../../../../utils/noop';
import styles from './index.module.css';
import { IModalProps } from './types';

const Modal: FC<IModalProps> = (props) => {
  // BASE SIZE PROPS - sm, md(base), lmd, lg, auto
  const {
    children,
    className,
    closeButtonClassName,
    returnButtonClassName,
    closeIconClassName,
    contentClassName,
    wrapperClassName,
    duration = 300,
    iconSize,
    isOpen,
    noPadding = false,
    size,
    toClose,
    withoutCloseBtn,
    closeButtonColor,
    toReturn = noop,
    withReturnBtn = false,
    stopMouseDownPropagation = false,
    withoutBackdrop = false,
    closeIcon,
    modalBackdropClassName,
    customBackButton,
  } = props;
  const opacity = useFlowValue(duration, 0, 1, 0);
  const wrapperClasses = cx(styles.modalWrap, wrapperClassName);
  const modalClasses = cx(styles.modal, className, 'custom-modal');
  const modalContentClasses = cx(
    styles.content,
    noPadding && styles.contentNoPadding,
    size && prop(`content-${size}`, styles),
    contentClassName,
    'custom-modal-content',
  );

  const marginClasses = cx(
    styles.margin,
    size && prop(`margin-${size}`, styles),
    noPadding && prop('margin-noPadding', styles),
  );

  const renderBackButton = () => {
    if (!withReturnBtn) return null;

    if (customBackButton) return customBackButton;

    return (
      <button type="button" className={cx(styles.modalReturn, returnButtonClassName)} tabIndex={0} onClick={toReturn}>
        <Icon
          component={ArrowLeft}
          className={cx(styles.closeIcon)}
          width={iconSize}
          height={iconSize}
          strokeWidth={1.5}
        />
      </button>
    );
  };

  const renderCloseButton = () => {
    if (withoutCloseBtn) return null;

    return (
      <button
        type="button"
        className={cx(styles.modalClose, closeButtonClassName, 'custom-modal-close-btn')}
        tabIndex={0}
        onClick={toClose}
      >
        <Icon
          component={closeIcon || Close}
          className={cx(styles.closeIcon, closeIconClassName, 'custom-modal-close-icon')}
          width={iconSize}
          height={iconSize}
          strokeWidth={1.5}
          stroke={closeButtonColor}
        />
      </button>
    );
  };

  return (
    <div
      className={wrapperClasses}
      onMouseDown={(evt) => {
        if (stopMouseDownPropagation) {
          evt.stopPropagation();
        }
      }}
    >
      <div
        tabIndex={0}
        onClick={toClose}
        className={cx(!withoutBackdrop && styles.modalBackdrop, modalBackdropClassName)}
        style={{ transition: duration + 'ms', opacity }}
      />
      <div className={marginClasses}>
        <div className={modalClasses} style={{ transition: duration + 'ms', opacity }}>
          {isOpen && (
            <div className={modalContentClasses}>
              {renderBackButton()}

              {renderCloseButton()}

              {children}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
