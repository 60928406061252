import { useEffect } from 'react';

import { useCompleteModal } from 'modal.react.js';

import { history } from '../configureStore';

export const useModalClose = () => {
  const res = useCompleteModal();
  const unregister = history.listen(() => res.success(false));

  useEffect(() => unregister, []);
};
