import { FC, useMemo } from 'react';

import cx from 'classnames';

import Head from 'components/shared/FileDropZone/Description/Head';
import Center from 'components/shared/Typography/Center';
import Text from 'components/shared/Typography/Text';
import i18n from 'i18n';
import { ASSET_RECOMMENDED_MAX_SIZE, stringifyMimeTypes } from 'utils/asset';

import s from './index.module.css';
import { IDescriptionProps } from './types';

const Description: FC<IDescriptionProps> = ({
  error,
  placeholder,
  headComponent,
  bottomComponent,
  isDragActive = false,
  allowedMimeTypes = [],
  isNoDropzone = false,
  contentType = 'files',
  recommendedFileSize = ASSET_RECOMMENDED_MAX_SIZE,
  renderFilePropertiesHint = ({ recommendedFileSize }, translate) => {
    const defaultValue = `We recommend a maximum file size of ${recommendedFileSize} MB.`;

    if (translate) {
      return i18n.t('proposal_content.file.upload_modal.section.description.size', {
        size: recommendedFileSize,
        defaultValue,
      });
    }
    return defaultValue;
  },
  translate = false,
  className,
}) => {
  const allowedExtensions = stringifyMimeTypes(allowedMimeTypes);
  const extensionsText = useMemo(() => {
    const defaultValue = `File formats include ${allowedExtensions}`;
    if (translate) {
      return i18n.t('proposal_content.file.upload_modal.section.description.format', {
        formats: allowedExtensions,
        defaultValue,
      });
    }
    return defaultValue;
  }, []);
  const baseText =
    placeholder ||
    (isNoDropzone ? `Click here to upload or search for ${contentType}.` : 'Drag and drop Resource here.');

  return (
    <div className={cx(s.dropper, 'custom-dropper', { [s.active]: isDragActive })}>
      <Center>
        <Head error={error} headComponent={headComponent} />
        <Text accent type="gray" className={cx(className, 'custom-dropper-text')}>
          {!!error && (
            <div className={cx(s.error, 'custom-dropper-text-error')}>
              <div>{error}</div>
              <div>&nbsp;</div>
            </div>
          )}
          {!error && (
            <>
              <div className="custom-dropper-base-text">{baseText}</div>
              <div>&nbsp;</div>
            </>
          )}
          <div>{extensionsText}</div>
          <div>{renderFilePropertiesHint({ recommendedFileSize }, translate)}</div>

          {bottomComponent}
        </Text>
      </Center>
    </div>
  );
};

export default Description;
