import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  pagination: {},
  loading: false,
  searchResultCounters: {},
};

const slice = createSlice({
  name: 'properties',
  initialState,
  reducers: {
    update: (state, action) => {
      const { data, meta } = action.payload;

      state.data = data;
      state.pagination = meta;
    },
    load: (state, action) => {
      state.loading = action.payload;
    },
    setSearchResultCountersAction(state, action) {
      state.searchResultCounters = action.payload;
    },
    resetSearchResultCountersAction(state) {
      state.searchResultCounters = {};
    },
  },
});

export const { update, load, setSearchResultCountersAction, resetSearchResultCountersAction } = slice.actions;

export default slice.reducer;
