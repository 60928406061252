import { addDays, format, getHours, getMinutes, isValid, parseISO } from 'date-fns';

import { EDateFormat } from 'detailsv2/structure';

export const formatDate = (d, base = 'MMM d, yyyy') => {
  return format(d, base);
};

export const formatDateTime = (d) => {
  const date = formatDate(d);
  const time = getHoursAndMinutes12(d);

  return `${date} ${time}`;
};

export function formatDateWithFullMonth(d, dateFormat = EDateFormat.MDY) {
  if (dateFormat === EDateFormat.MDY) {
    return format(d, 'MMMM d, yyyy');
  } else if (dateFormat === EDateFormat.DMY) {
    return format(d, 'd MMMM, yyyy');
  } else {
    return format(d, 'yyyy, MMMM d');
  }
}

export function formatRepresentationDate(d) {
  const ISODate = new Date(d).toISOString();
  return format(parseISO(ISODate), 'yyyy-MM-dd');
}

export function getHoursAndMinutes12(d) {
  if (!isValid(d)) return;
  const hours = format(d, 'hh');
  const minutes = format(d, 'mm');
  const ampm = format(d, 'aa');

  return `${hours}:${minutes} ${ampm}`;
}

export function getHoursAndMinutes24(d, separator = 'h') {
  if (!isValid(d)) return;

  const hours = format(d, 'HH');
  const minutes = format(d, 'mm');

  return `${hours}${separator}${minutes}`;
}

export function showTimeString(date) {
  if (!isValid(date)) return false;
  const hours = getHours(date);
  const minutes = getMinutes(date);
  if (hours > 0 || minutes > 0) return true;
  return false;
}

export function addDaysToDate(currentDate, count) {
  return getDefaultFormatDate(addDays(getLocalDateFromString(currentDate), count));
}

export function getLocalDateFromString(date) {
  return new Date(`${date}T00:00:00`);
}

export function getDefaultFormatDate(date) {
  return format(date, 'yyyy-MM-dd');
}

export function getCurrentDateString() {
  return getDefaultFormatDate(new Date());
}

export const isIDL = (start, end) => {
  if (!end) {
    return false;
  }

  return start > end;
};
export const getDateFormat = (displayDatesDMY) => (displayDatesDMY ? EDateFormat.DMY : EDateFormat.MDY);
