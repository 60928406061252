import React from 'react';

const Trash = ({ stroke = '#3e3e40', ...props }) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="#3E3E40"
    xmlns="http://www.w3.org/2000/svg"
    stroke={stroke}
    {...props}
  >
    <path fill="none" d="M20.25 5.65591H3.75" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path fill="none" d="M9.75 10.1559V16.1559" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path fill="none" d="M14.25 10.1559V16.1559" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      fill="none"
      d="M18.75 5.65591V19.9059C18.75 20.1048 18.671 20.2956 18.5303 20.4362C18.3897 20.5769 18.1989 20.6559 18 20.6559H6C5.80109 20.6559 5.61032 20.5769 5.46967 20.4362C5.32902 20.2956 5.25 20.1048 5.25 19.9059V5.65591"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fill="none"
      d="M15.75 5.65591V4.15591C15.75 3.75809 15.592 3.37656 15.3107 3.09525C15.0294 2.81395 14.6478 2.65591 14.25 2.65591H9.75C9.35218 2.65591 8.97064 2.81395 8.68934 3.09525C8.40804 3.37656 8.25 3.75809 8.25 4.15591V5.65591"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Trash;
