import axios from 'axios';
import { I18n } from 'i18n-js';

import axiosApi from 'utils/axios';

export async function loadTranslations(i18n: I18n, locale: string = 'en') {
  if (!i18n.availableLocales.includes(locale)) {
    const response = await axiosApi.get(process.env.PUBLIC_URL + `/locales/${locale}.json`, {
      baseURL: process.env.REACT_APP_ENV === 'development' ? window.location.origin : undefined,
      // @ts-expect-error
      forceMiddleware: true,
      transformResponse: axios.defaults.transformResponse,
    });

    i18n.store(response.data);
    i18n.availableLocales.push(locale);
  }

  i18n.locale = locale;
}

const i18n = new I18n(
  {},
  {
    enableFallback: true,
  },
);

i18n.pluralization.register('ru', (_i18n, count) => {
  const mod10 = count % 10;
  const mod100 = count % 100;
  let key;

  const one = mod10 === 1 && mod100 !== 11;
  const few = [2, 3, 4].includes(mod10) && ![12, 13, 14].includes(mod100);
  const many = mod10 === 0 || [5, 6, 7, 8, 9].includes(mod10) || [11, 12, 13, 14].includes(mod100);

  if (one) {
    key = 'one';
  } else if (few) {
    key = 'few';
  } else if (many) {
    key = 'many';
  } else {
    key = 'other';
  }

  return [key];
});

export default i18n;

// Example: Jun 17, 2024
export const MM_DD_YYYY_FORMAT = '%b %-d, %Y';

export const DD_MM_YYYY_FORMAT = '%-d %b %Y';
