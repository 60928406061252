import React from 'react';

import cx from 'classnames';

import { Alert } from '../../../Alert';
import Button from '../../../Button';
import Modal from '../index';
import s from './index.module.css';
import { IAlertModalProps } from './types';

const AlertModal = ({
  res,
  headerText,
  bodyText,
  closeButtonText = 'Cancel',
  onAccept,
  confirmButtonText,
  footerClassName,
  isAlertBlock = false,
  isDisabledAcceptBtn = false,
  hideCloseButton = false,
  wrapperClassName,
}: IAlertModalProps) => {
  const [loading, setLoading] = React.useState(false);

  const handleOnClick = async () => {
    setLoading(true);
    await onAccept();
    setLoading(false);
  };

  return (
    <Modal
      closeButtonClassName={s.closeButton}
      isOpen
      toClose={res}
      contentClassName={s.modalWrapper}
      wrapperClassName={cx(s.wrapper, wrapperClassName)}
    >
      <div className={s.modalHeader}>{headerText}</div>
      <div className={s.modalBody}>
        {bodyText}
        {isAlertBlock && (
          <Alert
            className={s.alertBlock}
            variant="info"
            content="Please check all information is accurate for this new guest portal."
          />
        )}
      </div>

      <div className={cx(s.modalFooter, footerClassName)}>
        {!hideCloseButton && (
          <Button className={cx(s.modalButton)} type="default" onClick={res} disabled={loading}>
            {closeButtonText}
          </Button>
        )}
        <Button
          className={cx(s.modalButton)}
          disabled={isDisabledAcceptBtn}
          type="primary"
          onClick={handleOnClick}
          loading={loading}
        >
          {confirmButtonText}
        </Button>
      </div>
    </Modal>
  );
};

export default AlertModal;
