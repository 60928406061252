import React from 'react';

import { isNil } from 'ramda';
import { StringParam, useQueryParam } from 'use-query-params';

import MapPoints from 'components/shared/MapBlock/MapPoints';

import s from './index.module.css';

const Map = () => {
  const [q] = useQueryParam('q', StringParam);

  if (isNil(q)) {
    return null;
  }

  const handleMapReady = () => {
    window.google_map_ready = true;
  };

  try {
    const points = JSON.parse(window.atob(q));

    return (
      <div className={s.body}>
        <MapPoints containerStyle={{ width: '450px', height: '319px' }} points={points} onReady={handleMapReady} />
      </div>
    );
  } catch (_) {
    return null;
  }
};

export default Map;
