import { useRef } from 'react';

import { useDispatch } from 'react-redux';

import { useMoveTokens } from 'hooks/useMoveTokens';
import { loginAsThunk } from 'store/auth/thunks';
import { getAccessToken } from 'utils/accessToken';
import { setAuthToken, setLoginAsHeader } from 'utils/axios';
import { getLoginAsUserId } from 'utils/loginAs';

function TokenMiddleware({ children }) {
  const dispatch = useDispatch();
  const beforeMountRef = useRef(false);
  useMoveTokens();

  if (!beforeMountRef.current) {
    setAuthToken(getAccessToken());

    const loginAsUserId = getLoginAsUserId();
    if (loginAsUserId) {
      setLoginAsHeader(loginAsUserId);
      dispatch(loginAsThunk(loginAsUserId.userId, loginAsUserId.accId));
    }

    beforeMountRef.current = true;
  }

  return children;
}

export default TokenMiddleware;
