import { LANDING_PAGES_CONTAINER_ID } from 'components/shared/LandingPage/components/Grid';
import i18n from 'i18n';

export const coverPageConfig = {
  pageTypeContextProps: {
    noPageHeadline: true,
    centerContent: true,
    blockAddingAvailable: false,
    startArrow: true,
    startArrowContainerId: LANDING_PAGES_CONTAINER_ID,
    showTravelDates: true,
    getLeftHeadline: ({ page }) => page.title,
    getLeftSubheadline: ({ page }) =>
      page?.preparedFor ? `${i18n.t('proposal_content.prepared_for')} ${page.preparedFor}` : null,
    notEditLeftSide: true,
    editHeadline: true,
  },
};

export const overviewConfig = {
  pageTypeContextProps: {
    blockAddingAvailable: false,
    noDelete: true,
  },
};

export const overviewMapConfig = {
  pageTypeContextProps: {
    blockAddingAvailable: false,
    noDelete: true,
  },
};

export const costsConfig = {
  pageTypeContextProps: {
    blockAddingAvailable: false,
    leftSubheadline: 'The Fine Print',
  },
};
