import mockData from './mockData';

export default {
  pageTypeContextProps: {
    disableFirstSectionNameRemoval: true,
    leftSubheadline: 'Property Portal',
    subheadlineEditable: true,
  },
  withNav: true,
  useMockData: mockData,
};
