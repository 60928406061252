import React, { PropsWithChildren, useCallback, useState } from 'react';

import Button from 'components/shared/Button';
import { showDefaultSnackbar, showErrorSnackbar } from 'hooks/useSnackbar';
import appsignal from 'utils/appsignal';

import s from './index.module.css';

interface IGeneralSaveErrorProps extends PropsWithChildren {
  withShareReportButton: boolean;
  onShareReport: () => void;
}

const GeneralSaveError = ({ withShareReportButton = false, onShareReport, children }: IGeneralSaveErrorProps) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const handleShareReport = useCallback(async () => {
    setLoading(true);

    try {
      await onShareReport();
      setSuccess(true);

      showDefaultSnackbar(
        <>
          <b>Your report was sent to our Support team.</b>
          <br />
          We will work on resolving your issue as soon as possible.
        </>,
        { fitContent: true, autoClose: false },
      );
    } catch (err) {
      showErrorSnackbar('Problem with sending report');

      appsignal.sendError(err as Error);
    } finally {
      setLoading(false);
    }
  }, [onShareReport]);

  return (
    <>
      <span style={{ marginRight: '1rem', fontWeight: !withShareReportButton ? 'bold' : 'normal' }}>{children}</span>
      {withShareReportButton && !success && (
        <Button type="default" onClick={handleShareReport} loading={loading} className={s.reportButton}>
          Report a Problem
        </Button>
      )}
      {!withShareReportButton && (
        <>
          <br />
          You have already sent a report. Your request is in progress.
        </>
      )}
    </>
  );
};

export default GeneralSaveError;
