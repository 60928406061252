import { format, parse } from 'date-fns';

import { ETimePeriod, NUMBERS_BEFORE_COLON_LENGTH, TIME_FORMAT_12H } from './constants';

const HOURS_AND_MINUTES_WITH_COLON_LENGTH = 5;

export const getInputWidth = (str: string) => {
  const charCountData = str.split('').reduce((acc: any, char: string) => {
    acc[char] = acc[char] ? acc[char] + 1 : 1;
    return acc;
  }, {});

  const charWidth = charCountData['0'] > 2 ? 0.85 : charCountData['1'] > 1 ? 0.65 : 0.8;
  return str.length * charWidth + 0.5 + 'ch';
};

export const getAnotherTimeSystem = (value: string, activeTimePeriod: ETimePeriod) => {
  if (value.length !== HOURS_AND_MINUTES_WITH_COLON_LENGTH) return null;
  const timeWithTimePeriod = `${value} ${activeTimePeriod}`;

  return format(parseDate(timeWithTimePeriod), "(HH'h'mm)");
};

export const isTimeValid = (value: string) => {
  if (value.length < HOURS_AND_MINUTES_WITH_COLON_LENGTH) return true;

  const [hours, minutes] = value.split(':').map(Number);

  return hours > 0 && hours < 13 && minutes >= 0 && minutes < 60;
};

export const formatHours = (value: string) => {
  if (value.length === 1 && Number(value) > 1) {
    return '0' + value;
  }
  return value;
};

export const addColonToTime = (value: string) => {
  if (value.length > NUMBERS_BEFORE_COLON_LENGTH) {
    return value.slice(0, NUMBERS_BEFORE_COLON_LENGTH) + ':' + value.slice(NUMBERS_BEFORE_COLON_LENGTH);
  }
  return value;
};

/*
  Returns today's date object with the time from a string like "10:00 AM"
*/
export const parseDate = (timeString: string) => parse(timeString, TIME_FORMAT_12H, new Date());
