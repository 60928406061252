import React, { FC } from 'react';

import { isNil } from 'ramda';

import ImageLoadingHandler from 'components/shared/ImageLoadingHandler';
import { calculateDimensionsByRatio } from 'utils/calculateDimensionsByRatio';

import { IImageViewProps } from '../types';
import s from './index.module.css';

const ImageView: FC<IImageViewProps> = ({ url, onLoad, showContextMenu, width, height, maxWidth, maxHeight }) => {
  if (isNil(width)) {
    return <img src={url} className={s.image} alt="alt" />;
  }
  const normalizedDimensions = calculateDimensionsByRatio(width, height, maxWidth ?? width, maxHeight ?? 550);

  return (
    <div style={{ width: normalizedDimensions.width, maxWidth: '100%' }}>
      <ImageLoadingHandler
        //@ts-ignore
        src={url}
        //@ts-ignore
        onLoad={onLoad}
        itemClassName={s.image}
        //@ts-ignore
        allowSave={showContextMenu}
        //@ts-ignore
        dimensionsStrategy={ImageLoadingHandler.DIMENSIONS_STRATEGIES.ADAPTIVE_HEIGHT}
        ratio={[width, height]}
      />
    </div>
  );
};

export default ImageView;
