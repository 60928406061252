import React from 'react';

import Loader from 'components/shared/Loader';

const RoutesWrapper = React.lazy(() => import('./RoutesWrapper'));

const LazyAppRoutes: React.FC = () => {
  return (
    <React.Suspense fallback={<Loader isFillBlock />}>
      <RoutesWrapper />
    </React.Suspense>
  );
};

export default LazyAppRoutes;
