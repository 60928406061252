import { addDays, differenceInDays, format, parseISO } from 'date-fns';

import * as types from './recalculateRanges.types';
import { findEarliestDate, getMergeType, parseDate } from './utils';

export const recalculateRangesForOriginal = ({
  itemsArray,
  targetOrganizedBy,
  sourceOrganizedBy,
  referenceDate,
  referenceDay,
}: types.RecalculateRangesForOriginalAgruments) => {
  const mergeType = getMergeType(targetOrganizedBy, sourceOrganizedBy);

  switch (mergeType) {
    case types.MergeTypes.DatesToDates:
      return recalculateDatesToDatesRanges(itemsArray, referenceDate);
    case types.MergeTypes.DaysToDates:
      return recalculateDaysToDatesRanges(itemsArray, referenceDate);
    case types.MergeTypes.DatesToDays:
      return recalculateDatesToDaysRanges(itemsArray, referenceDay);
    case types.MergeTypes.DaysToDays:
      return recalculateDaysToDaysRanges(itemsArray, referenceDay);
  }
};

const recalculateDatesToDatesRanges = (itemsArray: types.Schedule[], referenceDateString: string | undefined) => {
  if (!referenceDateString) {
    throw new Error('Reference date is required when merging dates to dates');
  }

  const referenceDate = parseISO(referenceDateString);
  const sampleDates = itemsArray.map((item) => ({
    startDate: parseDate(item.datesRange.start),
    endDate: parseDate(item.datesRange.end),
  }));
  const earliestDate = findEarliestDate(sampleDates);
  const difference = earliestDate ? differenceInDays(referenceDate, earliestDate) : 0;

  return itemsArray.map((item) => {
    const itemStartDate = parseDate(item.datesRange.start);
    const itemEndDate = parseDate(item.datesRange.end);

    const newStartDate = itemStartDate && addDays(itemStartDate, difference);
    const newEndDate = itemEndDate && addDays(itemEndDate, difference);
    const newDatesRange = {
      start: newStartDate ? format(newStartDate, 'yyyy-MM-dd') : item.datesRange.start,
      end: newEndDate ? format(newEndDate, 'yyyy-MM-dd') : item.datesRange.end,
    };

    return {
      ...item,
      datesRange: newDatesRange,
    };
  });
};

const recalculateDaysToDatesRanges = (itemsArray: types.Schedule[], referenceDateString: string | undefined) => {
  if (!referenceDateString) {
    throw new Error('Reference date is required when merging dates to dates');
  }

  const referenceDate = parseISO(referenceDateString);

  return itemsArray.map((item) => {
    const itemStartDay = item.daysRange.start;
    const itemEndDay = item.daysRange.end;

    const newStartDate = itemStartDay && addDays(referenceDate, itemStartDay - 1);
    const newEndDate = itemEndDay && addDays(referenceDate, itemEndDay - 1);
    const newDatesRange = {
      start: newStartDate ? format(newStartDate, 'yyyy-MM-dd') : item.daysRange.start,
      end: newEndDate ? format(newEndDate, 'yyyy-MM-dd') : item.daysRange.end,
    };

    return {
      ...item,
      datesRange: newDatesRange,
    };
  });
};

const recalculateDatesToDaysRanges = (itemsArray: types.Schedule[], referenceDay: number | undefined) => {
  if (!referenceDay) {
    throw new Error('Reference day is required when merging dates to days');
  }

  const sampleDates = itemsArray.map((item) => ({
    startDate: parseDate(item.datesRange.start),
    endDate: parseDate(item.datesRange.end),
  }));
  const earliestDate = findEarliestDate(sampleDates);
  return itemsArray.map((item) => {
    const itemStartDate = parseDate(item.datesRange.start);
    const itemEndDate = parseDate(item.datesRange.end);
    const startDifference = itemStartDate && differenceInDays(itemStartDate, earliestDate!);
    const endDifference = itemEndDate && differenceInDays(itemEndDate, earliestDate!);
    const newStartDay = itemStartDate ? referenceDay + startDifference! : item.datesRange.start;
    const newEndDay = itemEndDate ? referenceDay + endDifference! : item.datesRange.end;

    const newDaysRange = {
      start: newStartDay,
      end: newEndDay,
    };

    return { ...item, daysRange: newDaysRange };
  });
};

const recalculateDaysToDaysRanges = (itemsArray: types.Schedule[], referenceDay: number | undefined) => {
  if (!referenceDay) {
    throw new Error('Reference day is required when merging days to days');
  }

  return itemsArray.map((item) => {
    const itemStartDay = item.daysRange.start as number | null;
    const itemEndDay = item.daysRange.end as number | null;
    const newStartDay = itemStartDay ? referenceDay + itemStartDay - 1 : item.daysRange.start;
    const newEndDay = itemEndDay ? referenceDay + itemEndDay - 1 : item.daysRange.end;

    const newDaysRange = {
      start: newStartDay,
      end: newEndDay,
    };

    return { ...item, daysRange: newDaysRange };
  });
};
