import React, { lazy, Suspense } from 'react';

import cx from 'classnames';
import { useSelector } from 'react-redux';

import FocalImage from 'components/Layouts/Form/FormBuilder/FocalImage';
import Icon from 'components/shared/Icon';
import Pencil from 'components/shared/Icon/icons/Pencil';
import Trash from 'components/shared/Icon/icons/Trash';
import { lastAssetSelector } from 'store/assets/selectors';
import { ASSET_SIZE_TYPES, ASSET_TYPES, getAssetSrcFromId } from 'utils/asset';

import ImageLoadingHandler from '../ImageLoadingHandler';
import Loader from '../Loader';
import DescriptionName from './DescriptionName';
import styles from './index.module.css';

const PDFView = lazy(() => import(/* webpackChunkName: "PDFView" */ 'components/shared/FileDropZone/View/PDF'));

const renderNameDefault = ({ name }) => <DescriptionName name={name} />;

function MediaItem({
  id,
  className,
  updatable,
  onDelete,
  onEdit,
  onClick,
  imageSizeType = ASSET_SIZE_TYPES.MEDIUM,
  noLazyLoading,
  imageCoords,
  type,
  url,
  name,
  renderName = renderNameDefault,
  versionsProcessingStatus,
}) {
  const cn = cx(styles.imageContainer, styles.imgBlock, className);
  const lastEditedAsset = useSelector(lastAssetSelector) || {};
  const [localAsset, setLocalAsset] = React.useState({});
  const currentImageCoords = localAsset?.imageCoords ? localAsset?.imageCoords : imageCoords;

  React.useEffect(() => {
    if (lastEditedAsset?.id === id) setLocalAsset(lastEditedAsset);
  }, [id, lastEditedAsset]);

  return (
    <div className={cn} onClick={onClick}>
      {type === ASSET_TYPES.DOCUMENT ? (
        versionsProcessingStatus === 'in_progress' ? (
          <div className={styles.frameWrapper}>
            <Suspense fallback={<Loader />}>
              <PDFView url={url} className={styles.file} />
            </Suspense>
          </div>
        ) : (
          <ImageLoadingHandler src={getAssetSrcFromId(id, imageSizeType)} noLazyLoading={noLazyLoading} />
        )
      ) : (
        <FocalImage
          point={currentImageCoords}
          src={getAssetSrcFromId(id, imageSizeType)}
          noLazyLoading={noLazyLoading}
        />
      )}

      {Boolean(updatable) && (
        <div className={styles.hover}>
          {onEdit && <Icon onClick={onEdit} component={Pencil} className={styles.edit} />}
          {onDelete && (
            <Icon
              onClick={onDelete}
              component={Trash}
              width="2.4rem"
              height="2.4rem"
              fill="#fff"
              stroke="#fff"
              className={styles.delete}
            />
          )}
          {renderName({ name })}
        </div>
      )}
    </div>
  );
}

export default MediaItem;
