import { differenceInDays } from 'date-fns';
import { clone, filter, map, pipe, times } from 'ramda';

import { EOrganizedBy, ETimelineType, ETripEvent, IDatesRange, TTripEvent } from 'detailsv2/structure';
import { reverseFlightDateValues } from 'utils/common';
import { addDaysToDate, getLocalDateFromString, isIDL } from 'utils/date';
import { isDefined } from 'utils/ramda';

import { IDay, TDayTripEvent } from '../../../components/Pages/Admin/Itinerary/Details/DetailsChronologicalMode/types';
import { getTimelineDays } from '../../../components/Pages/Admin/Itinerary/Details/DetailsChronologicalMode/utils';

export function assignNewTripEventId(tripEvents: TTripEvent[]) {
  const filterT = filter<TTripEvent>((t) => t.id < 0);
  const mapT = map<TTripEvent, number>((t) => t.id);
  const ids = pipe(filterT, mapT)(tripEvents);

  return Math.min(...(isDefined(ids) ? ids : [0])) - 1;
}

export function syncTripEvents(organizedBy: EOrganizedBy, tripEvents: TTripEvent[]) {
  if (!tripEvents.length) return tripEvents;

  const minDate = getTripEventsMinDate(tripEvents);

  if (organizedBy === EOrganizedBy.Date) {
    if (minDate === null) {
      console.warn('You have trip events without start date in Organize by date mode');

      return tripEvents;
    }

    return normalizeTripEvents(
      tripEvents.map((t) => {
        const datesRange = t.datesRange;
        const startDay =
          differenceInDays(getLocalDateFromString(datesRange.start), getLocalDateFromString(minDate)) + 1;
        const endDay = isDefined(datesRange.end)
          ? differenceInDays(getLocalDateFromString(datesRange.end), getLocalDateFromString(datesRange.start)) +
            startDay
          : null;

        return {
          ...t,
          daysRange: { start: startDay, end: endDay },
        };
      }),
    );
  }

  if (organizedBy === EOrganizedBy.Day) {
    if (minDate === null) return tripEvents;

    return normalizeTripEvents(
      tripEvents.map((t) => {
        const daysRange = t.daysRange;
        const startDate = addDaysToDate(minDate, daysRange.start - 1);
        const endDate = daysRange.end !== null ? addDaysToDate(minDate, daysRange.end - 1) : null;

        return {
          ...t,
          datesRange: { start: startDate, end: endDate },
        };
      }),
    );
  }

  return tripEvents;
}

export function getTripEventsMinDate(tripEvents: TTripEvent[]) {
  let minDate: string | null = null;

  if (!isDefined(tripEvents)) {
    return null;
  }

  minDate = tripEvents[0].datesRange?.start;
  let correspondingDay = tripEvents[0].daysRange.start;

  tripEvents.forEach((t) => {
    if (t.datesRange.start === null) return;
    if (minDate === null) {
      minDate = t.datesRange.start;
      correspondingDay = t.daysRange.start;
    }

    if (t.datesRange.start < minDate) {
      minDate = t.datesRange.start;
      correspondingDay = t.daysRange.start;
    }

    if (t.datesRange.end && t.datesRange.end < minDate) {
      minDate = t.datesRange.end;
      correspondingDay = t.daysRange.end as number;
    }
  });

  if (minDate && correspondingDay > 1) {
    minDate = addDaysToDate(minDate, 1 - correspondingDay);
  }

  return minDate;
}

export function updateStartDate(tripEvents: TTripEvent[], newStartDate: string) {
  const minDate = getTripEventsMinDate(tripEvents);

  if (minDate === null) return;

  const diff = differenceInDays(getLocalDateFromString(newStartDate), getLocalDateFromString(minDate));

  if (diff === 0) return;

  return tripEvents.map((t) => {
    if (t.datesRange.start) {
      return {
        ...t,
        datesRange: {
          start: addDaysToDate(t.datesRange.start, diff),
          end: t.datesRange.end ? addDaysToDate(t.datesRange.end, diff) : null,
        },
      };
    }
  });
}

export function updateEventDaysRange(payload: Pick<TTripEvent, 'id' | 'daysRange'>, tripEvents: TTripEvent[]) {
  const eventIndex = tripEvents.findIndex(({ id }) => id === payload.id);

  if (eventIndex === -1) return tripEvents;

  const tripEvent = tripEvents[eventIndex];

  const diffInDaysNumber = (() => {
    const startRangeDayNumber = tripEvent.daysRange.start;
    return payload.daysRange.start - startRangeDayNumber;
  })();

  const dateRange: IDatesRange = (() => {
    const startRangeDate = tripEvent.datesRange.start;
    const endRangeDate = tripEvent.datesRange.end;

    if (!startRangeDate) return { start: null, end: null };

    return {
      start: addDaysToDate(startRangeDate, diffInDaysNumber),
      end: endRangeDate ? addDaysToDate(endRangeDate, diffInDaysNumber) : null,
    };
  })();

  tripEvents[eventIndex].datesRange = dateRange;
  tripEvents[eventIndex].daysRange = payload.daysRange;

  return tripEvents;
}

export function extendEventDaysRange(
  payload: Pick<TTripEvent, 'id' | 'daysRange'>,
  tripEvents: TTripEvent[],
  organizedBy: EOrganizedBy,
) {
  const eventIndex = tripEvents.findIndex(({ id }) => id === payload.id);

  if (eventIndex === -1) return tripEvents;

  const tripEvent = clone(tripEvents[eventIndex]);

  const diffInDaysNumber = (() => {
    if (!payload.daysRange.end) return 0;

    return payload.daysRange.end - payload.daysRange.start;
  })();

  const datesRange: IDatesRange = (() => {
    const startRangeDate = tripEvent.datesRange.start;

    if (!startRangeDate) return { start: null, end: null };

    return {
      start: startRangeDate,
      end: payload.daysRange.end ? addDaysToDate(startRangeDate, diffInDaysNumber) : null,
    };
  })();

  tripEvent.datesRange = datesRange;
  tripEvent.daysRange = payload.daysRange;

  return updateTripEvent(tripEvent, tripEvents, organizedBy);
}

export function getNewTripEvent(tripEvents: TTripEvent[], payload: TTripEvent, organizedBy: EOrganizedBy) {
  const tripEvent: TTripEvent = {
    ...payload,
    positions: getTripEventPositions(payload, tripEvents, organizedBy),
    id: assignNewTripEventId(tripEvents),
  };

  const minDate = getTripEventsMinDate(tripEvents);

  if (!minDate) return tripEvent;

  if (organizedBy === EOrganizedBy.Date) {
    const diff = differenceInDays(getLocalDateFromString(tripEvent.datesRange.start), getLocalDateFromString(minDate));

    const startDay = diff < 0 ? 1 : diff + 1;
    const endDay = tripEvent.datesRange.end
      ? startDay +
        differenceInDays(
          getLocalDateFromString(tripEvent.datesRange.end),
          getLocalDateFromString(tripEvent.datesRange.start),
        )
      : null;

    tripEvent.daysRange = {
      start: startDay,
      end: endDay,
    };
  }

  return tripEvent;
}

export function insertDayAbove(tripEvents: TTripEvent[], day: IDay) {
  return tripEvents.reduce((acc: TTripEvent[], tripEvent) => {
    const duration = tripEvent.daysRange.end ? tripEvent.daysRange.end - tripEvent.daysRange.start : null;
    const startDay = day.day <= tripEvent.daysRange.start ? tripEvent.daysRange.start + 1 : tripEvent.daysRange.start;

    acc.push({
      ...tripEvent,
      daysRange: {
        start: startDay,
        end: duration ? startDay + duration : null,
      },
    });

    return acc;
  }, []);
}

export function getTripEventPositions(
  tripEvent: TTripEvent,
  tripEvents: TTripEvent[],
  organizedBy: EOrganizedBy,
  isUpdating?: boolean,
) {
  const timeline = getTimelineDays(tripEvents);
  const positions: number[] = [];

  if (organizedBy === EOrganizedBy.Date) {
    const datesRange = getDatesRange(tripEvent.datesRange.start!, tripEvent.datesRange.end);
    datesRange.forEach((d) => {
      const day = timeline.find((t) => t.date === d);
      const isOvernight =
        tripEvent.type === ETripEvent.Accommodation &&
        tripEvent.datesRange.start !== day?.date &&
        tripEvent.datesRange.end !== day?.date;

      positions.push(getPosition(tripEvent, clone(day), isOvernight, isUpdating));
    });
  } else if (organizedBy === EOrganizedBy.Day) {
    const daysRange = getDaysRange(tripEvent);

    daysRange.forEach((d) => {
      const day = timeline.find((t) => t.day === d);
      const isOvernight =
        tripEvent.type === ETripEvent.Accommodation &&
        tripEvent.daysRange.start !== day?.day &&
        tripEvent.daysRange.end !== day?.day;

      positions.push(getPosition(tripEvent, clone(day), isOvernight, isUpdating));
    });
  }

  return positions;
}

function getDatesRange(start: string, end: string | null) {
  if (!end) {
    return [start];
  }

  const duration = Math.abs(differenceInDays(getLocalDateFromString(end), getLocalDateFromString(start))) + 1;

  const result = [];

  for (let i = 0; i < duration; i++) {
    result.push(addDaysToDate(start, i));
  }

  return result;
}

function getDaysDuration(tripEvent: TTripEvent) {
  return (tripEvent.daysRange.end ? tripEvent.daysRange.end - tripEvent.daysRange.start + 1 : 1) || 1;
}

function getDaysRange(tripEvent: TTripEvent) {
  return times((i) => tripEvent.daysRange.start + i, getDaysDuration(tripEvent));
}

function getPosition(tripEvent: TTripEvent, day?: IDay, isOvernight?: boolean, isUpdating?: boolean) {
  if (!day || day.tripEvents.length === 0) {
    return 0;
  }

  if (isUpdating && tripEvent.type === ETripEvent.Accommodation && !isOvernight) {
    const foundEvent = day?.tripEvents.find((t) => t.id === tripEvent.id);

    if (foundEvent) {
      return foundEvent.position;
    }
  }

  if (tripEvent.type === ETripEvent.Accommodation && tripEvent.data.isAlternate && isOvernight) {
    return Math.max(...day.tripEvents.map((t) => t.position)) + 1;
  }

  let newTripPosition = 0;

  day.tripEvents.forEach((t) => {
    if (
      t.type !== ETripEvent.Accommodation ||
      (t.type === ETripEvent.Accommodation && t.timelineType !== ETimelineType.Middle) ||
      (t.type === ETripEvent.Accommodation && t.timelineType === ETimelineType.Middle && !t.data.isAlternate)
    ) {
      newTripPosition = t.position + 1;
    }
  });

  return newTripPosition;
}

export function deleteTripEvent(id: number, tripEvents: TTripEvent[], droppedTripEvents: number[]) {
  tripEvents = clone(tripEvents);
  droppedTripEvents = clone(droppedTripEvents);
  const droppedIndex = tripEvents.findIndex((t) => t.id === id);

  if (droppedIndex < 0) return;

  const [droppedTripEvent] = tripEvents.splice(droppedIndex, 1);

  if (droppedTripEvent.id > 0) {
    droppedTripEvents.push(droppedTripEvent.id);
  }

  const tripEventsWithSyncedPositions = syncPositions(tripEvents);

  return { tripEvents: tripEventsWithSyncedPositions, droppedTripEvents };
}

export function updateTripEvent(tripEvent: TTripEvent, tripEvents: TTripEvent[], organizedBy: EOrganizedBy) {
  const index = tripEvents.findIndex((t) => t.id === tripEvent.id);
  tripEvents = clone(tripEvents);

  if (index < 0) {
    return;
  }

  const oldTripEvent = clone(tripEvents[index]);
  const newTripEvent = clone(tripEvent);
  if (
    oldTripEvent.datesRange.start !== null &&
    newTripEvent.datesRange.start !== null &&
    organizedBy === EOrganizedBy.Date &&
    oldTripEvent.datesRange.start !== newTripEvent.datesRange.start
  ) {
    const startDaysDiff = differenceInDays(
      getLocalDateFromString(newTripEvent.datesRange.start),
      getLocalDateFromString(oldTripEvent.datesRange.start),
    );

    newTripEvent.daysRange.start += startDaysDiff;
  }

  if (newTripEvent.type === ETripEvent.Accommodation && oldTripEvent.type === ETripEvent.Accommodation) {
    if (
      (newTripEvent.data.isAlternate && !oldTripEvent.data.isAlternate) ||
      (!newTripEvent.data.isAlternate && oldTripEvent.data.isAlternate)
    ) {
      newTripEvent.positions = getTripEventPositions(tripEvent, tripEvents, organizedBy, true);
    }
  }

  tripEvents[index] = newTripEvent;

  const syncedTripEvents = syncTripEvents(organizedBy, tripEvents);

  adjustPositionsLength(syncedTripEvents[index]);

  return syncPositions(syncedTripEvents);
}

function syncPositions(tripEvents: TTripEvent[]) {
  tripEvents = clone(tripEvents);

  const timelineDays = getTimelineDays(tripEvents);

  timelineDays.forEach((d) => {
    const sortedTripEvents = d.tripEvents.sort((t1, t2) => t1.position - t2.position);

    tripEvents = updateTripEventsPositions(tripEvents, { sortedTripEvents });
  });

  return tripEvents;
}

export function updateTripEventsPositions(
  tripEvents: TTripEvent[],
  { sortedTripEvents }: { sortedTripEvents: TDayTripEvent[] },
) {
  tripEvents = clone(tripEvents);

  sortedTripEvents.forEach((dayTripEvent, i) => {
    if (dayTripEvent.position !== i) {
      const sourceTripEvent = tripEvents.find((t) => t.id === dayTripEvent.id);

      if (!sourceTripEvent) return;

      const daysRange = getDaysRange(sourceTripEvent);

      const dayIndex = daysRange.findIndex((d) => dayTripEvent.day === d);

      if (dayIndex < 0) return;

      sourceTripEvent.positions[dayIndex] = i;
    }
  });

  return tripEvents;
}

function adjustPositionsLength(tripEvent: TTripEvent) {
  const positions = tripEvent.positions;
  const duration = getDaysDuration(tripEvent);

  if (duration === positions.length) return;

  if (duration > positions.length) {
    tripEvent.positions = positions.concat(new Array(duration - positions.length).fill(0));

    return;
  }

  tripEvent.positions = positions.slice(0, duration);
}

const normalizeTripEvents = (tripEvents: TTripEvent[]) =>
  tripEvents.map((t) => {
    const { type, daysRange } = t;
    if (type === ETripEvent.Flight) {
      return isIDL(daysRange.start, daysRange.end) ? reverseFlightDateValues(t, true) : t;
    }
    return t;
  });
