import React from 'react';

const ErrorCircleRounded = (props) => (
  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.99967 8.16659C7.18856 8.16659 7.34701 8.10259 7.47501 7.97459C7.60256 7.84703 7.66634 7.68881 7.66634 7.49992V4.81659C7.66634 4.6277 7.60256 4.47214 7.47501 4.34992C7.34701 4.2277 7.18856 4.16659 6.99967 4.16659C6.81079 4.16659 6.65256 4.23036 6.52501 4.35792C6.39701 4.48592 6.33301 4.64436 6.33301 4.83325V7.51659C6.33301 7.70547 6.39701 7.86103 6.52501 7.98325C6.65256 8.10547 6.81079 8.16659 6.99967 8.16659ZM6.99967 10.8333C7.18856 10.8333 7.34701 10.7693 7.47501 10.6413C7.60256 10.5137 7.66634 10.3555 7.66634 10.1666C7.66634 9.9777 7.60256 9.81925 7.47501 9.69125C7.34701 9.5637 7.18856 9.49992 6.99967 9.49992C6.81079 9.49992 6.65256 9.5637 6.52501 9.69125C6.39701 9.81925 6.33301 9.9777 6.33301 10.1666C6.33301 10.3555 6.39701 10.5137 6.52501 10.6413C6.65256 10.7693 6.81079 10.8333 6.99967 10.8333ZM6.99967 14.1666C6.07745 14.1666 5.21079 13.9915 4.39967 13.6413C3.58856 13.2915 2.88301 12.8166 2.28301 12.2166C1.68301 11.6166 1.20812 10.911 0.858341 10.0999C0.508119 9.28881 0.333008 8.42214 0.333008 7.49992C0.333008 6.5777 0.508119 5.71103 0.858341 4.89992C1.20812 4.08881 1.68301 3.38325 2.28301 2.78325C2.88301 2.18325 3.58856 1.70814 4.39967 1.35792C5.21079 1.00814 6.07745 0.833252 6.99967 0.833252C7.9219 0.833252 8.78856 1.00814 9.59967 1.35792C10.4108 1.70814 11.1163 2.18325 11.7163 2.78325C12.3163 3.38325 12.7912 4.08881 13.141 4.89992C13.4912 5.71103 13.6663 6.5777 13.6663 7.49992C13.6663 8.42214 13.4912 9.28881 13.141 10.0999C12.7912 10.911 12.3163 11.6166 11.7163 12.2166C11.1163 12.8166 10.4108 13.2915 9.59967 13.6413C8.78856 13.9915 7.9219 14.1666 6.99967 14.1666Z"
      fill="#DF3802"
    />
  </svg>
);

export default ErrorCircleRounded;
