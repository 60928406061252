import React, { FC, lazy, Suspense, useMemo } from 'react';

import { useSelector } from 'react-redux';

import ContainerWithPoint from 'components/Layouts/Form/FormBuilder/Content/AddNewContent/Upload/ContainerWithPoint';
import ImageView from 'components/shared/FileDropZone/View/Image';
import SimpleView from 'components/shared/FileDropZone/View/Simple';
import VideoView from 'components/shared/FileDropZone/View/Video';
import { accountIdSelector, isSuperAdminSelector } from 'store/user/selectors';
import { ASSET_STORAGE_TYPES } from 'utils/asset';

import Loader from '../../Loader';
import s from './index.module.css';
import { IViewProps } from './types';

const PDFView = lazy(() => import(/* webpackChunkName: "PDFView" */ 'components/shared/FileDropZone/View/PDF'));

const getComponent = (type: string) => {
  switch (type) {
    case 'image/jpeg':
    case 'image/png':
    case 'image/svg+xml':
    case 'image/svg':
      return ImageView;
    case 'video/mp4':
      return VideoView;
    case 'application/pdf':
      return PDFView;
    default:
      throw new Error('Unexpected type: ' + type);
  }
};

const View: FC<IViewProps> = ({
  url,
  type,
  filename,
  width,
  height,
  maxWidth,
  maxHeight,
  dynamic,
  simple,
  onLoad,
  point,
  onSelectPoint,
  fileAccId,
  assetStorageType,
}) => {
  const accountId = useSelector(accountIdSelector);
  const isSuperAdmin = useSelector(isSuperAdminSelector);
  const showContextMenu = isSuperAdmin || accountId === fileAccId || assetStorageType === ASSET_STORAGE_TYPES.DOCUMENTS;

  const element = useMemo(() => {
    const Component = simple ? SimpleView : getComponent(type!);

    let element = (
      <Suspense fallback={<Loader />}>
        <Component
          url={url}
          filename={filename}
          onLoad={onLoad}
          width={width!}
          height={height!}
          maxHeight={maxHeight}
          maxWidth={maxWidth}
          dynamic={dynamic}
          showContextMenu={showContextMenu}
        />
      </Suspense>
    );

    if (onSelectPoint) {
      //@ts-ignore
      return <ContainerWithPoint point={point} onSelectPoint={onSelectPoint} children={element} />;
    }

    return element;
  }, [dynamic, filename, height, onLoad, onSelectPoint, point, simple, type, url, width]);

  return <div className={s.view} children={element} />;
};

export default View;
