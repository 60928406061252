import React from 'react';

const CircleArrowIcon = ({ width, height, ...rest }) => (
  <svg width={width} height={height} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M12 21.4059C16.9706 21.4059 21 17.3765 21 12.4059C21 7.43535 16.9706 3.40591 12 3.40591C7.02944 3.40591 3 7.43535 3 12.4059C3 17.3765 7.02944 21.4059 12 21.4059Z"
      stroke="#3F3C43"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M8.82187 11.834L12 8.65591L15.1781 11.834"
      stroke="#3F3C43"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path d="M12 16.1559V8.65591" stroke="#3F3C43" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default CircleArrowIcon;
