import React from 'react';

import cx from 'classnames';

import Icon from 'components/shared/Icon';
import CircleArrowIcon from 'components/shared/Icon/icons/CircleArrowIcon';
import ExclamationCircle from 'components/shared/Icon/icons/ExclamationCircle';

import s from './index.module.css';
import { IHeadProps } from './types';

const Head = ({ error, headComponent }: IHeadProps) => {
  if (headComponent) return <div className={s.head}>{headComponent}</div>;

  return (
    <Icon
      component={error ? ExclamationCircle : CircleArrowIcon}
      width="4.1rem"
      height="4.1rem"
      className={cx(s.head, 'custom-dropper-head', {
        [s.error]: Boolean(error),
        'custom-dropper-head-error': Boolean(error),
      })}
    />
  );
};

export default Head;
