import React, { FC } from 'react';

import Tag from 'components/shared/FormAdapters/FormDefault/TagsInput/Tag';

import { IListProps } from './types';

const List: FC<IListProps> = ({ collection, isArray, onDelete, type }): any => {
  return isArray && collection.map((tag) => <Tag key={tag} title={tag} type={type} onDelete={() => onDelete(tag)} />);
};

export default List;
