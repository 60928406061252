import { Dispatch, SetStateAction } from 'react';

import type { ChannelEvents } from '@anycable/core';

import { ITINERARIES_ITEM_TYPE_TO_PATH_STEP_MAP } from 'utils/itineraries/map';
import { EProposalItems } from 'utils/itineraryDetails/types';

export type Params<T extends EProposalItems> = {
  [K in T as `${T}_id`]: string;
};

export enum EEvents {
  USER_JOINED = 'user_joined',
  USER_CHANGED_TAB = 'user_changed_tab',
  USER_LEFT = 'user_left',
  PROPOSAL_UPDATED = 'proposal_updated',
}

export type User = {
  userId: string;
  fullName: string;
  tab: string;
  sid: string;
};

type ActiveUsers = {
  activeUsers: User[];
};

type UserJoinedEvent = {
  event: EEvents.USER_JOINED;
} & User;

type UserChangedTabEvent = {
  event: EEvents.USER_CHANGED_TAB;
} & Pick<User, 'userId' | 'tab' | 'sid'>;

type UserLeftEvent = {
  event: EEvents.USER_LEFT;
} & Pick<User, 'sid' | 'userId'>;

type ProposalUpdatedEvent = {
  event: EEvents.PROPOSAL_UPDATED;
} & Pick<User, 'userId'>;

export type TabData<T extends EProposalItems> = {
  tab: keyof (typeof ITINERARIES_ITEM_TYPE_TO_PATH_STEP_MAP)[T];
};

export type Message = UserJoinedEvent | UserChangedTabEvent | UserLeftEvent | ProposalUpdatedEvent | ActiveUsers;

export interface Events extends ChannelEvents<Message> {
  user_joined: (msg: UserJoinedEvent) => void;
}

export interface Actions<T extends EProposalItems> {
  join: (msg: TabData<T>) => void;
  change_tab: (msg: TabData<T>) => void;
  keep_alive: () => void;
}

export interface Config {
  currentUserId: string;
  setActiveUsers: Dispatch<SetStateAction<User[]>>;
  setShowChangesDetectedModal: Dispatch<SetStateAction<boolean>>;
  setUserConnected: Dispatch<SetStateAction<boolean>>;
}
