import { combineReducers } from 'redux';

import filesReducer from './files';
import itinerariesReducer from './itineraries';
import membersReducer from './members';
import pagesTableReducer from './pagesTable';
import pendingContentReducer from './pendingContent';
import propertiesReducer from './properties';
import usersReducer from './users';

const adminReducer = combineReducers({
  files: filesReducer,
  users: usersReducer,
  pagesTable: pagesTableReducer,
  members: membersReducer,
  pendingContent: pendingContentReducer,
  properties: propertiesReducer,
  itineraries: itinerariesReducer,
});

export default adminReducer;
