import React, { FC, useState } from 'react';

import * as R from 'ramda';
import { useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';

import Description from 'components/shared/FileDropZone/Description';
import { IFileDropZoneProps } from 'components/shared/FileDropZone/types';
import { isAgentSelector } from 'store/user/selectors';
import { ASSET_DEFAULT_MAX_SIZE, getAttrsFromMIMETypes } from 'utils/asset';

import * as errors from './errors';
import s from './index.module.css';
import { getFileUploadError } from './utils';

const FileDropZone: FC<IFileDropZoneProps> = ({
  placeholder,
  allowedMimeTypes = [],
  maxFileSize = ASSET_DEFAULT_MAX_SIZE,
  recommendedFileSize,
  maxImageWidth = Infinity,
  maxImageHeight = Infinity,
  multiple = false,
  onSelectNewFiles,
  headComponent,
  name = 'file',
  needToShowWarning,
  renderFilePropertiesHint,
  translate = false,
}) => {
  const [error, setError] = useState<string | null>(null);
  const showErrorByCode = R.pipe(errors.getMarkup, setError);
  const isAgent = useSelector(isAgentSelector);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple,
    onDropRejected: R.pipe(errors.getPrimaryFromRejections, showErrorByCode),
    onDropAccepted: async (files) => {
      const result = [];

      for (const file of files) {
        const error = await getFileUploadError({ file, allowedMimeTypes, maxFileSize, maxImageHeight, maxImageWidth });

        if (error) return setError(error);

        result.push({
          url: URL.createObjectURL(file),
          mimeType: file.type,
          filename: file.name,
        });
      }

      onSelectNewFiles(result);
    },
  });

  return (
    <div>
      <div {...getRootProps()}>
        <input {...getInputProps(getAttrsFromMIMETypes(allowedMimeTypes))} name={name} />

        <Description
          error={error}
          placeholder={placeholder}
          allowedMimeTypes={allowedMimeTypes}
          maxFileSize={maxFileSize}
          recommendedFileSize={recommendedFileSize}
          headComponent={headComponent}
          isDragActive={isDragActive}
          renderFilePropertiesHint={renderFilePropertiesHint}
          translate={translate}
        />
      </div>
      {Boolean(needToShowWarning) && !isAgent && (
        <div className={s.warning}>
          <div>Be cognizant if you are sharing STO rates here that all agents who use</div>
          <div>Safari Portal may not be on the same nett rate contract agreement;</div>
          <div>you may only want to share rack rate sheets.</div>
        </div>
      )}
    </div>
  );
};

export default FileDropZone;
