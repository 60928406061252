import { LIBRARY_RESOURCE, PENDING_CONTENT, LIBRARY_CONTENT, LIBRARY_ITINERARIES } from 'constants/index';
import { CONTENT_LIBRARY_PATH, ITINERARIES_PATH, PENDING_CONTENT_PATH, RESOURCE_LIBRARY_PATH } from 'constants/routes';

export const mapLibType2LibPath = (libType) => {
  switch (libType) {
    case LIBRARY_RESOURCE:
      return RESOURCE_LIBRARY_PATH;
    case PENDING_CONTENT:
      return PENDING_CONTENT_PATH;
    case LIBRARY_CONTENT:
      return CONTENT_LIBRARY_PATH;
    case LIBRARY_ITINERARIES:
      return ITINERARIES_PATH;
    default:
      return CONTENT_LIBRARY_PATH;
  }
};

export const mapLibType2Theme = (libType) => {
  switch (libType) {
    case LIBRARY_RESOURCE:
      return 'accent';
    case PENDING_CONTENT:
      return 'secondary2';
    case LIBRARY_CONTENT:
      return 'secondary2';
    default:
      return 'primary';
  }
};
