import React, { useState, useEffect } from 'react';

import { render } from 'react-dom';

import Button from 'components/shared/Button';
import FormActions from 'components/shared/FormAdapters/FormDefault/FormActions';
import Modal from 'components/shared/Modal';
import FormTitle from 'components/shared/Typography/FormTitle';

function ConfirmAlert(props) {
  const {
    title = 'Are you sure?',
    confirmButtonText = 'Confirm',
    cancelButtonText = 'Cancel',
    onConfirm,
    onCancel,
    onManualClose,
    hint,
    hintBottom,
    noBorderWithMargin = false,
    showCancel = true,
    children,
  } = props;

  const [isModalOpened, setIsModalOpened] = useState(false);

  const closeModal = () => {
    setIsModalOpened(false);
    removeElementReconfirm();
  };

  const openModal = () => {
    setIsModalOpened(true);
  };

  const manualClose = () => {
    if (onManualClose) {
      onManualClose();
    }

    closeModal();
  };

  useEffect(() => {
    openModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      isOpen={isModalOpened}
      toClose={manualClose}
      children={
        <>
          <FormTitle
            size="small"
            noBorderWithMargin={noBorderWithMargin}
            hint={hint}
            hintBottom={hintBottom}
            center
            className="custom-modal-title"
          >
            {title}
          </FormTitle>

          {children}

          <FormActions isCenter>
            {showCancel && (
              <Button
                onClick={() => {
                  if (onCancel) onCancel();
                  closeModal();
                }}
                children={cancelButtonText}
                type="default"
              />
            )}

            <Button
              htmlType="button"
              children={confirmButtonText}
              onClick={() => {
                if (onConfirm) onConfirm();
                closeModal();
              }}
            />
          </FormActions>
        </>
      }
    />
  );
}

function createElementReconfirm(properties) {
  let divTarget = document.getElementById('confirm-alert');

  divTarget = document.createElement('div');
  divTarget.id = 'confirm-alert';
  document.body.appendChild(divTarget);
  render(<ConfirmAlert {...properties} />, divTarget);
}

function removeElementReconfirm() {
  const target = document.getElementById('confirm-alert');
  if (target) {
    target.parentNode.removeChild(target);
  }
}

export function confirmAlert(properties) {
  createElementReconfirm(properties);
}
