import React from 'react';

import s from './index.module.css';

export function BackButtonContent({ title, subtitle }: { title: string; subtitle: string }) {
  return (
    <div className={s.content}>
      <span className={s.title}>{getTruncatedTitle(title || subtitle, 38)}</span>
      {title && <span className={s.subtitle}>{getTruncatedTitle(subtitle, 38)}</span>}
    </div>
  );
}

const getTruncatedTitle = (title: string, size: number = 38) => {
  if (!title) {
    return null;
  }
  return title.length > size ? `${title.slice(0, size)}...` : title;
};
