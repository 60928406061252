import React, { useCallback } from 'react';

import ReactDOM from 'react-dom';

import Snackbar, { SNACKBAR_SKIN } from 'components/shared/Snackbar';

const TIME = 10 * 1000;

const stack = createStack();

export const useSnackbar = ({ time = TIME } = {}) => {
  return useCallback((text, isError = false) => {
    const type = isError ? SNACKBAR_SKIN.ERROR : SNACKBAR_SKIN.DEFAULT;

    showSnackbar({ text, type, time });
  }, []);
};

function createStack() {
  const stack = document.createElement('div');
  stack.setAttribute('id', 'snackbar');

  stack.style.zIndex = '1302';
  stack.style.position = 'absolute';
  stack.style.bottom = '2rem';
  stack.style.right = '2rem';
  stack.style.textAlign = 'right';

  document.body.appendChild(stack);

  return stack;
}

function inStack(id) {
  return !!document.getElementById(id);
}

export function showSnackbar({
  text,
  type = SNACKBAR_SKIN.DEFAULT,
  time = TIME,
  uniqueId,
  autoClose = true,
  fitContent = false,
  prohibitManualClose = false,
}) {
  if (uniqueId && inStack(uniqueId)) {
    return;
  }

  const container = document.createElement('div');

  if (uniqueId) {
    container.id = uniqueId;
  }

  const onHide = () => {
    try {
      ReactDOM.unmountComponentAtNode(container);
      stack.removeChild(container);
    } catch (err) {
      // do nothing
    }
  };

  const element = (
    <Snackbar type={type} body={text} onHide={onHide} fitContent={fitContent} hideCloseButton={prohibitManualClose} />
  );

  ReactDOM.render(element, container);
  stack.appendChild(container);

  if (autoClose) {
    setTimeout(onHide, time);
  }

  return onHide;
}

export function showDefaultSnackbar(info, options = {}) {
  showSnackbar({
    text: info,
    type: SNACKBAR_SKIN.DEFAULT,
    ...options,
  });
}

export function showErrorSnackbar(info, options = {}) {
  showSnackbar({
    text: info,
    type: SNACKBAR_SKIN.ERROR,
    ...options,
  });
}

export function showSuccessSnackbar(info, options = {}) {
  showSnackbar({
    text: info,
    type: SNACKBAR_SKIN.SUCCESS,
    ...options,
  });
}
