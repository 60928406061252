import React, { FC } from 'react';

import cx from 'classnames';
import JsFileDownloader from 'js-file-downloader';

import Icon from 'components/shared/Icon';
import Download2 from 'components/shared/Icon/icons/Download2';
import { SNACKBAR_SKIN } from 'components/shared/Snackbar';
import FormTitle from 'components/shared/Typography/FormTitle';
import { showSnackbar } from 'hooks/useSnackbar';
import i18n from 'i18n';
import { checkIsWebview } from 'utils/mobileApplication';

import s from './index.module.css';
import { IViewHeadProps } from './types';

export const handleDownloadDocument = async (enteredFile: any) => {
  const getExtension = (str: string) => str.slice(str.lastIndexOf('.'));
  const extension = getExtension(enteredFile.filename);

  try {
    await new JsFileDownloader({
      url: enteredFile.url,
      filename: `${enteredFile.name}${extension}`,
    });
    //@ts-ignore
    showSnackbar({ text: i18n.t('proposal_content.file.file_downloaded', { proposal_title: enteredFile.name }) });
  } catch (e) {
    //@ts-ignore
    showSnackbar({
      text: `'${enteredFile.name}' couldn't be downloaded. Please try again.`,
      type: SNACKBAR_SKIN.ERROR,
    });
  }
};

export const ViewHead: FC<IViewHeadProps> = ({ isDocument = false, asset, isCenter = false }) => {
  const topClasses = cx(s.top, isDocument && s.topDocument);
  const titleClasses = cx('breakWord', s.title);
  const isWebview = checkIsWebview();
  const isLoadingAvailable = isDocument && !isWebview;

  return (
    <div className={topClasses}>
      {/*@ts-ignore*/}
      <FormTitle className={titleClasses} center={isCenter}>
        {asset?.name}
      </FormTitle>
      {isLoadingAvailable && (
        <Icon component={Download2} className={cx(s.icon)} onClick={() => handleDownloadDocument(asset)} />
      )}
    </div>
  );
};
