import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: [],
  meta: {
    totalCount: 0,
    totalPages: 0,
  },
  searchResultCounters: {},
  searchFilters: [],
  isLoading: false,
};

const slice = createSlice({
  name: 'pagesTable',
  initialState,
  reducers: {
    setPagesAction(state, action) {
      const { data, meta } = action.payload;
      state.list = data;
      state.meta = meta;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    resetPagesTableAction: (state, _action) => (state = initialState),
    setSearchResultCountersAction(state, action) {
      state.searchResultCounters = action.payload;
    },
    resetSearchResultCountersAction(state) {
      state.searchResultCounters = {};
    },
    setSearchFiltersAction(state, action) {
      state.searchFilters = action.payload;
    },
    resetSearchFiltersAction(state) {
      state.searchFilters = [];
    },
  },
});

export const {
  setPagesAction,
  resetPagesTableAction,
  setSearchResultCountersAction,
  resetSearchResultCountersAction,
  setSearchFiltersAction,
  resetSearchFiltersAction,
} = slice.actions;

export default slice.reducer;
