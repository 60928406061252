import React from 'react';

import classes from 'classnames';
import { prop } from 'ramda';

import { IButtonGroupProps } from '../types';
import styles from './Group.module.css';

const Separator = () => {
  return <div className={styles.separator} />;
};

const Group = ({ className, type = 'default', shape, disabled, children }: IButtonGroupProps) => {
  const buttonClasses = classes(
    styles.buttonGroup,
    type && prop(`buttonGroup-type-${type}`, styles),
    shape && prop(`buttonGroup-shape-${shape}`, styles),
    disabled && prop('buttonGroup-disabled', styles),
    className,
  );

  return (
    <div className={buttonClasses}>
      {React.Children.map(children, (child, index) => {
        if (!React.isValidElement(child)) return null;

        return (
          <>
            {child}
            {index < React.Children.count(children) - 1 && <Separator />}
          </>
        );
      })}
    </div>
  );
};

export default Group;
