import { createContext } from 'react';

import { ILandingPageTypeContext, ILandingPageTypeParams } from './types';

export const defaultParams = {
  blockAddingAvailable: true,
  disableFirstSectionNameRemoval: false,
  getLeftHeadline: ({ page: _page }: ILandingPageTypeParams) => {},
  getLeftSubheadline: ({ page: _page }: ILandingPageTypeParams) => {},
};

// FIXME: remove any
const LandingPageTypeContext = createContext<ILandingPageTypeContext>(defaultParams as any);

export default LandingPageTypeContext;
