import { parseISO } from 'date-fns';

import { EOrganizedBy } from 'detailsv2/structure';

import * as types from './recalculateRanges.types';

export const getMergeType = (targetOrganizedBy: EOrganizedBy, sourceOrganizedBy: EOrganizedBy): types.MergeTypes => {
  const isMergeDatesToDates = sourceOrganizedBy === EOrganizedBy.Date && targetOrganizedBy === EOrganizedBy.Date;
  const isMergeDatesToDays = sourceOrganizedBy === EOrganizedBy.Date && targetOrganizedBy === EOrganizedBy.Day;
  const isMergeDaysToDates = sourceOrganizedBy === EOrganizedBy.Day && targetOrganizedBy === EOrganizedBy.Date;
  const isMergeDaysToDays = sourceOrganizedBy === EOrganizedBy.Day && targetOrganizedBy === EOrganizedBy.Day;

  if (isMergeDatesToDates) {
    return types.MergeTypes.DatesToDates;
  } else if (isMergeDaysToDates) {
    return types.MergeTypes.DaysToDates;
  } else if (isMergeDatesToDays) {
    return types.MergeTypes.DatesToDays;
  } else if (isMergeDaysToDays) {
    return types.MergeTypes.DaysToDays;
  } else {
    throw new Error('Invalid combination of targetOrganizedBy and sourceOrganizedBy');
  }
};

export const parseDate = (dateString: string | null): Date | null => {
  if (!dateString) {
    return null;
  }
  return parseISO(dateString);
};

export const findEarliestDate = (sampleDates: { startDate: Date | null; endDate: Date | null }[]): Date | null => {
  let earliestDate: Date | null = null;

  for (const current of sampleDates) {
    if (!current.startDate) {
      continue;
    }
    if (!earliestDate || current.startDate < earliestDate) {
      earliestDate = current.startDate;
    }
  }

  return earliestDate;
};
