import store from 'configureStore';
import { itinerariesItemDataSelector } from 'store/itinerariesItem/selectors';
import api from 'utils/axios';

import { getAccountId } from './auth';

export function patchShareReport(data: { error: any }) {
  const accountId = getAccountId();
  const itinerary = itinerariesItemDataSelector(store.getState());

  return api.post(`/acc/${accountId}/itineraries/${itinerary.id}/share_report`, { error: { data } });
}
