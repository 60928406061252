import React from 'react';

import classes from 'classnames';
import { prop } from 'ramda';

import Text from '../Text';
import styles from './index.module.css';

type FormTitleProps = {
  className?: string;
  children: React.ReactNode;
  type?: 'primary' | 'secondary' | 'tertiary' | 'forth';
  size?: 'small' | 'medium' | 'large';
  hint?: string;
  center?: boolean;
  noBorder?: boolean;
  rightText?: string;
  hintBottom?: string;
  bracketsText?: string;
  withAction?: boolean;
  duplicateWrapperStylesForTitle?: boolean;
  button?: React.ReactNode;
  noBorderWithMargin?: boolean;
  onClick?: () => void;
  mainClassName?: string;
};

function FormTitle(props: FormTitleProps) {
  const {
    className,
    children,
    type,
    size,
    hint,
    center,
    noBorder,
    rightText,
    hintBottom,
    bracketsText,
    withAction,
    button,
    noBorderWithMargin,
    onClick,
    mainClassName,
    duplicateWrapperStylesForTitle = true,
    ...rest
  } = props;

  const wrapperClasses = classes(
    styles.wrapper,
    center && prop('wrapper-center', styles),
    noBorder && prop('wrapper-noBorder', styles),
    noBorderWithMargin && prop('wrapper-noBorder-with-margin', styles),
    hintBottom && prop('wrapper-hintBottom', styles),
    onClick && prop('wrapper-clickable', styles),
    className,
    'custom-typography-form-title-wrapper',
    center && 'custom-typography-form-title-wrapper-center',
    noBorder && 'custom-typography-form-title-wrapper-noBorder',
  );

  const formTitleClasses = classes(
    styles.formTitle,
    size && prop(`formTitle-${size}`, styles),
    type && prop(`formTitle-${type}`, styles),
    withAction && prop('formTitleWithAction', styles),
    rightText && prop('formTitleWithrightText', styles),
    duplicateWrapperStylesForTitle && className,
    'custom-typography-form-title',
    size && `custom-typography-form-title-size-${size}`,
    type && `custom-typography-form-title-type-${type}`,
    rightText && 'custom-typography-form-title-right',
  );

  const hintClasses = classes(
    styles.hint,
    styles.hintBottom,
    center && styles.hintBottomCenter,
    'custom-typography-form-title-hint',
    center && 'custom-typography-form-title-hint-center',
  );

  return (
    <>
      <div className={wrapperClasses} onClick={onClick}>
        <div className={formTitleClasses} {...rest}>
          <span className={classes(styles.main, mainClassName && mainClassName, 'custom-typography-form-title-main')}>
            {children}
          </span>

          {bracketsText && (
            <span className={classes(styles.brackets, 'custom-typography-form-title-brackets')}>{bracketsText}</span>
          )}
          {withAction && button}
          {rightText && (
            <span className={classes(styles.rightText, 'custom-typography-form-title-right-text')}>{rightText}</span>
          )}
        </div>
        {hint && (
          <Text accent className={classes(styles.hint, 'custom-typography-form-title-hint')}>
            {hint}
          </Text>
        )}
      </div>
      {hintBottom && (
        <Text accent className={hintClasses}>
          {hintBottom}
        </Text>
      )}
    </>
  );
}

export default FormTitle;
