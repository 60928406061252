import { useSelector } from 'react-redux';

import { userNameSelector } from 'store/user/selectors';

import mockData from './mockData';

export default {
  pageTypeContextProps: {
    leftSubheadline: 'A Personal Touch',
    leftHeadline: 'About Us',
    headlineHint: 'Don’t worry, this can be edited within an itinerary.',
    subheadlineEditable: true,
  },
  useMockData: (page) => {
    const userName = useSelector(userNameSelector);

    return mockData(page, userName);
  },
};
