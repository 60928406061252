// import testImgUrl from 'components/shared/images/basePreview.jpg';

const mockData = (page) => ({
  title: page.name || 'Area',
  // primaryImage: testImgUrl,
  blocks: [
    { type: 'header', data: { subheadline: 'Area Overview' } },
    {
      type: 'text',
      data: {
        placeholder:
          'Click in this text block to write your own content. Tell your guests what they have to look forward to when visiting this particular area. You should give them a good sense about what they can expect. There is a character limit on this text block to ensure that the text is split up with images and videos which studies show is much more effective than long blocks of text. You can continue your text beneath the media block and in the other sections.',
      },
    },
    {
      type: 'image',
      data: {},
    },
    {
      type: 'text',
      data: {
        placeholder:
          'Click in this text block to write your own content. Tell your guests what they have to look forward to when visiting this particular area. You should give them a good sense about what they can expect. There is a character limit on this text block; you can add another text block if you absolutely must but we encourage you to keep it concise.',
      },
    },
  ],
});

export default mockData;
