import React, { FC, useCallback, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import CheckBox from 'components/shared/FormAdapters/FormDefault/CheckBox';
import FormGroup from 'components/shared/FormAdapters/FormDefault/FormGroup/Base';
import FormTitle from 'components/shared/Typography/FormTitle';
import { accountIdSelector, isOwnerOrSuperSelector } from 'store/user/selectors';
import axiosApi from 'utils/axios';

import { oneOf } from '../../../utils';
import s from './index.module.css';
import { IDocumentSettingsProps, IProperty } from './types';

export const DocumentSettings: FC<IDocumentSettingsProps> = ({ asset, data, onUpdate }) => {
  const [state, setState] = useState<IProperty[] | null>(null);
  const accountId = useSelector(accountIdSelector);
  const isOwnerOrSuper = useSelector(isOwnerOrSuperSelector);

  const allProperties = oneOf(data.allProperties, asset.allProperties);
  const propertyIds = oneOf(data.propertyIds, asset.propertyIds);

  useEffect(() => {
    if (!isOwnerOrSuper) return;
    if (asset.accountId && asset.accountId !== accountId) return;

    (async () => {
      // Temporary added limit is 1000. Need to remove it before start to add pagination.
      const payload = (await axiosApi.get(`/acc/${accountId}/properties?limit=1000`)).data?.data || [];
      setState(payload);
    })();
  }, [accountId, asset.accountId, isOwnerOrSuper]);

  const onSelectAll = useCallback(() => {
    onUpdate({
      allProperties: !allProperties,
      propertyIds: !allProperties ? state!.map((prop) => prop.id) : [],
    });
  }, [allProperties, onUpdate, state]);

  const onSelect = useCallback(
    (id: number) => {
      let res;

      if (allProperties) {
        res = new Set(state!.map((prop) => prop.id));
      } else {
        res = new Set(propertyIds);
      }

      if (res.has(id)) {
        res.delete(id);
      } else {
        res.add(id);
      }

      onUpdate({
        allProperties: false,
        propertyIds: [...res] as number[],
      });
    },
    [allProperties, onUpdate, propertyIds, state],
  );

  if (state === null) {
    return null;
  }

  const hint = (
    <span>
      Is this document relevant to other properties you manage? Select properties below to attach this document to and
      make it easy for Agents to find it when they need it.
    </span>
  );

  return (
    <FormGroup>
      {/*@ts-ignore*/}
      <FormTitle hint={hint}>Attach to Properties</FormTitle>
      <div className={s.container}>
        <div className={s.wrapper}>
          <CheckBox
            checked={Boolean(allProperties)}
            children={<b>Attach to all properties</b>}
            onChange={() => onSelectAll()}
          />
        </div>
        {state.map((property) => {
          return (
            <div className={s.wrapper} key={property.id}>
              <CheckBox
                checked={Boolean(allProperties || propertyIds?.includes(property.id))}
                children={
                  <div title={property.name} className={s.children}>
                    {property.name}
                  </div>
                }
                onChange={() => onSelect(property.id)}
              />
            </div>
          );
        })}
      </div>
    </FormGroup>
  );
};

export default DocumentSettings;
