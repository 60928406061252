export function calculateDimensionsByRatio(
  srcWidth: number,
  srcHeight: number,
  maxWidth: number = srcWidth,
  maxHeight: number = srcHeight,
) {
  const minRatio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
  // we don't want to enlarge, shrink only
  const ratio = minRatio > 1 ? 1 : minRatio;

  return { width: srcWidth * ratio, height: srcHeight * ratio };
}
