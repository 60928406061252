export const VIEWED_MOBILE_APP_INFO = 'viewedMobileAppInfo';
// FIXME: Add real link to App Store when the beta test will be opened
export const APP_STORE_LINK = 'https://apps.apple.com/app/6450455051';
export const GOOGLE_PLAY_LINK = 'https://play.google.com/store/apps/details?id=com.safari.travelportal';
export const MOBILE_APP_URL = 'travelportal://';

export const MOBILE_APP_LINK_PATTERNS: { [key: string]: string } = {
  lookbook: 'lookbooks',
  itinerary: 'itineraries',
  guest_portal: 'guest-portals',
};

export const MOBILE_OPERATING_SYSTEMS = {
  android: 'Android',
  ios: 'iOS',
};
