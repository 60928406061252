import React from 'react';

export const useSetTabIndex = (
  elementRef: React.RefObject<HTMLElement>,
  selectors: string[] = [],
  tabIndex: number = 0,
  interval: number = 0,
): void => {
  React.useEffect(() => {
    if (!elementRef.current || !selectors.length) return;
    let intervalId: NodeJS.Timeout;
    const element = elementRef.current;

    if (interval) {
      intervalId = setInterval(() => {
        setTabIndexToChildren(element, selectors, tabIndex);
      }, interval);
    } else {
      setTabIndexToChildren(element, selectors, tabIndex);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [elementRef, selectors, tabIndex]);
};

const setTabIndexToChildren = (element: HTMLElement, selectors: string[] = [], tabIndex: number = 0) => {
  selectors.forEach((selector) => {
    if (!(element instanceof HTMLElement)) return;
    const matchingElements = element?.querySelectorAll<HTMLElement>(selector);

    matchingElements?.forEach((child) => {
      child.setAttribute('tabindex', tabIndex.toString());
    });
  });
};
