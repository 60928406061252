export function scrollIfNeeded(element, container) {
  if (element.offsetTop < container.scrollTop) {
    container.scrollTop = element.offsetTop;
  } else {
    const offsetBottom = element.offsetTop + element.offsetHeight;
    const scrollBottom = container.scrollTop + container.offsetHeight;
    if (offsetBottom > scrollBottom) {
      container.scrollTop = offsetBottom - container.offsetHeight;
    }
  }
}

export const timeReg = /(\d{2}):(\d{2})(AM|PM)/;

export const getTime24HoursFormat = (hours, ampm) => {
  if (!hours) return;
  let res = hours;
  if (ampm === 'AM' && hours === '12') {
    res = '00';
  } else if (ampm === 'PM') {
    res = (hours % 12) + 12;
  }
  return res;
};
