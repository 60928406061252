import React, { FC } from 'react';

import { useSelector } from 'react-redux';

import Checkbox from 'components/shared/FormAdapters/FormDefault/CheckBox';
import FormGroup from 'components/shared/FormAdapters/FormDefault/FormGroup/Base';
import FormTitle from 'components/shared/Typography/FormTitle';
import Text from 'components/shared/Typography/Text';
import { isLoginAsSelector } from 'store/auth/selectors';
import { isSuperAdminSelector } from 'store/user/selectors';

import { oneOf } from '../../../utils';
import s from './index.module.css';
import { IMediaSettings } from './types';

const MediaSettings: FC<IMediaSettings> = ({ asset, data, onUpdate }) => {
  const isSuperAdmin = useSelector(isSuperAdminSelector);
  const isLoginAs = useSelector(isLoginAsSelector);

  if (!isSuperAdmin && !isLoginAs) return null;

  const publiclyAvailable = oneOf(data.publiclyAvailable, asset.publiclyAvailable);
  const primary = oneOf(data.primary, asset.primary);

  return (
    <>
      <FormGroup>
        {/*@ts-ignore*/}
        <FormTitle hint="Manage permissions for this media below.">Permissions</FormTitle>
        <div className={s.flex}>
          <div className={s.item}>
            <Checkbox
              checked={publiclyAvailable}
              onChange={() => onUpdate({ publiclyAvailable: !publiclyAvailable })}
              children={<Text>Public</Text>}
            />
          </div>
          <div className={s.item}>
            <Checkbox
              checked={primary}
              onChange={() => onUpdate({ primary: !primary })}
              children={<Text>Primary Photo</Text>}
            />
          </div>
        </div>
      </FormGroup>
    </>
  );
};

export default MediaSettings;
