import { ChangeEvent } from 'react';

// eslint-disable-next-line import/named
import { identity, memoizeWith, omit, pipe } from 'ramda';
import { getIn } from 'utils/ramda';
import { ICommonObject, IGetDistance } from 'utils/types';

import { TTripEvent } from '../detailsv2/structure';

export const getErrorTitle = getIn('errors.title');
export const getErrors = getIn('errors.source');

export const capitalize = (s: string | undefined | null) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const capitalizeAllWords = (s: string | undefined | null) => {
  if (typeof s !== 'string') return '';
  // return s.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1)); OLD not working with slavic words
  return s.replace(/\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1));
};

export const capitalizeAllWordsForInput = (event: ChangeEvent<HTMLInputElement>) => {
  let p = event.target.selectionStart;
  event.target.value = capitalizeAllWords(event.target.value);
  event.target.setSelectionRange(p, p);
};

export const removeSpaces = (s: string | undefined | null) => {
  if (typeof s !== 'string') return '';
  return s.replace(/\s/g, '');
};

export const generateHandleString = (pageNameValue: string, tagsValue?: string) => {
  const formatString = (enteredValue?: string) => pipe(capitalizeAllWords, removeSpaces)(enteredValue);
  return `${formatString(pageNameValue)}${formatString(tagsValue)}`;
};

export function isChildOfParent(el: Node, parent: Node): boolean {
  if (el === parent) {
    return true;
  }

  if (!el.parentNode) {
    return false;
  }

  return isChildOfParent(el.parentNode, parent);
}

export function getCoordsString(lat: string, lng: string) {
  if (!lat && !lng) return '';

  return [lat, lng].join(', ');
}

export function copyTextToClipboard(text: string) {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();

  if (navigator.userAgent.match(/ipad|iphone/i)) {
    const range = document.createRange();
    range.selectNodeContents(textArea);
    const selection = window.getSelection();
    selection && selection.removeAllRanges();
    selection && selection.addRange(range);
    textArea.setSelectionRange(0, 999999);
  } else {
    textArea.select();
  }

  let isCopied = false;

  try {
    isCopied = document.execCommand('copy');
  } catch (err) {
    window.console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);

  return isCopied;
}

export function getQueryParam(key: string) {
  const { search } = window.location;
  const params = new URLSearchParams(search);

  return params.get(key);
}

export function parseHEXColor(value: string) {
  return value ? '#' + value.replaceAll('#', '') : null;
}

export function makeId(length: number, { numbers = true, letters = true } = {}) {
  const result = [];
  const characters = `${letters ? 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz' : ''}${numbers ? '0123456789' : ''}`;
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
  }
  return result.join('');
}

// todo: replace other getErrorsMap functions with this
export function getErrorsMap(source = {} as ICommonObject<string>) {
  return Object.keys(source).reduce((res, key) => {
    res[key] = source[key][0];

    return res;
  }, {} as ICommonObject<string>);
}

// http://www.movable-type.co.uk/scripts/latlong.html

export function getDistance(p1: IGetDistance, p2: IGetDistance) {
  const R = 6371000; // Radius of the Earth in m
  const rLat1 = p1.lat * (Math.PI / 180); // Convert degrees to radians
  const rLat2 = p2.lat * (Math.PI / 180); // Convert degrees to radians
  const diffLat = rLat2 - rLat1; // Radian difference (latitudes)
  const diffLon = (p2.lng - p1.lng) * (Math.PI / 180); // Radian difference (longitudes)
  const a =
    Math.sin(diffLat / 2) * Math.sin(diffLat / 2) +
    Math.cos(rLat1) * Math.cos(rLat2) * Math.sin(diffLon / 2) * Math.sin(diffLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c;
}

//@ts-ignore
export const memoize = memoizeWith(identity);

export function saveFile(file: File, name: string) {
  const url = URL.createObjectURL(file);
  const a = document.createElement('a');
  a.target = '_blank';
  a.download = name;
  a.href = url;

  document.body.appendChild(a);

  a.click();

  document.body.removeChild(a);
}

export function uuidv4() {
  //@ts-ignore
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
  );
}

export function waitForElm(selector: string) {
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });
  });
}

export const getBrowser = () => {
  var ua = navigator.userAgent,
    tem,
    M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: 'IE', version: tem[1] || '' };
  }

  if (M[1] === 'Chrome') {
    tem = ua.match(/\bOPR|Edge\/(\d+)/);
    if (tem != null) {
      return { name: 'Opera', version: tem[1] };
    }
  }

  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];

  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1]);
  }

  return { name: M[0], version: M[1] };
};

export function parseDate(dateString: string) {
  return new Date(dateString);
}

export const removeSpecialControlSymbols = (text: string) => text.replace(/(?!\n)[\x00-\x1F]+/g, '');

export const removeLSEPSymbols = (text: string) => text.replace(/\u2028/g, '');

export const formatWysiwygText = pipe(removeSpecialControlSymbols, removeLSEPSymbols);

export const reverseFlightDateValues = <T extends TTripEvent>(t: T, isIDLNeeded: boolean = false): T => {
  const data = isIDLNeeded ? { ...t.data, isIDL: true } : omit(['isIDL'], t.data);
  return {
    ...t,
    data,
    datesRange: {
      start: t.datesRange.end,
      end: t.datesRange.start,
    },
    daysRange: {
      start: t.daysRange.end,
      end: t.daysRange.start,
    },
  };
};
