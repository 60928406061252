export const symbol = Symbol();

export const wrapper = (gen) => {
  const w = (...args) => gen(...args);
  w[symbol] = true;
  return w;
};

export const singleYieldWrapper = (fn) => {
  return wrapper(function* (dispatch, getState) {
    yield (action) => fn(dispatch, getState, action);
  });
};
