import { matchPath } from 'react-router';

import { ACC_ID, AUTH_PATH } from 'constants/routes';
// eslint-disable-next-line import/named
import { isNil } from 'ramda';

export const DOMAINS = {
  ACCOUNT: 'account',
  ITINERARIES: 'itineraries',
  GUEST: 'guest',
  TRAVEL: 'travel',
  CUSTOM_DOMAIN: 'custom_domain',
  FILES: 'files',
  PREVIEW: 'preview',
};

const isLocalDomain = '127.0.0.1' === window.location.hostname;
const isBasedOnLocalDomain = /\.?localhost$/.test(window.location.hostname);
const isJustLocalhost = window.location.hostname === 'localhost';
const isBasedOnSafariPortalDomain = /\.?safariportal.app$/.test(window.location.hostname);
const isOurDomain = isLocalDomain || isBasedOnLocalDomain || isBasedOnSafariPortalDomain;

export const getDomain = () => {
  if (!isOurDomain) {
    return DOMAINS.CUSTOM_DOMAIN;
  }

  if (window.location.host.startsWith('account.') || isJustLocalhost) {
    return DOMAINS.ACCOUNT;
  }

  if (window.location.host.startsWith('travel.')) {
    return DOMAINS.TRAVEL;
  }

  if (window.location.host.startsWith('itineraries.')) {
    return DOMAINS.ITINERARIES;
  }

  if (window.location.host.startsWith('guest.')) {
    return DOMAINS.GUEST;
  }

  if (window.location.host.startsWith('files.')) {
    return DOMAINS.FILES;
  }

  if (window.location.host.startsWith('preview.')) {
    return DOMAINS.PREVIEW;
  }

  return null;
};

export function getAppHostByEnv() {
  switch (process.env.REACT_APP_ENV) {
    case 'development':
      return window.location.host;
    case 'staging':
      return 'staging.safariportal.app';
    default:
      return 'safariportal.app';
  }
}

export const isAccountAuthRoute = () => {
  const match = matchPath(window.location.pathname, {
    path: AUTH_PATH,
  });

  return !!match;
};

export const isAccountAccWithIdRoute = () => {
  const match = matchPath(window.location.pathname, {
    path: ACC_ID,
  });

  return !!match;
};

export const extractAccountIdFromURL = () => {
  const match = matchPath(window.location.pathname, {
    path: ACC_ID,
  });

  if (isNil(match?.params)) {
    return null;
  }

  return match.params.accountId;
};

export const SHARED_DOMAIN_ROOT_PATH = (isGPCustomDomain() ? '/gp' : '') + '/:title/:itemId';
export const extractItemIdOnSharedDomain = () => {
  const match = matchPath(window.location.pathname, {
    path: SHARED_DOMAIN_ROOT_PATH,
  });

  if (isNil(match?.params)) {
    return null;
  }

  return match.params.itemId;
};

export function isGPCustomDomain() {
  const domain = getDomain();

  return domain === DOMAINS.CUSTOM_DOMAIN && window.location.pathname.startsWith('/gp/');
}
