import React from 'react';

import cx from 'classnames';

import Icon from '../Icon';
import CheckCircle from '../Icon/icons/CheckCircle';
import Close from '../Icon/icons/Close';
import Text from '../Typography/Text';
import s from './index.module.css';

function Snackbar({ type = SNACKBAR_SKIN.DEFAULT, body, onHide, fitContent, hideCloseButton = false }) {
  const strokeColor = () => {
    switch (type) {
      case 'default':
        return '#c4c4c4';
      case 'error':
        return '#fff';
      case 'success':
        return '#fff';
      case 'warning':
        return '#3e3e40';
    }
  };
  const iconSize = type === SNACKBAR_SKIN.WARNING || type === SNACKBAR_SKIN.SUCCESS ? '2.4rem' : '1.5rem';

  return (
    <div className={cx(s.wrapper, s[type], fitContent && s.fitContent, hideCloseButton && s.commonRightPadding)}>
      {!hideCloseButton && (
        <Icon
          component={Close}
          width={iconSize}
          stroke={strokeColor}
          height={iconSize}
          fill="unset"
          className={s.icon}
          onClick={onHide}
        />
      )}

      {type === SNACKBAR_SKIN.SUCCESS && (
        <Icon component={CheckCircle} width={iconSize} height={iconSize} className={s.successIcon} />
      )}

      <Text accent className={s.text} id="snackbarContent">
        {body}
      </Text>
    </div>
  );
}

export default Snackbar;

export const SNACKBAR_SKIN = {
  DEFAULT: 'default',
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'success',
};
