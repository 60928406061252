import { FC } from 'react';

import cx from 'classnames';

import Icon from 'components/shared/Icon';
import Close from 'components/shared/Icon/icons/Close';

import { FormFieldWrapper } from '..';
import s from '../index.module.css';
import { ETimePeriod } from './constants';
import { useTimeInput } from './functions';
import styles from './index.module.css';
import { IBaseTimeInputProps } from './types';
import { getAnotherTimeSystem, getInputWidth } from './utils';

export const BaseTimeInput: FC<IBaseTimeInputProps> = ({
  value,
  onChange,
  onBlur,
  label,
  errorMessage,
  helpText = '',
}) => {
  const {
    focusInput,
    contentRef,
    timeValue,
    colonRemoveHandler,
    timeInputHandler,
    isValid,
    activeTimePeriod,
    setActiveTimePeriod,
  } = useTimeInput({ value, onChange });

  return (
    <div className={s.wrapper} onClick={focusInput}>
      <FormFieldWrapper helpText={helpText} errorMessage={errorMessage}>
        <div className={cx(styles.timeWrapper, { [styles.errorBorder]: errorMessage })}>
          <input
            //@ts-ignore
            ref={contentRef}
            style={{ width: getInputWidth(timeValue) }}
            type="text"
            value={timeValue}
            //@ts-ignore
            onKeyDown={colonRemoveHandler}
            onChange={timeInputHandler}
            onBlur={onBlur}
            className={styles.input}
          />
          {label && <label className={cx({ [styles.filled]: value })}>{label}</label>}
          {isValid && <div className={styles.timeString}>{getAnotherTimeSystem(timeValue, activeTimePeriod)}</div>}
          <div style={{ display: value && 'flex' }} className={styles.timePeriod}>
            {value && <Icon className={styles.clearButton} component={Close} onClick={() => onChange('')} />}
            <div className={styles.timePeriodOptions}>
              <span
                className={cx(activeTimePeriod === ETimePeriod.AM && styles.activeOption)}
                onClick={() => setActiveTimePeriod(ETimePeriod.AM)}
              >
                {ETimePeriod.AM}
              </span>
              <span
                className={cx(activeTimePeriod === ETimePeriod.PM && styles.activeOption)}
                onClick={() => setActiveTimePeriod(ETimePeriod.PM)}
              >
                {ETimePeriod.PM}
              </span>
            </div>
          </div>
        </div>
      </FormFieldWrapper>
    </div>
  );
};
