import { useMemo, useState } from 'react';

import { debounce } from 'utils/debounce';

export function useDebounce(time: number = 500) {
  const [value, setValue] = useState<string>('');
  const [saved, setSaved] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const debounced = useMemo(() => {
    const fn = debounce((val: string) => {
      return setSaved(val);
    }, time);

    return (val: string) => {
      setValue(val);
      setLoading(true);
      if (val.length === 0) {
        setLoading(false);
      }
      fn(val);
    };
  }, []);

  return [value, saved, debounced, loading, setLoading] as const;
}
