import { connectRouter } from 'connected-react-router';

import adminReducer from './admin';
import appReducer from './app';
import assetsReducer from './assets';
import authReducer from './auth';
import itinerariesItemReducer from './itinerariesItem';
import itineraryDetailsReducer from './itineraryDetails';
import modalReducer from './modal';
import pageReducer from './page';
import privacyPolicyReducer from './privacyPolicy';
import termsOfUseReducer from './terms_of_use';
import translateReducer from './translate';
import unsavedChangesReducer from './unsavedChanges';
import userReducer from './user';

const rootReducer = (history) => ({
  router: connectRouter(history),
  assets: assetsReducer,
  auth: authReducer,
  user: userReducer,
  admin: adminReducer,
  modal: modalReducer,
  app: appReducer,
  page: pageReducer,
  itinerariesItem: itinerariesItemReducer,
  itineraryDetails: itineraryDetailsReducer,
  translate: translateReducer,
  unsavedChanges: unsavedChangesReducer,
  termsOfUse: termsOfUseReducer,
  privacyPolicy: privacyPolicyReducer,
});

export default rootReducer;
