import { ReactNode } from 'react';

import { ErrorBoundary as AppSignalErrorBoundary } from '@appsignal/react';

import ErrorScreen from 'components/shared/ErrorScreen';
import appsignal from 'utils/appsignal';

import { getDomain } from '../../../utils/domain';

const ErrorBoundary = ({ children }: { children: ReactNode }) => {
  const domain = getDomain();
  const tags = { domain };

  return (
    /*@ts-ignore*/
    <AppSignalErrorBoundary instance={appsignal} tags={tags} fallback={() => <ErrorScreen status="crashed" />}>
      {children}
    </AppSignalErrorBoundary>
  );
};

export default ErrorBoundary;
