import ErrorScreen from '../ErrorScreen';

const EnableCookie = () => {
  return (
    /*@ts-ignore ErrorScreen has status everywhere but here */
    <ErrorScreen
      customTitle="Your cookies are disabled"
      customHint={
        <>
          This website uses cookies. Please enable cookies or re-open this website in a cookie-enabled browser. <br />
          <br />
          Need help?{' '}
          <a href="https://help.safariportal.app/hc/en-us/articles/6952024285335" target="_blank" rel="noreferrer">
            Click here for instructions
          </a>
          <br />
          on how to enable cookies.
        </>
      }
      noButton
    />
  );
};

export default EnableCookie;
