import Axios from 'axios';
import { isNil } from 'ramda';
import api from 'utils/axios';

/**
 * Returns wrapped axios.get that autocancels the previous request if it's still running at the momemnt when a new request is send
 */
export const axiosGetAutoCancelDecorator = () => {
  let cancelToken;

  return (url, params = {}) => {
    if (!isNil(cancelToken)) {
      cancelToken.cancel('Operation canceled due to new request.');
    }

    cancelToken = Axios.CancelToken.source();

    return api.get(url, { ...params, cancelToken: cancelToken.token });
  };
};
