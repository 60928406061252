import { createSlice } from '@reduxjs/toolkit';
import { isNil } from 'ramda';

import { ITINERARIES_ITEM_TYPES } from 'constants/index';
import { immutableRowUpdate, immutableRowRemove } from 'utils/immutableFunctions';
import isNoData from 'utils/isNoData';

export const ITINERARIES_ITEM_TYPE_TO_KEY_MAP = {
  [ITINERARIES_ITEM_TYPES.LOOKBOOK]: 'lookbooks',
  [ITINERARIES_ITEM_TYPES.ITINERARY]: 'itineraries',
  [ITINERARIES_ITEM_TYPES.GUEST_PORTAL]: 'guestPortals',
};

const initialState = {
  list: [],
  events: [],
  meta: {
    totalCount: 0,
    totalPages: 0,
  },
  searchResultCounters: {},
  isLoading: false,
};

const slice = createSlice({
  name: 'tourRequests',
  initialState,
  reducers: {
    setTourRequestsAction(state, action) {
      const { data, meta } = action.payload;
      state.list = getPreparedList(data);
      state.meta = meta;
    },
    addTourRequestsAction(state, action) {
      state.list = getPreparedList([action.payload, ...state.list]);
    },
    resetTourRequestsAction: () => initialState,
    updateTourRequestAction(state, action) {
      state.list = getPreparedList(immutableRowUpdate(state.list, action.payload));
    },
    updateFileNameAction(state, action) {
      state.list = getPreparedList(immutableRowUpdate(state.list, action.payload));
    },
    deleteTourRequestAction(state, action) {
      state.list = getPreparedList(immutableRowRemove(state.list, action.payload));
    },
    updateTourRequestRecentEventAction(state, action) {
      const { id, recentEvent, itinerariesItemType } = action.payload;
      const key = ITINERARIES_ITEM_TYPE_TO_KEY_MAP[itinerariesItemType];

      const tourRequestIndex = state.list.findIndex((t) => t[key]?.map((it) => it.id).includes(id));

      if (tourRequestIndex !== -1) {
        state.list[tourRequestIndex].recentEvent = recentEvent;
      }
    },
    setSearchResultCountersAction(state, action) {
      state.searchResultCounters = action.payload;
    },
    resetSearchResultCountersAction(state) {
      state.searchResultCounters = {};
    },
  },
});

export const {
  addTourRequestsAction,
  setTourRequestsAction,
  updateTourRequestAction,
  updateFileNameAction,
  deleteTourRequestAction,
  resetTourRequestsAction,
  updateTourRequestRecentEventAction,
  setSearchResultCountersAction,
  resetSearchResultCountersAction,
} = slice.actions;

export default slice.reducer;

function getPreparedList(tourRequests) {
  if (isNoData(tourRequests)) return [];

  return tourRequests.map((tourRequest) => {
    const itineraries = sortItems(tourRequest.itineraries ?? []).reduce((acc, itinerary) => {
      const newItinerary = {
        ...itinerary,
        type: ITINERARIES_ITEM_TYPES.ITINERARY,
      };

      if (itinerary.confirmed && !isNil(tourRequest.guestPortals?.[0])) {
        return [...acc, newItinerary, ...tourRequest.guestPortals];
      }

      return [...acc, newItinerary];
    }, []);

    const lookbooks = sortItems(tourRequest.lookbooks ?? []).map((lookbook) => ({
      ...lookbook,
      type: ITINERARIES_ITEM_TYPES.LOOKBOOK,
    }));

    return { ...tourRequest, proposals: [...itineraries, ...lookbooks] };
  });
}

const sortItems = (items, sortBy = 'desc') => {
  return items.slice().sort((a, b) => {
    if (new Date(a.updatedAt) > new Date(b.updatedAt)) return sortBy === 'desc' ? -1 : 1;
    if (new Date(a.updatedAt) < new Date(b.updatedAt)) return sortBy === 'desc' ? 1 : -1;

    return 0;
  });
};
