import { useCallback } from 'react';

import { convertToRaw } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { useSelector } from 'react-redux';
import { useLocalStorage } from 'usehooks-ts';

import { confirmAlert as renderConfirmAlert } from 'components/Pages/Admin/Itinerary/shared/ConfirmAlert';
import { SNACKBAR_SKIN } from 'components/shared/Snackbar';
import {
  defaultLandingPageTypes,
  ITINERARIES_ITEM_TYPES,
  LANDING_PAGE_ROLES,
  LANDING_PAGE_TYPES,
  LIBRARY_CONTENT,
  PAGE_TYPE_SAMPLE_ITINERARIES,
  PAGE_TYPE_SAMPLE_LOOKBOOK,
} from 'constants/index';
// eslint-disable-next-line import/named
import { showSnackbar } from 'hooks/useSnackbar';
import { any, concat, defaultTo, difference, filter, flip, includes, pipe, propEq, propOr, replace } from 'ramda';
import { itinerariesItemDataSelector } from 'store/itinerariesItem/selectors';
import { userSelector } from 'store/user/selectors';
import isNoData from 'utils/isNoData';
import { toSlug } from 'utils/urlHelpers';

import { confirmAlert } from '../../alert';
import { getAppHostByEnv } from '../../domain';

export const isValidItinerariesItemType = (itemType) => Object.values(ITINERARIES_ITEM_TYPES).includes(itemType);

const kebabToSnakeCase = (str) => str.split('-').join('_');
export const mapPathSegment2ItemType = (pathSegment = '') => {
  const itemType = kebabToSnakeCase(pathSegment);

  return isValidItinerariesItemType(itemType) ? itemType : null;
};

const snakeToKebabCase = (str) => str.split('_').join('-');
export const mapItemType2PathSegment = (itemType) => {
  return isValidItinerariesItemType(itemType) ? snakeToKebabCase(itemType) : null;
};

function getSharingLink({ entity, query = '', useAppDomain, domainPrefix, path }) {
  const handle = entity?.preparedFor || entity?.title || '';
  const host = useAppDomain ? getAppHostByEnv() : window.location.host;

  const getHost = replace(/(account|itineraries|guest)\./, '');
  const safeConcat = pipe(defaultTo(''), flip(concat));

  return pipe(
    safeConcat(window.location.protocol + '//'),
    safeConcat(domainPrefix + '.'),
    safeConcat(getHost(host) + '/'),
    safeConcat(toSlug(handle) + '/'),
    safeConcat(entity?.id),
    safeConcat(useAppDomain && path ? `/${path}` : ''),
    safeConcat(query),
  )('');
}

export const getSharingLinkByItinerariesItemType = (itemType, itinerariesItem, useAppDomain = false) => {
  const map = {
    [ITINERARIES_ITEM_TYPES.ITINERARY]: (entity) =>
      getSharingLink({ entity, useAppDomain, domainPrefix: 'itineraries' }),
    [ITINERARIES_ITEM_TYPES.LOOKBOOK]: (entity) =>
      getSharingLink({ entity, query: '?type=lookbook', useAppDomain, domainPrefix: 'itineraries' }),
    [ITINERARIES_ITEM_TYPES.GUEST_PORTAL]: (entity) =>
      getSharingLink({ entity, useAppDomain, domainPrefix: 'guest', path: `guest-portal/${entity?.id}` }),
    [ITINERARIES_ITEM_TYPES.SAMPLE]: (entity) =>
      getSharingLink({ entity, useAppDomain, domainPrefix: 'itineraries', query: '?type=sample' }),
    [ITINERARIES_ITEM_TYPES.SAMPLE_LOOKBOOK]: (entity) =>
      getSharingLink({ entity, useAppDomain, domainPrefix: 'itineraries', query: '?type=sample_lookbook' }),
  };

  return map[itemType](itinerariesItem);
};

export const getLangingPageType = (landingPage) => {
  if (landingPage.type === LANDING_PAGE_TYPES.MISC) {
    if (defaultLandingPageTypes.includes(landingPage.role)) {
      return landingPage.role;
    } else {
      return landingPage.type;
    }
  }

  return landingPage.type;
};

export const getGuestNameInfo = (guest = {}, { defaultName = 'No name' } = {}) => {
  const name = [guest.firstName, guest.lastName].filter(Boolean).join(' ');

  return {
    isEmpty: !name,
    name: name || defaultName,
  };
};

export const ITINERARIES_ITEM_TYPE_TO_ROUTE_SEGMENT_MAP = {
  [ITINERARIES_ITEM_TYPES.ITINERARY]: 'itineraries',
  [ITINERARIES_ITEM_TYPES.LOOKBOOK]: 'lookbooks',
  [ITINERARIES_ITEM_TYPES.GUEST_PORTAL]: 'guest_portals',
  [ITINERARIES_ITEM_TYPES.SAMPLE]: 'sample_itineraries',
  [ITINERARIES_ITEM_TYPES.SAMPLE_LOOKBOOK]: 'sample_lookbooks',
};

export const isSample = (libType) => libType === LIBRARY_CONTENT;
export const isItineraries = (contentType) => contentType === PAGE_TYPE_SAMPLE_ITINERARIES;
export const isLookbook = (contentType) => contentType === PAGE_TYPE_SAMPLE_LOOKBOOK;

const genBrandingInfoHTML = ({ companyName, contactPhone, email, messagePhone, skype, whatsapp }) => {
  const markup = [
    companyName && `<div><b>Your Agent: ${companyName}</b></div>`,
    contactPhone && `<div>Phone: ${contactPhone}</div>`,
    email && `<div>Email: ${email}</div>`,
    messagePhone && `<div>Mobile Phone: ${messagePhone}</div>`,
    whatsapp && `<div>WhatsApp: ${whatsapp}</div>`,
    skype && `<div>Skype: ${skype}</div>`,
  ]
    .filter(Boolean)
    .join('');

  return markup;
};

const genContactsHTML = (contacts = [], pageName) => {
  return contacts
    .map(({ email, phone, name, title }) => {
      const contactName = name || '';
      const isEmpty = isNoData([email, phone, contactName].filter(Boolean));
      if (isEmpty) {
        return null;
      }

      return [
        pageName && `<div><b>${pageName}:</b></div>`,
        title
          ? `<div>${title}: ${contactName}</div>`
          : contactName
            ? `<div>Emergency Contact: ${contactName}</div>`
            : null,
        phone && `<div>Phone number: ${phone}</div>`,
        email && `<div>Email: ${email}</div>`,
      ]
        .filter(Boolean)
        .join('');
    })
    .filter(Boolean)
    .join('<div></br></div>');
};

const textPlaceholder = { text: 'Please, add the contacts' };
export const getEmergencyContactsBlocks = (brandSettings, emergencyContacts) => {
  const brandingHTML = isNoData(brandSettings) ? null : genBrandingInfoHTML(brandSettings);
  const emergencyContactsHTML = isNoData(emergencyContacts)
    ? null
    : emergencyContacts.map(({ contacts, pageName }) => genContactsHTML(contacts, pageName)).join('<div></br></div>');
  const resultHTML = [brandingHTML, emergencyContactsHTML].filter(Boolean).join('<div></br></div>');

  return [
    {
      type: 'subtitle',
      data: { text: 'Emergency contacts', placeholder: 'Emergency contacts' },
    },
    {
      type: 'text',
      withoutRemoving: true,
      data: {
        raw: convertToRaw(stateFromHTML(resultHTML)),
        placeholder: textPlaceholder,
      },
    },
  ];
};

export const NOMAD_THEME_HEADER_HEIGHT = 94;
export const NOMAD_THEME_MOBILE_HEADER_HEIGHT = 107;
export const JBD_THEME_HEADER_HEIGHT = 94;
export const JBD_THEME_MOBILE_HEADER_HEIGHT = 107;
export const BOATS_THEME_COVER_LEFT_ID = 'boats-theme-cover-left';

export const LANDING_THEMES = {
  default: 'default',
  nomad: 'nomad',
  jbd: 'jbd',
  boats: 'boats',
};

export function getLandingTheme(itineraryItem) {
  if (itineraryItem?.customStyles?.startsWith('/*theme=nomad*/')) {
    return LANDING_THEMES.nomad;
  }

  if (itineraryItem?.customStyles?.startsWith('/*theme=jbd*/')) {
    return LANDING_THEMES.jbd;
  }

  if (itineraryItem?.customStyles?.startsWith('/*theme=boats*/')) {
    return LANDING_THEMES.boats;
  }

  return LANDING_THEMES.default;
}

export function hasAnyManualPrimaryImage(landing) {
  const { COVER_PAGE, SAFARI_COSTS, SAFARI_MAP, SAFARI_OVERVIEW } = LANDING_PAGE_ROLES;
  const defaultRoles = [COVER_PAGE, SAFARI_COSTS, SAFARI_MAP, SAFARI_OVERVIEW];
  const getPages = propOr([], 'pages');
  const filterThemByDefaultRole = filter(pipe(propOr('', 'role'), flip(includes)(defaultRoles)));
  const anyHasManualPrimaryImage = any(propEq(true, 'primaryImageManual'));

  return pipe(getPages, filterThemByDefaultRole, anyHasManualPrimaryImage)(landing);
}

export function useTryToRemindCheckPrimaryImage({
  neededLastAvailableStep,
  itineraryItemName,
  initialAreaPropertyPageIds,
  getCurrentAreaPropertyPageIds,
}) {
  const itineraryItem = useSelector(itinerariesItemDataSelector);

  const tryToRemindCheckPrimaryImages = useCallback(
    (savedItem) => {
      if (
        itineraryItem.lastAvailableStep === neededLastAvailableStep &&
        hasAnyManualPrimaryImage(itineraryItem?.landing)
      ) {
        const currentAreaPropertyPageIds = getCurrentAreaPropertyPageIds(savedItem);
        const diff1 = difference(initialAreaPropertyPageIds, currentAreaPropertyPageIds);
        const diff2 = difference(currentAreaPropertyPageIds, initialAreaPropertyPageIds);

        if (diff1.length > 0 || diff2.length > 0) {
          confirmAlert({
            title: 'Heads up!',
            hint: `You’ve modified the ${itineraryItemName}. Remember to check that the primary photos you had previously selected are still relevant`,
            showCancel: false,
            confirmButtonText: 'Okay, got it',
          });
        }
      }
    },
    [
      itineraryItem,
      itineraryItemName,
      initialAreaPropertyPageIds,
      neededLastAvailableStep,
      getCurrentAreaPropertyPageIds,
    ],
  );

  return { tryToRemindCheckPrimaryImages };
}

export const checkPrimaryImageWarning = (savedItem) => {
  if (savedItem.showHeadsUpModal) {
    renderConfirmAlert({
      text: 'You’ve modified the itinerary. Remember to check that the primary photos you had previously selected are still relevant.',
    });
  }
};

const IGNORED_WARNING_KEY = 'IGNORED_WARNING_V2_KEY';
export const useIgnoreWarning = () => {
  const currentUserId = useSelector(userSelector).id;
  const [ignoredWarningMap, setIgnoredWarningMap] = useLocalStorage(IGNORED_WARNING_KEY, {});

  const getIgnoredEvents = () => {
    try {
      if (!currentUserId) throw new Error('Current user id is not defined');
      const ignoredEvents = ignoredWarningMap[currentUserId];
      if (!ignoredEvents) return [];
      return ignoredEvents;
    } catch (e) {
      return [];
    }
  };

  const checkIsEventsWarningIgnored = (tripEventsIdByDates) => {
    try {
      if (!currentUserId) throw new Error('Current user id is not defined');
      const ignoredEvents = ignoredWarningMap[currentUserId];
      if (!ignoredEvents) return false;

      const keys = Object.entries(tripEventsIdByDates).flatMap(([_, ids]) => ids.map((id) => id));
      const isAllEventsIgnored = keys.every((key) => ignoredEvents.includes(key));
      return isAllEventsIgnored;
    } catch (e) {
      console.error(e);
      showSnackbar({
        uniqueId: 'checkIsWarningIgnored',
        text: 'Something went wrong with warning ignoring. Please try again.',
        type: SNACKBAR_SKIN.ERROR,
      });
      return false;
    }
  };

  const ignoreWarning = (tripEventsIdByDates) => {
    try {
      if (!currentUserId) throw new Error('Current user id is not defined');

      let ignoredEvents = ignoredWarningMap[currentUserId];
      if (!ignoredEvents) {
        ignoredEvents = [];
      }
      const keys = Object.entries(tripEventsIdByDates).flatMap(([_, ids]) => ids);
      ignoredWarningMap[currentUserId] = [...ignoredEvents, ...keys];
      setIgnoredWarningMap(ignoredWarningMap);
    } catch (e) {
      console.error(e);
      showSnackbar({
        uniqueId: 'ignoreWarning',
        text: 'Something went wrong with warning ignoring. Please try again.',
        type: SNACKBAR_SKIN.ERROR,
      });
    }
  };

  return { getIgnoredEvents, checkIsEventsWarningIgnored, ignoreWarning };
};
