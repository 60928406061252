import { timeReg, getTime24HoursFormat } from 'components/shared/FormAdapters/FormDefault/TimePicker/utils';
import { formatRepresentationDate } from 'utils/date';

export const MANUALLY_FLIGHT = 'Flight';
export const MANUALLY_TRANSFER = 'Transfer';

export const types = [MANUALLY_FLIGHT, MANUALLY_TRANSFER];

export const getDateString = ({ date, time }) => {
  const dateFormat = formatRepresentationDate(date);
  if (!time) return dateFormat;
  const [, hours, minutes, ampm] = time.replaceAll(' ', '').split(timeReg);
  const hours24Format = getTime24HoursFormat(hours, ampm);
  const timeFormat = `${hours24Format}:${minutes}:00.000`;
  return `${dateFormat}T${timeFormat}`;
};
