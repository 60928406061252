import React, { ChangeEvent, useRef } from 'react';

import cx from 'classnames';
import { move } from 'ramda';
import { batch, useDispatch, useSelector } from 'react-redux';

import { useDebounce } from 'components/shared/AssetEditorForm/debounceHook';
import Slider from 'components/shared/AssetEditorForm/Slider';
import { useSuggestedOrSearch } from 'components/shared/AssetEditorForm/suggestedHook';
import Form from 'components/shared/Assets/shared/Form';
import Button from 'components/shared/Button';
import FileDropZone from 'components/shared/FileDropZone';
import FormActions from 'components/shared/FormAdapters/FormDefault/FormActions';
import FormGroup from 'components/shared/FormAdapters/FormDefault/FormGroup/Base';
import Icon from 'components/shared/Icon';
import Clipboard from 'components/shared/Icon/icons/Clipboard';
import Loading from 'components/shared/Icon/icons/Loading';
import Search from 'components/shared/Icon/icons/Search';
import {
  changeSettingsAction,
  closeModalAction,
  onDeleteAction,
  onEditAction,
  onSaveAction,
  onUploadAction,
} from 'store/assets';
import { settingsSelector } from 'store/assets/selectors';
import { ASSET_TYPES, ASSET_TYPE_TO_MIME_TYPES_MAP, MIME_TYPES, mapMime2AssetType } from 'utils/asset';

import Input from '../../FormAdapters/FormDefault/Input';
import Loader from '../../Loader';
import Modal from '../../Modal';
import Text from '../../Typography/Text';
import style from '../EditModal/index.module.css';
import { IErrors, IMedias, IUpdate, IDropZoneFiles } from '../shared/Form/types';
import { oneOf } from '../utils';
import styles from './index.module.css';
import { useClipboardImagePaste } from './useClipboardImagePaste';

const ASSET_TYPE_TO_LABEL_MAP = {
  [ASSET_TYPES.MEDIA]: 'Photo',
  [ASSET_TYPES.DOCUMENT]: 'Document',
  [ASSET_TYPES.FILE]: 'File',
};

const searchOrSuggestedResultText = (
  length: number | undefined,
  isSearch: boolean | undefined,
  searchValue: string,
  pageName: string,
  assetType: string,
) => {
  const part1 = length + ' ';
  const part2 = isSearch ? 'Search Result' : `Suggested ${ASSET_TYPE_TO_LABEL_MAP[assetType]}`;
  const part3 = length === 1 ? '' : 's';
  const part4Search = !searchValue ? null : (
    <>
      {' '}
      for <span className={styles.searchValue}>{`‘${searchValue}’`}</span>
    </>
  );
  const part4Suggested = pageName ? ` for ${pageName}` : '';

  return (
    <div>
      {`${part1}${part2}${part3}`}
      {isSearch ? part4Search : part4Suggested}
    </div>
  );
};

const UploadModal = () => {
  const dispatch = useDispatch();
  const ref = useRef();
  const suggestedSliderRef = useRef();
  const selectedSliderRef = useRef();
  const settings = useSelector(settingsSelector);
  const [tmpSearch, search, setSearch, isLoading, setLoading]: any = useDebounce();
  const [isSearch, images] = useSuggestedOrSearch({
    pageId: settings.pageId,
    search,
    withoutSuggested: !settings.isSuggestedEnabled,
    onlyPrimary: settings.isPrimaryPhoto,
    type: settings.type,
    isRooms: settings.isRoomSuggested,
    isItinerariesItem: settings.isItinerariesItem,
    itinerariesItemType: settings.itinerariesItemType,
    itinerariesItemId: settings.itinerariesItemId,
    landingPageType: settings.landingPageType,
    setLoading,
  });

  const index = oneOf(settings.index, 0);
  const files = oneOf(settings.files, []);
  const pipeline = oneOf(settings.data, []);
  const selected = oneOf(settings.selected, []);

  const file = files[index];
  const data = oneOf(pipeline[index], { ...settings.defaults });
  const fileAssetType = file ? mapMime2AssetType(file.mimeType) : null;

  const clipboardInputRef = useRef<HTMLDivElement>(null);
  const isModalStateChange = files.length;
  const allowedMimeTypes = ASSET_TYPE_TO_MIME_TYPES_MAP[settings.type];
  const maxFileSize = settings.maxFileSize;
  useClipboardImagePaste({ allowedMimeTypes, maxFileSize, clipboardInputRef, isModalStateChange });

  const isImageSearch = allowedMimeTypes.includes(MIME_TYPES.JPEG) || allowedMimeTypes.includes(MIME_TYPES.PNG);

  const getErrors = (val = data) => {
    const errors: IErrors = {};

    if (!val?.name) {
      errors.name = 'This is a required field';
    }

    if (settings.withTags && !val?.tags?.length) {
      errors.tags = 'This is a required field';
    }

    return errors;
  };

  const updateData = (diff: IUpdate) => {
    const copy = [...pipeline];
    copy[index] = { ...data, ...diff };
    const update = { data: copy };
    if (settings.errors) {
      //@ts-ignore
      update.errors = getErrors(copy[index]);
    }

    dispatch(changeSettingsAction(update));
  };

  const renderClipboardInput = (containerClassNames?: string) => {
    return (
      <div ref={clipboardInputRef} className={containerClassNames}>
        <FormGroup>
          <Input
            prefixClassName={styles.prefixContainer}
            placeholder={'Or, Paste Image Here'}
            className={styles.pasteImageInputContainer}
            prefix={<Icon component={Clipboard} />}
            search
          />
        </FormGroup>
      </div>
    );
  };

  let element;

  if (files.length) {
    element = (
      <Form
        asset={{ ...file, type: fileAssetType }}
        data={data}
        infoAlertText={settings.infoAlertText}
        translate={settings.translate}
        namePlaceholder={settings.namePlaceholder}
        tagsSubTitle={settings.tagsSubTitle}
        tagsPlaceholder={settings.tagsPlaceholder}
        saveButtonText={
          files.length > 1
            ? files.length - 1 === index
              ? settings.multipleSaveButtonText
              : settings.nextButtonText
            : settings.saveButtonText
        }
        errors={settings.errors}
        isPointEnabled={settings.isCenterOfImageEnabled && fileAssetType === 'media'}
        withTags={settings.withTags}
        withAssetSettings={settings.withAssetSettings}
        onUpdate={updateData}
        onSave={() => {
          const errors = getErrors();

          if (Object.keys(errors).length) {
            dispatch(changeSettingsAction({ errors }));
          } else {
            batch(() => {
              updateData({});

              // reset scroll top of modal window
              //@ts-ignore
              ref.current!.scrollTop = 0;

              if (index + 1 >= files.length) {
                dispatch(onUploadAction());
              } else {
                dispatch(changeSettingsAction({ index: index + 1 }));
              }
            });
          }
        }}
      />
    );
  } else {
    const suggestedImages = images?.filter((img) => !selected.find((img2: any) => img2.id === img.id));

    element = (
      <>
        {settings.isSearchEnabled && (
          <>
            <div className={styles.searchWrapper}>
              <FormGroup className={styles.searchInputContainer}>
                <Input
                  placeholder={settings.searchPlaceholder}
                  prefix={<Icon spin={isLoading} component={isLoading ? Loading : Search} />}
                  value={tmpSearch}
                  search
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setSearch(e.target.value);
                    //@ts-ignore
                    if (suggestedSliderRef.current?.resetScroll) {
                      //@ts-ignore
                      suggestedSliderRef.current.resetScroll();
                    }
                  }}
                  onKeyPress={(e: KeyboardEvent) => {
                    if (e.key === 'Enter') e.preventDefault();
                  }}
                />
              </FormGroup>

              {isImageSearch && renderClipboardInput(styles.clipboardInputContainer)}
            </div>

            <FormGroup>
              <Slider
                elementRef={suggestedSliderRef}
                images={suggestedImages}
                text={searchOrSuggestedResultText(
                  suggestedImages?.length,
                  isSearch,
                  search as string,
                  settings.pageName,
                  settings.type,
                )}
                onSelect={(asset) => {
                  if (settings.multiple) {
                    const isAssetInSelectedAlready = Boolean(selected.find((cur: IMedias) => cur.id === asset.id));
                    dispatch(
                      changeSettingsAction({
                        selected: isAssetInSelectedAlready
                          ? selected.filter((cur: IMedias) => cur.id !== asset.id)
                          : [asset, ...selected],
                      }),
                    );
                  } else {
                    dispatch(
                      changeSettingsAction({
                        selected: [asset],
                      }),
                    );
                  }
                  //@ts-ignore
                  if (selectedSliderRef.current?.resetScroll) {
                    //@ts-ignore
                    selectedSliderRef.current.resetScroll();
                  }
                }}
              />
            </FormGroup>

            {isSearch && suggestedImages!.length === 0 && (
              <FormGroup>
                <div
                  style={{
                    height: '12.3rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Text type="danger" accent>
                    {oneOf(settings.notFoundText, 'No Assets Found. Please try again.')}
                  </Text>
                </div>
              </FormGroup>
            )}
          </>
        )}
        {settings.isSelectedEnabled && (
          <>
            {selected.length > 0 && (
              <FormGroup>
                <Slider
                  reorderEnabled={settings.isSortingEnabled}
                  elementRef={selectedSliderRef}
                  images={selected}
                  onDelete={(asset) => {
                    dispatch(changeSettingsAction({ selected: selected.filter((s: IMedias) => s !== asset) }));
                  }}
                  onEdit={(asset) => {
                    dispatch(
                      // onEditAction updated at frontend/src/store/assets/thunks.js:303
                      //@ts-ignore
                      onEditAction({
                        asset,
                      }),
                    );
                  }}
                  onReorder={(oldIndex, newIndex) => {
                    dispatch(changeSettingsAction({ selected: move(oldIndex, newIndex, selected) }));
                  }}
                  text={
                    selected.length > 1
                      ? oneOf(settings.selectedTextMultiple, settings.selectedText)
                      : settings.selectedText
                  }
                />
              </FormGroup>
            )}
          </>
        )}

        {!settings.isSearchEnabled && isImageSearch && renderClipboardInput()}

        <FileDropZone
          placeholder={settings.dropZonePlaceholder}
          allowedMimeTypes={allowedMimeTypes}
          maxFileSize={maxFileSize}
          recommendedFileSize={settings.recommendedFileSize}
          onSelectNewFiles={(files: IDropZoneFiles[]) => {
            return dispatch(changeSettingsAction({ files }));
          }}
          needToShowWarning={settings.type === 'document'}
          multiple={settings.multiple}
          translate={settings.translate}
        />
        {settings.isSelectedEnabled && (
          <FormActions isRight className={styles.modalActionsWrapper}>
            <Button type="default" children="Delete" onClick={() => dispatch(onDeleteAction())} />
            <Button children={settings.saveButtonText} onClick={() => dispatch(onSaveAction())} />
          </FormActions>
        )}
      </>
    );
  }

  return (
    <Modal
      //@ts-ignore
      isOpen={true}
      toClose={() => dispatch(closeModalAction())}
      className={cx(style.modal, 'custom-upload-modal')}
      contentClassName={style.modalContent}
      ref={ref}
      children={
        <div>
          {Boolean(settings.loading) && <Loader text={settings.loading} isFillBlock />}

          <div className={style.modalHeader}>{settings.modalTitle}</div>

          {element}
        </div>
      }
      noPadding
    />
  );
};

export default UploadModal;
