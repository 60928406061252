import { useEffect } from 'react';

import { setToken } from 'utils/accessToken';
import { setAuthToken } from 'utils/axios';
import { getCookie, deleteCookie } from 'utils/cookies';

export const useMoveTokens = () => {
  useEffect(() => {
    const accessToken = getCookie('access_token');
    const refreshToken = getCookie('refresh_token');

    if (accessToken && refreshToken) {
      setToken({ accessToken, refreshToken });
      setAuthToken(accessToken);
      deleteCookie('access_token');
      deleteCookie('refresh_token');
    }
  }, []);
};
