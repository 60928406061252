import React, { FC } from 'react';

import cx from 'classnames';

import Icon from 'components/shared/Icon';
import Close from 'components/shared/Icon/icons/Close';
import Trash2 from 'components/shared/Icon/icons/Trash2';

import { ITagProps } from '../types';
import styles from './index.module.css';

const Tag: FC<ITagProps> = ({ title, onDelete, type }) => {
  const renderIcon = () => {
    if (type === 'primary') {
      return <Icon component={Close} width="1.4rem" height="1.4rem" strokeWidth={3} className={styles.icon} />;
    }

    return <Icon component={Trash2} className={styles.icon} />;
  };

  return (
    <div className={cx(styles.tag, { [styles.tagPrimary]: type === 'primary' })}>
      <div className={cx(styles.title, { [styles.titlePrimary]: type === 'primary' })}>{title}</div>
      {Boolean(onDelete) && (
        <div className={cx(styles.handle, { [styles.handlePrimary]: type === 'primary' })} onClick={onDelete}>
          {renderIcon()}
        </div>
      )}
    </div>
  );
};

export default Tag;
