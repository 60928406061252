import { batch } from 'react-redux';

import { logoutOnServer } from 'api/auth';
import axios from 'axios';
import store from 'configureStore';
import { isUnauthorizedSelector } from 'store/auth/selectors';
import { logoutAsThunk, unauthorizedThunk } from 'store/auth/thunks';
import errorTracking, { shouldReportAxiosError } from 'utils/errorTracker';
import { camelizeKeys, decamelizeKeys } from 'utils/humps';
import logger from 'utils/logger';

import { getAccessToken, getRefreshToken, setToken, removeToken } from './accessToken';

const { REACT_APP_API_URL, REACT_APP_ENV } = process.env;
const isDevelopment = REACT_APP_ENV === 'development';
export const baseApiURL = isDevelopment ? REACT_APP_API_URL : window.location.origin + '/api';

axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';

const instanceConfig = {
  transformResponse: [...axios.defaults.transformResponse, (data) => camelizeKeys(data)],
  transformRequest: [
    (data) => (data instanceof FormData ? data : decamelizeKeys(data)),
    ...axios.defaults.transformRequest,
  ],
};

const axiosAuth = axios.create({
  baseURL: baseApiURL,
  ...instanceConfig,
});

const axiosApi = axios.create({
  baseURL: baseApiURL,
  ...instanceConfig,
});

let external = false;
export const setExternal = (v = true) => (external = v);

function interceptorMiddleware(config) {
  if (config.baseURL === baseApiURL || config.forceMiddleware) {
    config.headers['X-Commit-Version'] = process.env.REACT_APP_COMMIT;
    const url = new URL(config.baseURL + config.url);
    url.searchParams.set('_v', process.env.REACT_APP_COMMIT);

    if (external) {
      url.searchParams.set('_h', window.location.hostname);
    }

    if (localStorage.getItem('pdfPreview') === 'true') {
      config.headers['X-Pdf-Preview'] = true;
    }

    config.url = url.toString().replace(config.baseURL, '');
  }

  return config;
}

axiosAuth.interceptors.request.use(interceptorMiddleware);
axiosApi.interceptors.request.use(interceptorMiddleware);

axiosApi.interceptors.response.use(
  (response) => {
    return response;
  },
  function (axiosError) {
    const state = store.getState();
    const isUnauthorized = isUnauthorizedSelector(state);
    const originalRequest = axiosError.config;
    const accessToken = getAccessToken();
    const refreshToken = getRefreshToken();
    const isUserBlocked = axiosError?.response?.data?.error === 'blocked';
    const isUserDeleted = axiosError?.response?.data?.errors?.title === 'deleted';
    const error = axiosError?.response?.data || axiosError;

    const respUrl = axiosError.request ? new URL(axiosError.request.responseURL) : {};
    const isSecondaryVerifyingAttempt = respUrl.origin + respUrl.pathname === baseApiURL + '/auth/verify-account';

    if (axiosError?.response?.status === 401) {
      // do not refresh token for Mobile app
      // supposed to be refactored, bc now it looks like workaround
      const url = new URL(location.href);
      if (url.searchParams.get('webview') === 'true') {
        return;
      }

      if (!isSecondaryVerifyingAttempt) {
        removeToken();
        setAuthToken();
      }
    }

    if (isUserBlocked || isUserDeleted) {
      if (!isUnauthorized) {
        batch(() => {
          store.dispatch(logoutAsThunk());
          store.dispatch(unauthorizedThunk({ isUnauthorized: true, isUserBlocked, isUserDeleted }));
        });
        throw error;
      }
    }

    // eslint-disable-next-line no-underscore-dangle
    if (refreshToken && axiosError?.response?.status === 401 && !originalRequest._retry) {
      // eslint-disable-next-line no-underscore-dangle
      originalRequest._retry = true;
      return axiosAuth
        .post(
          '/auth/jwt-refresh',
          {
            refreshToken,
          },
          { headers: { Authorization: `Bearer ${accessToken}` } },
        )
        .then((res) => {
          if (res.status === 200) {
            // 1) put token to LocalStorage
            setToken(res.data);
            const newAccessToken = res.data.accessToken;
            // 2) Change Authorization header
            axiosApi.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
            // 3) Change originalRequest header
            originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
            // 4) check if it's FormData request return upload with original params
            if (originalRequest.data instanceof FormData) {
              return upload(originalRequest.url, originalRequest.method, originalRequest.data);
            }
            // 5) or return originalRequest object with Axios.
            return axiosApi(originalRequest);
          }
        })
        .catch(async (e) => {
          if (e?.response?.status === 401) {
            await logoutOnServer();
          }

          logger.error('token request error: >>', error);
          throw error;
        });
    }

    if (shouldReportAxiosError(axiosError)) {
      errorTracking.sendError(error, '[axiosApi]');
    }

    error.axiosResponse = axiosError.response;

    logger.error('Axios request error: >>', error);
    throw error;
  },
);

/**
 * sets each request header with bearer token
 * @param token
 */
export function setAuthToken(token) {
  if (token) {
    axiosApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axiosApi.defaults.headers.common['Authorization'];
  }
}

export function setLoginAsHeader({ userId, accId }) {
  if (userId) {
    axiosApi.defaults.headers.common['X-Login-As'] = userId;

    axiosApi.defaults.headers.common['X-Login-As-Account-ID'] = accId;
  } else {
    delete axiosApi.defaults.headers.common['X-Login-As'];

    delete axiosApi.defaults.headers.common['X-Login-As-Account-ID'];
  }
}

async function upload(url, method, data) {
  const config = {
    url,
    method,
    headers: { 'Content-Type': 'multipart/form-data' },
    data,
  };

  return axiosApi.request(config);
}

export { axiosAuth, upload };
export default axiosApi;
