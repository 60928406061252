import React from 'react';

import { Control, FieldPath, FieldValues, useController } from 'react-hook-form';

import TagsInput from 'components/shared/FormAdapters/FormDefault/TagsInput';
import { ITagsInputProps } from 'components/shared/FormAdapters/FormDefault/TagsInput/types';

type Props<T extends FieldValues> = {
  name: string;
  control: Control<T>;
  placeholder?: string;
} & Pick<ITagsInputProps, 'type' | 'disabled'>;

const TagsInputAdapter = <T extends FieldValues>({ control, name, placeholder, type, disabled }: Props<T>) => {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController<T>({ name: name as FieldPath<T>, control });
  const errorMessage = error?.message;

  return (
    <div>
      <TagsInput
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        isError={!!errorMessage}
        disabled={disabled}
      />
    </div>
  );
};

export { TagsInputAdapter };
