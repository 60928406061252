export const ASSET_RATIO = [35, 31];

export const DIMENSIONS_STRATEGIES = {
  // Container stretches to 100% width and height adapts to the width based on image's ratio
  ADAPTIVE_HEIGHT: 'ADAPTIVE_HEIGHT',

  // width and height are set to auto
  CONTROLLABLE: 'CONTROLLABLE',
};

export const DIMENSIONS_STRATEGY_TO_CONTAINER_STYLES_MAP = {
  [DIMENSIONS_STRATEGIES.ADAPTIVE_HEIGHT]: {
    width: '100%',
    height: 'auto',
  },

  [DIMENSIONS_STRATEGIES.CONTROLLABLE]: {
    width: 'auto',
    height: 'auto',
  },
};

export const DIMENSIONS_STRATEGY_TO_ITEM_STYLES_MAP = {
  [DIMENSIONS_STRATEGIES.ADAPTIVE_HEIGHT]: {
    width: '100%',
    height: '100%',
  },

  [DIMENSIONS_STRATEGIES.CONTROLLABLE]: {
    width: 'auto',
    height: 'auto',
  },
};
