import React, { FC, InputHTMLAttributes, RefObject } from 'react';

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  innerRef?: RefObject<HTMLInputElement>;
  type?: string;
}

const Input: FC<IInputProps> = ({ innerRef, type = 'text', ...rest }) => {
  return <input ref={innerRef} type={type} {...rest} />;
};

export default Input;
