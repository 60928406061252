import { FC, useEffect } from 'react';
import { HTMLAttributes } from 'react';

import cx from 'classnames';

import { BaseRadioButton } from '..';
import { FormFieldWrapper } from '../..';
import s from '../index.module.css';
import { IBaseRadioButtonGroupProps } from '../types';

export const BaseRadioButtonGroup: FC<IBaseRadioButtonGroupProps & HTMLAttributes<HTMLDivElement>> = ({
  options,
  value,
  defaultValue,
  onChange,
  helpText,
  isVertical,
  className,
}) => {
  useEffect(() => {
    defaultValue && onChange(defaultValue);
  }, [onChange, defaultValue]);

  return (
    <FormFieldWrapper helpText={helpText}>
      <div className={cx(s.wrapper, { [s.vertical]: isVertical }, className)}>
        {options.map(({ name, id = name, isDisabled }, idx) => (
          <BaseRadioButton
            key={name + idx}
            label={name}
            value={id}
            onChange={() => onChange(id)}
            isSelected={id === (value || defaultValue)}
            isDisabled={isDisabled}
          />
        ))}
      </div>
    </FormFieldWrapper>
  );
};
