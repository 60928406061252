import React from 'react';

import EnableCookie from 'components/shared/EnableCookie';
import { inIframe } from 'utils/browserDetect';

function CheckCookieEnabledMiddleware({ children }) {
  const cookieEnabled = navigator.cookieEnabled;

  if (!cookieEnabled && !inIframe()) {
    return <EnableCookie />;
  }

  return children;
}

export default CheckCookieEnabledMiddleware;
