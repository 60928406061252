// import testImgUrl from 'components/shared/images/basePreview.jpg';

const mockData = (page, userName) => ({
  title: 'About your Consultant',
  // primaryImage: testImgUrl,
  blocks: [
    { type: 'header', data: { subheadline: userName } },
    {
      type: 'text',
      data: {
        placeholder:
          'Click in this text block to write your own content. We have given you space here to write about how awesome you are and what sets you apart from other safari consultants. We know you are awesome because you have very good taste in systems software. There is a character limit on this text block to ensure that the text is split up with images and videos which studies show is much more effective than long blocks of text. You can continue your text beneath the media block and in the other sections.',
      },
    },
    {
      type: 'image',
      data: {},
    },
    {
      type: 'text',
      data: {
        placeholder:
          'We have given you even more space here to write more about how special you are, because you are really special! Please do not underestimate how great you are. There is a character limit on this text block but you can always add another text block if you need more space to sing your praises! However, we do not think it’s a good idea to write more than 630 characters here (so keep it short, buddy!).',
      },
    },
  ],
});

export default mockData;
