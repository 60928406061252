import { EFlightSearchTypes } from 'components/Pages/Admin/Itinerary/Details/DetailsChronologicalMode/constants';

import { IRichText } from './__blocks';

// eslint-disable-next-line no-unused-vars
export enum EOrganizedBy {
  // eslint-disable-next-line no-unused-vars
  Date = 'dates',
  // eslint-disable-next-line no-unused-vars
  Day = 'days',
}

export enum EBulkAction {
  Duplicate = 'duplicate',
  Delete = 'delete',
  ClearSelection = 'clearSelection',
}

// eslint-disable-next-line no-unused-vars
export enum ETripEvent {
  // eslint-disable-next-line no-unused-vars
  Flight = 'flight',
  // eslint-disable-next-line no-unused-vars
  Transfer = 'transfer',
  // eslint-disable-next-line no-unused-vars
  Area = 'area',
  // eslint-disable-next-line no-unused-vars
  Accommodation = 'accommodation',
  // eslint-disable-next-line no-unused-vars
  Activity = 'activity',
  // eslint-disable-next-line no-unused-vars
  Information = 'info',
  // eslint-disable-next-line no-unused-vars
}

// eslint-disable-next-line no-unused-vars
export enum EActivityEventType {
  // eslint-disable-next-line no-unused-vars
  CustomTime = 'custom_time',
  // eslint-disable-next-line no-unused-vars
  FullDay = 'full_day',
  // eslint-disable-next-line no-unused-vars
  HalfDay = 'half_day',
  // eslint-disable-next-line no-unused-vars
  MultiDay = 'multi_day',
  // eslint-disable-next-line no-unused-vars
  Meal = 'meal',
}

export enum EFlightStatus {
  Common = 'common',
  Rescheduled = 'rescheduled',
  Cancelled = 'cancelled',
}

export const activityEventTypeToName = {
  [EActivityEventType.CustomTime]: 'Custom Time',
  [EActivityEventType.FullDay]: 'Full Day',
  [EActivityEventType.HalfDay]: 'Half Day',
  [EActivityEventType.MultiDay]: 'Multi Day',
  [EActivityEventType.Meal]: 'Meal',
};

export enum EAreaEventType {
  CheckInDay = 'in_day',
  MultyDay = 'multi_day',
}

export const areaEventTypeToName = {
  [EAreaEventType.CheckInDay]: 'On Check in Day Only',
  [EAreaEventType.MultyDay]: 'As Multi-Day Activity ',
};

// eslint-disable-next-line no-unused-vars
export enum ERoomType {
  // eslint-disable-next-line no-unused-vars
  FamilyCottage = 'family_cottage',
  // eslint-disable-next-line no-unused-vars
  DoubleBed = 'double_bed',
  // eslint-disable-next-line no-unused-vars
  SingleBed = 'single_bed',
}

// eslint-disable-next-line no-unused-vars
export enum ERoomBasis {
  // eslint-disable-next-line no-unused-vars
  RoomOnly = 'Room Only',
  // eslint-disable-next-line no-unused-vars
  BreakfastIncluded = 'Breakfast Included',
  // eslint-disable-next-line no-unused-vars
  HalfBoard = 'Half Board',
  // eslint-disable-next-line no-unused-vars
  FullBoard = 'Full Board',
  // eslint-disable-next-line no-unused-vars
  FullyInclusive = 'Fully Inclusive',
  // eslint-disable-next-line no-unused-vars
  GamePackage = 'Game Package',
  // eslint-disable-next-line no-unused-vars
  FullBoard2 = 'Full Board With Coffee, Tea, And Soft Drinks',
  // eslint-disable-next-line no-unused-vars
  FullBoard3 = 'Full Board With All Drinks (Excluding Premium Wines, Spirits, And Champagnes)',
  // eslint-disable-next-line no-unused-vars
  BreakfastAndTaxes = 'Breakfast and Taxes',
}

export interface IDatesRange {
  start: string | null;
  end: string | null;
}

export interface IDaysRange {
  start: number;
  end: number | null;
}

export interface ITripEvent<K extends ETripEvent> {
  id: number;
  icon: string;
  type: K;
  /* position number inside the day */
  positions: number[];
  datesRange: IDatesRange;
  daysRange: IDaysRange;
  data: TripEventMap[K];
  isCollapsible?: boolean;
  attachedPages?: ITarget[];
  showChangedRoomOrPageTooltip?: boolean;
  _destroy?: boolean;
}

export enum ETimelineType {
  Start = 'start',
  Middle = 'middle',
  End = 'end',
  OneDay = 'one_day',
}

interface ITripEventData {
  notes: IRichText | null;
  isIDL?: boolean;
}

interface IBaseFlightEventData extends ITripEventData {
  // whether flight was found through the Flight Stats service
  fromFsService: boolean;
  fsSearchType: EFlightSearchTypes;
  displaySettings?: string;
}

export interface IFlightManualEventData extends IBaseFlightEventData {
  fromFsService: false;
  arrivalTime: string;
  arrivalTimeEmpty?: boolean;
  arrivalTo: string;
  bookedBy?: string;
  confirmationNumber?: string;
  createdAt?: string;
  departureFrom: string;
  departureTime: string;
  departureTime_empty?: boolean;
  duration?: string;
  flightNumber?: string;
  id?: number;
  policies?: IPolicies;
  airline?: string;
  type?: string;
}

export interface IAirport {
  id: number;
  active: boolean;
  city: string;
  cityCode: string;
  countryName: string;
  fsCode: string;
  name: string;
  utcOffsetHours: string;
}

export interface IAirline {
  id: number;
  active: boolean;
  fsCode: string;
  name: string;
}

interface IPolicies {
  create: boolean;
  destroy: boolean;
  update: boolean;
}

interface ICommonEventData {
  arrivalTime: string;
  arrivalTimeEmpty: boolean;
  bookedBy: string;
  confirmationNumber: string;
  createdAt: string;
  departureTime: string;
  departureTimeEmpty: boolean;
  duration: string;
  id: number;
  policies: IPolicies;
  type: string;
}

export type NewTime = {
  date: string;
  timestamp?: number;
};

export interface IFlightAPIEventData extends IBaseFlightEventData, ICommonEventData {
  fromFsService: true;
  flightDate: string;
  airline: IAirline;
  arrivalAirport: IAirport;
  departureAirport: IAirport;
  flightNumber: string;
  cancelled?: true;
  newArrivalTime: NewTime[];
  newDepartureTime: NewTime[];
  flightStatus: EFlightStatus;
  notifyAgent: boolean;
  updateClientSide: boolean;
}

export type TFlightEventData = IFlightAPIEventData | IFlightManualEventData;

export interface ITransferEventData extends ITripEventData, ICommonEventData {
  arrivalTo: string;
  contactInfo: string;
  departureFrom: string;
  provider: string;
  vehicleType: string;
  displaySettings?: string;
}

export interface ITarget {
  id?: string;
  name: string;
}

export interface IRooming extends ITarget {
  isCustom?: boolean;
  count?: number | null;
}

export interface IAreaEventData extends ITripEventData {
  title: string;
  details: IRichText;
}

export interface IInformationEventData extends IAreaEventData {}

export interface IAccommodationEventData extends ITripEventData {
  checkIn: string;
  checkOut: string;
  rooming: IRooming[];
  roomBasis: string | null;
  confirmationNumber: string;
  contactInfo: string;
  location: string;
  website: string;
  isAlternate: boolean;
  displaySettings: {
    checkIn: string;
    checkOut: string;
    overnight: string;
  };
}

interface IActivityTime {
  start: string | null;
  end: string | null;
}

export interface IActivityEventData extends ITripEventData {
  target: ITarget;
  type: EActivityEventType;
  time: IActivityTime;
  title: string;
  details: IRichText;
  confirmationNumber: number;
  contactInfo: string;
  website?: string;
  location: string;
}

export type TripEventMap = {
  [ETripEvent.Flight]: TFlightEventData;
  [ETripEvent.Transfer]: ITransferEventData;
  [ETripEvent.Area]: IAreaEventData;
  [ETripEvent.Accommodation]: IAccommodationEventData;
  [ETripEvent.Activity]: IActivityEventData;
  [ETripEvent.Information]: IInformationEventData;
};

export type TTripEvent = {
  [K in ETripEvent]: ITripEvent<K>;
}[ETripEvent];

export interface IApiFlightTripEvent extends ITripEvent<ETripEvent.Flight> {
  data: IFlightAPIEventData;
}

export enum EDateFormat {
  DMY = 'dmy',
  MDY = 'mdy',
  YMD = 'ymd',
}
