import React from 'react';

import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'intersection-observer';
import 'web-animations-js';

import App from './App';
import store from './configureStore';
import * as serviceWorkerRegister from './serviceWorkerRegister';
import 'draft-js/dist/Draft.css';
import 'react-colorful/dist/index.css';
import 'stylesheets/modern-css-reset.css';
import 'stylesheets/index.css';

//for supporting safari browsers since 12.1
import 'utils/polyfills';

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegister.register();
