import { ChangeEvent, useEffect, useRef, useState } from 'react';

import { ETimePeriod, NUMBERS_BEFORE_COLON_LENGTH } from './constants';
import { IBaseTimeInputProps } from './types';
import { addColonToTime, formatHours, isTimeValid } from './utils';

export const useTimeInput = ({ value, onChange }: Pick<IBaseTimeInputProps, 'value' | 'onChange'>) => {
  const [formattedValue, timePeriod] = value.split(' ');
  const safeTimePreiod = (timePeriod as ETimePeriod) || ETimePeriod.AM;

  const [activeTimePeriod, setActiveTimePeriod] = useState<ETimePeriod>(safeTimePreiod);

  const contentRef = useRef<HTMLInputElement>();

  const timeValue = formattedValue.length == NUMBERS_BEFORE_COLON_LENGTH ? formattedValue + ':' : formattedValue;

  useEffect(() => {
    setActiveTimePeriod(safeTimePreiod);
  }, [safeTimePreiod]);

  useEffect(() => {
    timeValue && onChange(timeValue + ` ${activeTimePeriod}`);
  }, [activeTimePeriod]);

  const isValid = isTimeValid(timeValue);

  const focusInput = () => contentRef.current?.focus();

  const timeInputHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^0-9]+/g, '');
    const result = addColonToTime(formatHours(value));
    result && result.length < 6 && onChange(result + ` ${activeTimePeriod}`);
  };

  const colonRemoveHandler = (e: KeyboardEvent) => {
    if (e.key === 'Backspace') {
      if (timeValue.endsWith(':')) {
        e.preventDefault();
        onChange(value[0]);
      } else if (timeValue.length === 1) {
        e.preventDefault();
        onChange('');
      }
    }
  };
  return {
    focusInput,
    contentRef,
    timeValue,
    colonRemoveHandler,
    timeInputHandler,
    isValid,
    activeTimePeriod,
    setActiveTimePeriod,
  };
};
