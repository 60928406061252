import { ITINERARIES_ITEM_TYPES } from 'constants/index';

export const SHARE_TYPE = {
  CLIENT: 'client',
  AGENT: 'agent',
  FRIEND: 'friend',
};

export const SHARE_TYPE_TO_ENDPOINT_MAP = {
  [SHARE_TYPE.CLIENT]: 'client',
  [SHARE_TYPE.AGENT]: 'agent',
  [SHARE_TYPE.FRIEND]: 'friend',
};

export const SHARING_MODAL_ITINERARIES = {
  [SHARE_TYPE.CLIENT]: {
    who: 'Clients',
    text:
      'I am pleased to share your custom travel portal with you. \n\n' +
      'If you like, you can download the Travel Portal app from the App Store or Google Play store. As long as you register using this same email address, you will be able to access everything from there automatically. \n\n' +
      'Let me know if you have any questions.',
  },
  [SHARE_TYPE.AGENT]: {
    who: 'Agents',
    top: 'Share this project with another Agent. If the Agent has a Safari Portal account, this will send the itinerary into his or her Itineraries dashboard. This is an Add-On Feature.',
    text:
      'I am pleased to share your custom travel portal with you. \n\n' +
      'If you like, you can download the Travel Portal app from the App Store or Google Play store. As long as you register using this same email address, you will be able to access everything from there automatically. \n\n' +
      'Let me know if you have any questions.',
  },
  [SHARE_TYPE.FRIEND]: {
    who: 'Add People',
    what: 'Invite people to create their own Private Login to access and edit this Guest Portal.',
  },
};

export const SHARING_MODAL_GUEST_PORTAL = {
  [SHARE_TYPE.CLIENT]: {
    who: 'Clients',
    top: 'Share this project with your Clients via email.',
    text: 'I am pleased to share your custom travel portal with you.\n' + 'Let me know if you have any questions.',
  },
  [SHARE_TYPE.AGENT]: {
    who: 'Agents',
    top: 'Share this project with another Agent. The Agent will receive an invitation to this Guest Portal and can share it from there. This is an Add-On Feature.',
    text:
      'I am pleased to share this custom travel portal with you.\n' +
      '\n' +
      'Once you log into the portal, you will be able to share with your clients by clicking the + person icon in the top right hand corner of the screen.\n' +
      '\n' +
      'Let me know if you have any questions.',
  },
  [SHARE_TYPE.FRIEND]: {
    who: 'Add People',
    what: 'Invite people to create their own Private Login to access and edit this Guest Portal.',
  },
};

export const ITINERARIES_ITEM_TO_LABEL_MAP = {
  [ITINERARIES_ITEM_TYPES.ITINERARY]: 'itinerary',
  [ITINERARIES_ITEM_TYPES.LOOKBOOK]: 'itinerary',
  [ITINERARIES_ITEM_TYPES.GUEST_PORTAL]: 'Guest Portal',
};

export const ITINERARIES_ITEM_TO_SHARED_LINK_PLACEHOLDER_MAP = {
  [ITINERARIES_ITEM_TYPES.ITINERARY]: 'itineraries.safariportal.app/customizedslug',
  [ITINERARIES_ITEM_TYPES.LOOKBOOK]: 'itineraries.safariportal.app/customizedslug',
  [ITINERARIES_ITEM_TYPES.GUEST_PORTAL]: 'guest.safariportal.app/customizedslug',
};

export const ITINERARIES_ITEM_TO_SHARING_MODAL_MAP = {
  [ITINERARIES_ITEM_TYPES.ITINERARY]: SHARING_MODAL_ITINERARIES,
  [ITINERARIES_ITEM_TYPES.LOOKBOOK]: SHARING_MODAL_ITINERARIES,
  [ITINERARIES_ITEM_TYPES.GUEST_PORTAL]: SHARING_MODAL_GUEST_PORTAL,
};
