import { FC } from 'react';

import { useController } from 'react-hook-form';

import { BaseCheckbox } from 'components/shared/BaseFormComponents/Checkbox';

import { IHookCheckboxProps } from './types';

export const HookCheckbox: FC<IHookCheckboxProps> = ({
  name,
  control,
  defaultValue = false,
  children,
  helpText,
  isDisabled,
  className,
}) => {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({ name, control, defaultValue });

  return (
    <BaseCheckbox
      name={name}
      isChecked={value}
      onChange={onChange}
      errorMessage={error?.message || ''}
      helpText={helpText}
      isDisabled={isDisabled}
      className={className}
    >
      {children}
    </BaseCheckbox>
  );
};
