import { PageVirtualized } from 'components/shared/LandingPage/Virtualizer';
import { IPage } from 'utils/itineraryDetails/types';

import { ILandingConfig, ILandingSettings } from '../types';

export interface IPageProps {
  data: IPage;
  landingConfig: ILandingConfig;
  onChange?: (data: Partial<IPage>) => void;
  readonly: boolean;
  onChangeCoverImage?: () => void;
  index: number;
  isFirst: boolean;
  isLast: boolean;
  virtualized?: PageVirtualized;
}

export enum EPageType {
  PROPERTY = 'property',
  AREA = 'area',
  ACTIVITY = 'activity',
  MISC = 'misc',
  ABOUT_US = 'about_us',
  ABOUT_SAFARI = 'about_safari',
  PRE_TRAVEL_INFO = 'pre_travel_info',
  DAY = 'day',
}

export const PAGE_TYPE_KEY_NAMES = {
  [EPageType.PROPERTY]: 'Property',
  [EPageType.AREA]: 'Area',
  [EPageType.ACTIVITY]: 'Activity',
  [EPageType.MISC]: 'Miscellaneous',
  [EPageType.ABOUT_US]: 'About Us',
  [EPageType.ABOUT_SAFARI]: 'Introductory Notes',
  [EPageType.PRE_TRAVEL_INFO]: 'Pre Travel Information',
  [EPageType.DAY]: 'Day Page',
};

export enum EPageRole {
  COVER_PAGE = 'cover_page', // itinerary, lookbook
  SAFARI_MAP = 'safari_map', // itinerary, lookbook

  SAFARI_OVERVIEW = 'safari_overview', // itinerary
  SAFARI_COSTS = 'safari_costs', // itinerary

  TERMS = 'terms', // itinerary
  ABOUT_US = 'about_us', // itinerary

  ABOUT_SAFARI = 'about_safari', // itinerary, lookbook

  COMMON = 'common', // lookbook inclusions

  DETAILS = 'details', // itinerary details

  GUESTS = 'guest_forms', // guest portal
  SAFARI_MANIFEST = 'safari_manifest', // guest portal
  TRAVEL_BRIEF = 'travel_brief', // guest portal - like safari_manifest but for Details V2
  TRAVEL_WALLET = 'travel_wallet', // guest portal
  COMMON_PAGES_FIRST = 'common_pages_first', // Introduction select in General Info
  COMMON_PAGES_SECOND = 'common_pages_second', // Information pages select in General Info
  RELOCATIONS = 'relocations', // Flights and Transfer page in GP

  DAY = 'day',
}

export const PAGE_ROLE_KEY_NAMES = {
  [EPageRole.COVER_PAGE]: 'Cover Page',
  [EPageRole.SAFARI_MAP]: 'Map Page',
  [EPageRole.SAFARI_OVERVIEW]: 'Safari Overview Page',
  [EPageRole.SAFARI_COSTS]: 'Costs Page',
  [EPageRole.TERMS]: 'Pages after Costs Page',
  [EPageRole.ABOUT_US]: 'Pages after Map Page',
  [EPageRole.ABOUT_SAFARI]: 'Pages after Cover Page',
  [EPageRole.COMMON]: 'Lookbook Inclusions',
  [EPageRole.DETAILS]: 'Pages from Details Tab V1',
  [EPageRole.GUESTS]: 'Guest Forms Page',
  [EPageRole.SAFARI_MANIFEST]: 'Safari Manifest',
  [EPageRole.TRAVEL_BRIEF]: 'Travel Brief',
  [EPageRole.TRAVEL_WALLET]: 'Travel Wallet',
  [EPageRole.COMMON_PAGES_FIRST]: 'Introduction Pages in GP (go first)',
  [EPageRole.COMMON_PAGES_SECOND]: 'Information Pages in GP (go last)',
  [EPageRole.RELOCATIONS]: 'Flights and Transfers Page',
  [EPageRole.DAY]: 'Day Pages',
};

export enum EPageOrder {
  FIRST = 'first',
  LAST = 'last',
}

interface IPageBasicConfig {
  disabledAddingBlocks?: boolean;
  hiddenStickyHeader?: boolean;
  layout?: EPageLayout;
  settings?: ILandingSettings;
  defaultSubheadline?: string;
}
export interface IPageConfigByType extends IPageBasicConfig {}

export interface IPageConfigByRole extends IPageBasicConfig {
  centeredContent?: boolean;
  isTextOverImageEditable?: boolean;
}

export interface IPageConfig extends IPageConfigByType, IPageConfigByRole {}

export enum EPageLayout {
  // vertical orientation
  HalfWidth = 'halfWidth',
  // horizontal orientation
  FullWidth = 'fullWidth',
}

export const PAGE_LAYOUT_KEY_NAMES = {
  [EPageLayout.FullWidth]: 'Full Width',
  [EPageLayout.HalfWidth]: 'Half Width',
};
