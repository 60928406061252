import { useEffect, useState } from 'react';

import axios from 'axios';
import { useSelector } from 'react-redux';

import { IMedias, IUseSuggestedOrSearch } from 'components/shared/AssetEditorForm/types';
import { itineraryActivePrimaryImagesSelector } from 'store/itinerariesItem/selectors';
import { accountIdSelector } from 'store/user/selectors';
import { loadBySearch, loadSuggested } from 'utils/suggested';

export function useSuggestedOrSearch({
  pageId,
  search = '',
  withoutSuggested,
  onlyPrimary,
  type,
  isRooms,
  isItinerariesItem,
  itinerariesItemType,
  itinerariesItemId,
  landingPageType,
  setLoading,
}: IUseSuggestedOrSearch) {
  const accountId = useSelector(accountIdSelector);
  const [images, setImages] = useState<[boolean?, IMedias[]?]>([]);
  const activePrimaryImageIds = useSelector((state) => itineraryActivePrimaryImagesSelector(state, landingPageType));

  useEffect(() => {
    (async () => {
      if (search === '') {
        if (withoutSuggested) {
          return setImages([false, []]);
        }

        return setImages([
          false,
          await loadSuggested({
            accountId,
            pageId,
            onlyPrimary,
            type,
            isRooms,
            isItinerariesItem,
            itinerariesItemType,
            itinerariesItemId,
            landingPageType,
            activePrimaryImageIds,
          }),
        ]);
      } else {
        try {
          const images = await loadBySearch(accountId, search, type);
          setImages([true, images]);
          setLoading(false);
        } catch (err) {
          if (!(err instanceof axios.Cancel)) {
            setLoading(false);
            throw err;
          }
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, onlyPrimary, pageId, search, type, withoutSuggested]);

  return images;
}
