import Compressor from 'compressorjs';

export const compressImage = (file: File, quality = 0.95): Promise<File> =>
  new Promise(
    (resolve, reject) =>
      new Compressor(file, {
        quality,
        success(result) {
          let compressedFile: File;

          if (result instanceof File) {
            compressedFile = result;
          } else {
            compressedFile = new File([result], file.name, { type: file.type });
          }

          resolve(compressedFile);
        },
        error(error) {
          reject(error);
        },
      }),
  );
