import mixpanel from 'mixpanel-browser';

import logger from 'utils/logger';

export enum EMixpanelEvents {
  CREATE_ITINERARY = 'Create Itinerary',
  UPDATE_ITINERARY = 'Update Itinerary',
  DELETE_ITINERARY = 'Delete Itinerary',
  UPDATE_GUEST_PORTAL = 'Update Guest Portal',
  CREATE_PAGE = 'Create Page',
  UPDATE_PAGE = 'Update Page',
  DELETE_PAGE = 'Delete Page',
}

export const trackEvent = (eventName: string, eventProperties?: any) => {
  try {
    mixpanel.track(eventName, eventProperties);
  } catch (error) {
    logger.error(error);
  }
};
