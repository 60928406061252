import { FC } from 'react';

import { useController } from 'react-hook-form';

import { BaseTimeInput } from 'components/shared/BaseFormComponents/TimeInput';

import { IHookTimeInputProps } from './types';

export const HookTimeInput: FC<IHookTimeInputProps> = ({ name, control, label, helpText = '', defaultValue = '' }) => {
  const {
    field: { value, onChange, onBlur },
    fieldState: { error },
  } = useController({ name, control, defaultValue });

  return (
    <BaseTimeInput
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      label={label}
      helpText={helpText}
      errorMessage={error?.message}
    />
  );
};
