import { getPageTypesConfig } from 'components/Pages/Admin/Page/Landing/pageTypesConfig';
import { getLangingPageType } from 'utils/itineraries/functions';
import { IPage } from 'utils/itineraryDetails/types';

import { IMarkEmptyPagesProps } from './types';

export const getPagesWithPlaceholders = (pages: IPage[]) => {
  return pages?.map((page) => {
    const config = getPageTypesConfig(getLangingPageType(page));
    // @ts-ignore
    const placeholders = config.useMockData(page);
    // @ts-ignore
    const updatedPage = page?.blocks?.length === 0 ? { ...page, ...placeholders } : page;
    return updatedPage;
  });
};

export const markEmptyPages = ({ pages = [], excludeRoles = [] }: IMarkEmptyPagesProps) => {
  return pages.map((page) => ({ ...page, empty: excludeRoles.includes(page.role) }));
};
