import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  selected: [],
  pagination: {},
  modals: {},
  loading: false,
};

const slice = createSlice({
  name: 'admin/pendingContent',
  initialState,
  reducers: {
    selectAction(state, action) {
      if (state.selected.includes(action.payload)) {
        state.selected = state.selected.filter((id) => id !== action.payload);
      } else {
        state.selected.push(action.payload);
      }
    },
    clearSelectAction(state) {
      state.selected = [];
    },
    addDataAction(state, action) {
      state.data = action.payload.data;
      state.pagination = action.payload.pagination;
    },
    openModal(state, action) {
      state.modals[action.payload.id] = action.payload.data;
    },
    closeModal(state, action) {
      delete state.modals[action.payload];
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

export const { selectAction, clearSelectAction, addDataAction, openModal, closeModal, setLoading } = slice.actions;

export default slice.reducer;
