import React from 'react';

export const CheckboxCheckmark = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.31476 8.26401L0.132954 4.84701C-0.044318 4.65901 -0.044318 4.35301 0.132954 4.16301L0.775678 3.48001C0.95295 3.29201 1.24113 3.29201 1.4184 3.48001L3.63657 5.87201L8.5811 0.547006C8.75837 0.359006 9.04655 0.359006 9.22382 0.547006L9.86655 1.23101C10.0438 1.41901 10.0438 1.72601 9.86655 1.91301L3.95748 8.26401C3.78021 8.45201 3.49203 8.45201 3.31476 8.26401Z"
      fill="white"
    />
  </svg>
);
