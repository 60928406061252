import {
  coverPageConfig,
  overviewConfig,
  overviewMapConfig,
  costsConfig,
} from 'components/Pages/Admin/Itinerary/Landing/config';
import { defaultParams } from 'components/shared/LandingPage/contexts/LandingPageTypeContext';
import { LANDING_PAGE_TYPES } from 'constants/index';

import { guestPortalDefaultPagesConfig, guestPortalTripManifestConfig } from '../../GuestPortal/Landing/config';
import aboutUs from '../../PageTypes/AboutUs/Landing/config';
import activity from '../../PageTypes/Activity/Landing/config';
import area from '../../PageTypes/Area/Landing/config';
import helpArticle from '../../PageTypes/HelpArticle/Landing/config';
import helpTutorial from '../../PageTypes/HelpTutorial/Landing/config';
import miscellaneous from '../../PageTypes/Miscellaneous/Landing/config';
import noteAboutSafari from '../../PageTypes/NoteAboutSafari/Landing/config';
import preTravelInformation from '../../PageTypes/PreTravelInformation/Landing/config';
import property from '../../PageTypes/Property/Landing/config';

const baseBlockConfig = {
  pageTypeContextProps: {
    ...defaultParams,
  },
  useMockData: (_page) => ({}),
};

const getConfig = ({ pageTypeContextProps = {}, ...rest }) => {
  return {
    ...baseBlockConfig,
    pageTypeContextProps: {
      ...baseBlockConfig.pageTypeContextProps,
      ...pageTypeContextProps,
    },
    ...rest,
  };
};

const pageTypesConfig = {
  [LANDING_PAGE_TYPES.AREA]: getConfig(area),
  [LANDING_PAGE_TYPES.PROPERTY]: getConfig(property),
  [LANDING_PAGE_TYPES.ACTIVITY]: getConfig(activity),
  [LANDING_PAGE_TYPES.MISC]: getConfig(miscellaneous),
  [LANDING_PAGE_TYPES.ABOUT_SAFARI]: getConfig(noteAboutSafari),
  [LANDING_PAGE_TYPES.ABOUT_US]: getConfig(aboutUs),
  [LANDING_PAGE_TYPES.PRE_TRAVEL_INFO]: getConfig(preTravelInformation),
  [LANDING_PAGE_TYPES.HELP_ARTICLE]: getConfig(helpArticle),
  [LANDING_PAGE_TYPES.HELP_TUTORIAL]: getConfig(helpTutorial),

  [LANDING_PAGE_TYPES.COVER_PAGE]: getConfig(coverPageConfig),
  [LANDING_PAGE_TYPES.SAFARI_OVERVIEW]: getConfig(overviewConfig),
  [LANDING_PAGE_TYPES.SAFARI_MAP]: getConfig(overviewMapConfig),
  [LANDING_PAGE_TYPES.SAFARI_COSTS]: getConfig(costsConfig),

  [LANDING_PAGE_TYPES.GUESTS]: getConfig(guestPortalDefaultPagesConfig),
  [LANDING_PAGE_TYPES.SAFARI_MANIFEST]: getConfig(guestPortalTripManifestConfig),
  [LANDING_PAGE_TYPES.TRAVEL_WALLET]: getConfig(guestPortalDefaultPagesConfig),
  [LANDING_PAGE_TYPES.RELOCATIONS]: getConfig(guestPortalDefaultPagesConfig),
};

export function getPageTypesConfig(type) {
  if (!pageTypesConfig[type]) {
    return baseBlockConfig;
  }

  return pageTypesConfig[type];
}
