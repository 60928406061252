import React, { ReactNode } from 'react';

import { AModal } from 'components/shared/AModal';

import s from './index.module.css';

const MULTIPLE_MODAL_ID = 'mult-modal-body';

const ModalWrapper = ({
  onClose,
  header,
  body,
  footer,
}: {
  onClose: () => void;
  header: ReactNode;
  body: ReactNode;
  footer: ReactNode;
}) => {
  // To prevent modal overlapping
  if (document.getElementById(MULTIPLE_MODAL_ID)) {
    return null;
  }

  return (
    <AModal
      size="auto"
      header={header}
      isOpen
      onClose={onClose}
      bodyClassName={s.wrapper}
      wrapperClassName={s.modalWrapper}
      contentClassName={s.modalContent}
    >
      <div className={s.body} id={MULTIPLE_MODAL_ID}>
        {body}
      </div>
      <div className={s.footer}>{footer}</div>
    </AModal>
  );
};

export default ModalWrapper;
