import React from 'react';

import { ModalsArea } from 'modal.react.js';

import area from './area';

const GlobalModalArea = () => {
  return <ModalsArea area={area} />;
};

export default GlobalModalArea;
