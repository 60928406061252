import React from 'react';

function Download2({ stroke = '#3F3C43', ...props }) {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="none"
        d="M8.0625 10.7184L12 14.6559L15.9375 10.7184"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="none"
        d="M12 4.15591V14.6559"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.25 14.6559V19.9059C20.25 20.1048 20.171 20.2956 20.0303 20.4362C19.8897 20.5769 19.6989 20.6559 19.5 20.6559H4.5C4.30109 20.6559 4.11032 20.5769 3.96967 20.4362C3.82902 20.2956 3.75 20.1048 3.75 19.9059V14.6559"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
}

export default Download2;
