import React from 'react';

import Button from 'components/shared/Button';
import { useModalClose } from 'hooks/useModalClose';

import ModalWrapper from '../index';
import cs from '../index.module.css';

const ConfirmChangesModal = ({ onClose, onSave }: { onClose: () => void; onSave: (...args: any) => Promise<void> }) => {
  useModalClose();

  const handleSave = async (e: React.MouseEvent<HTMLElement>) => {
    await onSave(e);
    onClose();
  };

  return (
    <ModalWrapper
      onClose={onClose}
      header="Confirm Changes"
      body={
        <div className={cs.description}>
          Please be aware that by clicking save, you will override any changes made by others or you in another tab, if
          other changes had been made. Do you want to proceed?
        </div>
      }
      footer={
        <>
          <Button type="default" size="medium" onClick={onClose}>
            Cancel
          </Button>
          <Button size="medium" onClick={handleSave}>
            Save Changes
          </Button>
        </>
      }
    />
  );
};

export default ConfirmChangesModal;
