import React, { useMemo } from 'react';

import MaskedInput from 'react-text-mask';

import Input from 'components/shared/FormAdapters/FormBase/Input';
import { getMask, getPlaceholder } from 'utils/form';

const defaultProps = {
  mask: undefined,
  typeOfMask: undefined,
  className: undefined,
  placeholder: undefined,
  type: 'text',
  withoutPlaceholder: false,
};

function Mask({ className, mask, typeOfMask, placeholder, maskActive = true, withoutPlaceholder, ...props }) {
  const resultMask = useMemo(() => getMask(typeOfMask, mask), [mask, typeOfMask]);
  const maskPlaceholder = withoutPlaceholder ? '' : getPlaceholder(typeOfMask, placeholder);

  if (!maskActive || (!mask && !typeOfMask)) return <Input className={className} {...props} />;

  return (
    <MaskedInput
      {...props}
      mask={resultMask}
      placeholder={maskPlaceholder}
      render={(ref, p) => <input ref={ref} {...p} />}
      className={className}
    />
  );
}

Mask.defaultProps = defaultProps;

export default Mask;
