import { FC } from 'react';

import cx from 'classnames';

import { CheckboxCheckmark } from 'components/shared/Icon/icons/CheckboxCheckmark';

import { FormFieldWrapper } from '..';
import s from './index.module.css';
import { IBaseCheckboxProps } from './types';

export const BaseCheckbox: FC<IBaseCheckboxProps> = ({
  isChecked = false,
  onChange,
  children,
  name,
  errorMessage,
  helpText,
  isDisabled,
  className,
}) => {
  return (
    <label className={s.wrapper} htmlFor={name}>
      <input id={name} name={name} type="checkbox" disabled={isDisabled} checked={isChecked} onChange={onChange} />
      <div className={s.content}>
        <FormFieldWrapper helpText={helpText} errorMessage={errorMessage} helpClassName={s.noPadding}>
          <div
            className={cx(className, {
              [s.disabledContent]: isDisabled,
              [s.errorContent]: errorMessage,
            })}
          >
            {children}
          </div>
        </FormFieldWrapper>
      </div>
      <span className={cx(s.checkmark, { [s.error]: errorMessage })}>
        <CheckboxCheckmark />
      </span>
    </label>
  );
};
