import { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { DASHBOARD_PATH, ROOT_PATH, LOGIN_PATH } from 'constants/routes';
import { useAccountPath } from 'hooks/useAccountPath';
import { logoutThunk } from 'store/auth/thunks';
import { removeToken } from 'utils/accessToken';
import { isAccountAccWithIdRoute } from 'utils/domain';
import logger from 'utils/logger';

import { logoutOnServer } from '../../../api/auth';
import defaultBackground from '../../shared/images/authbg.jpg';
import { IUseErrorScreenProps } from './types';

const getTitle = (status: string) => {
  switch (status) {
    case '403':
      return 'Access forbidden';
    case '404':
      return 'Oops! Page not found';
    case '404_guest':
      return "Oops! This page doesn't exist";
    case 'guest_only':
      return 'Heads Up!';
    case 'unauthorized':
      return 'Your account has been disabled.';
    case 'blocked':
      return 'Your account has been disabled.';
    case 'deleted':
      return 'Your account has been disabled.';
    case 'deactivated':
      return 'Itinerary Deactivated';
    default:
      return 'Oops! Something went wrong';
  }
};

const getTitleProps = (status: string) => {
  switch (status) {
    case 'deactivated':
    case 'guest_only':
      return { noBorder: true, size: 'big' };
    default:
      return { size: 'small' };
  }
};

const getDesc = (status: string) => {
  switch (status) {
    case '403':
      return (
        <>
          We are sorry, but you do not have access to <br /> this page or resource for some reason.
        </>
      );
    case '404':
      return (
        <>
          Sorry, this page you&apos;re looking for doesn&apos;t exist. <br /> If you think something is broken report a
          problem.
        </>
      );
    case '404_guest':
      return 'Please contact your agent for more info.';
    case 'guest_only':
      return (
        <>
          <p style={{ paddingBottom: '2.7rem' }}>
            To log into your Guest Portal please
            <br />
            click the link from your email.
          </p>

          <p>
            If you are an agent trying to log into your
            <br />
            dashboard, click Log Out below then log in as normal.
          </p>
        </>
      );
    case 'unauthorized':
      return 'Sorry';
    case 'blocked':
      return (
        <>
          Please contact{' '}
          <a className="link" href="mailto:support@safariportal.app">
            support@safariportal.app
          </a>{' '}
          for more information.
        </>
      );
    case 'deleted':
      return 'Please contact your System Administrator for more information.';
    case 'crashed':
      return 'Try reloading the page';
    case 'deactivated':
      return (
        <>
          This itinerary has been deactivated.
          <br />
          Please contact your consultant.
        </>
      );
    default:
      return 'Try to go home';
  }
};

const useErrorScreen = ({ status, coverImage }: IUseErrorScreenProps) => {
  const dispatch = useDispatch();
  const getPath = useAccountPath();
  const isUnauthorized = status === 'blocked' || status === 'deleted';
  const title = getTitle(status);
  const titleProps = getTitleProps(status);
  const hintBottom = getDesc(status);
  const backgroundImage = coverImage ? `url('${coverImage}')` : `url('${defaultBackground}')`;
  const buttonUrlFn = () => {
    if (status === 'crashed') {
      window.location.reload();
      return;
    }
    if (status === 'guest_only') {
      dispatch(logoutThunk());
      return;
    }

    if (isUnauthorized) {
      window.location.replace(getPath(LOGIN_PATH));
      return;
    }
    if (isAccountAccWithIdRoute()) {
      window.location.replace(getPath(DASHBOARD_PATH));
      return;
    }

    window.location.replace(getPath(ROOT_PATH));
  };

  const requestLogout = async () => {
    try {
      await logoutOnServer();
    } catch (e) {
      logger.error(e);
    }
  };

  let buttonText = 'Return Home';
  if (isUnauthorized) {
    buttonText = 'Ok';
  } else if (status === 'crashed') {
    buttonText = 'Reload Page';
  } else if (status === 'guest_only') {
    buttonText = 'Log Out';
  }

  useEffect(() => {
    if (isUnauthorized) {
      requestLogout();
      removeToken();
    }
  }, []);

  return { hintBottom, buttonUrlFn, buttonText, title, titleProps, isUnauthorized, backgroundImage };
};

export default useErrorScreen;

export const getStatusTitle = (code: string) => {
  switch (code) {
    case '404_guest':
      return '404';
    default:
      return code;
  }
};
