import { addDays, differenceInDays, format, parseISO } from 'date-fns';

import { getTripEventsMinDate } from 'store/itinerariesItem/chronologicalDetails/utils';

import * as types from './recalculateRanges.types';
import { getMergeType } from './utils';

export const recalculateRangesForChronological = ({
  itemsArray,
  targetOrganizedBy,
  sourceOrganizedBy,
  referenceDate,
  referenceDay,
}: types.RecalculateRangesForChronologicalAgruments) => {
  const mergeType = getMergeType(targetOrganizedBy, sourceOrganizedBy);

  switch (mergeType) {
    case types.MergeTypes.DatesToDates:
      return recalculateDatesToDatesRanges(itemsArray, referenceDate);
    case types.MergeTypes.DaysToDates:
      return recalculateDaysToDatesRanges(itemsArray, referenceDate);
    case types.MergeTypes.DatesToDays:
      return recalculateDatesToDaysRanges(itemsArray, referenceDay);
    case types.MergeTypes.DaysToDays:
      return recalculateDaysToDaysRanges(itemsArray, referenceDay);
  }
};

const recalculateDatesToDatesRanges = (
  itemsArray: types.TripEvent[],
  referenceDateString: string | null | undefined,
) => {
  if (!referenceDateString) {
    throw new Error('Reference date is required when merging dates to dates');
  }

  const referenceDate = parseISO(referenceDateString);
  const startDateOfTemplate = parseISO(getTripEventsMinDate(itemsArray)!);
  const difference = differenceInDays(referenceDate, startDateOfTemplate);

  return itemsArray.map((item) => {
    const itemStartDate = parseISO(item.datesRange.start!);
    const itemEndDate = parseISO(item.datesRange.end!);

    const newStartDate = addDays(itemStartDate, difference);
    const newEndDate = addDays(itemEndDate, difference);
    const newDatesRange = {
      start: format(newStartDate, 'yyyy-MM-dd'),
      end: format(newEndDate, 'yyyy-MM-dd'),
    };

    return {
      ...item,
      datesRange: newDatesRange,
    };
  });
};

const recalculateDaysToDatesRanges = (
  itemsArray: types.TripEvent[],
  referenceDateString: string | null | undefined,
) => {
  if (!referenceDateString) {
    throw new Error('Reference date is required when merging days to dates');
  }

  const referenceDate = parseISO(referenceDateString);

  return itemsArray.map((item) => {
    const itemStartDay = item.daysRange.start!;
    const itemEndDay = item.daysRange.end!;

    const newStartDate = addDays(referenceDate, itemStartDay - 1);
    const newEndDate = addDays(referenceDate, itemEndDay - 1);
    const newDatesRange = {
      start: format(newStartDate, 'yyyy-MM-dd'),
      end: format(newEndDate, 'yyyy-MM-dd'),
    };

    return {
      ...item,
      datesRange: newDatesRange,
    };
  });
};

const recalculateDatesToDaysRanges = (itemsArray: types.TripEvent[], referenceDay: number | null) => {
  if (!referenceDay) {
    throw new Error('Reference day is required when merging dates to days');
  }

  return itemsArray.map((item) => {
    const itemStartDay = item.daysRange.start!;
    const itemEndDay = item.daysRange.end!;

    const newStartDay = referenceDay + itemStartDay - 1;
    const newEndDay = referenceDay + itemEndDay - 1;
    const newDayRange = {
      start: newStartDay,
      end: newEndDay,
    };

    return {
      ...item,
      daysRange: newDayRange,
    };
  });
};

const recalculateDaysToDaysRanges = (itemsArray: types.TripEvent[], referenceDay: number | null) => {
  if (!referenceDay) {
    throw new Error('Reference day is required when merging days to days');
  }

  return itemsArray.map((item) => {
    const itemStartDay = item.daysRange.start!;
    const itemEndDay = item.daysRange.end!;

    const newStartDay = referenceDay + itemStartDay - 1;
    const newEndDay = referenceDay + itemEndDay - 1;
    const newDaysRange = {
      start: newStartDay,
      end: newEndDay,
    };

    return {
      ...item,
      daysRange: newDaysRange,
    };
  });
};
