import mockData from './mockData';

export default {
  pageTypeContextProps: {
    blockAddingAvailable: false,
    leftHeadline: 'Pre travel information',
    leftSubheadline: 'Prepare for your trip',
    headlineHint: 'Don’t worry, this can be edited within an itinerary.',
    subheadlineEditable: true,
  },
  useMockData: mockData,
};
